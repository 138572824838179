import React from 'react'

import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'

import { Container } from 'presentation/shared/components/Container'
import Button from 'presentation/shared/components/Button'
import Heading from 'presentation/shared/components/Heading'
import SupportText from 'presentation/shared/components/SupportText'
import { TextArea } from 'presentation/doctor/layouts/AnswerSurgicalPendency/styles'
import { UpdateSurgicalOrder } from 'domain/usecases/surgical-order/update-surgical-order'
import { WithLoadingProps } from 'presentation/shared/components/HOCs/WithLoading'

import * as S from './styles'

type Props = {
  initialValues?: AddClinicalHistoryFormValues
  surgicalOrderId: number
  updateSurgicalOrder?: UpdateSurgicalOrder
  goBack: () => void
  setSuccessModal: () => void
} & WithLoadingProps

const AddClinicalHistory = ({
  initialValues = {
    clinicalHistory: ''
  } as AddClinicalHistoryFormValues,
  surgicalOrderId,
  updateSurgicalOrder,
  goBack,
  setSuccessModal,
  setIsLoading
}: Props) => {
  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        await updateSurgicalOrder?.update({
          clinicalHistory: values.clinicalHistory,
          surgical_order_id: surgicalOrderId
        })
        setSuccessModal()
      } catch (e: any) {
        toast.error(e.message)
      } finally {
        setIsLoading(false)
      }
    }
  })

  return (
    <>
      <S.Wrapper role="form">
        <Container
          title="Procedimento"
          primaryButton={
            <Button
              fullWidth
              disabled={!!errors.clinicalHistory}
              type="submit"
              onClick={() => {
                handleSubmit()
              }}
              data-testid="clinical-history-submit-button"
            >
              Confirmar
            </Button>
          }
          secondaryButton={
            <Button
              fullWidth
              variant="outlined"
              onClick={goBack}
              data-testid="go-back-clinical-history-button"
            >
              Voltar
            </Button>
          }
        >
          <S.InputsContainer>
            <Heading
              size="medium"
              color="gray"
              style={{ marginTop: '24px' }}
              required
            >
              História clínica
            </Heading>
            <SupportText
              color="lightGray"
              style={{ marginTop: '5px', marginBottom: '12px' }}
            >
              Digite aqui o diagnóstico do paciente ou quaisquer informações
              relevantes para a cirurgia.
            </SupportText>
            <TextArea
              rows={5}
              placeholder="Descrição da história clínica do paciente"
              style={{ marginBottom: '24px' }}
              onChange={handleChange('clinicalHistory')}
              value={values.clinicalHistory}
              dataTestId="clinical-history-textarea"
            />
          </S.InputsContainer>
        </Container>
      </S.Wrapper>
    </>
  )
}

const validationSchema = yup.object().shape({
  clinicalHistory: yup
    .string()
    .required()
    .test(
      'not-only-spaces',
      'Campo não pode ser apenas espaços em branco',
      (val?: string) => {
        if (typeof val === 'string') {
          return val?.trim()?.length !== 0
        }
        return false
      }
    ),
  expectedDate: yup.string().nullable().optional().notRequired()
})

type AddClinicalHistoryFormValues = {
  clinicalHistory: string
}

export default AddClinicalHistory

import React from 'react'

import { Secretary } from 'domain/entities/secretary-model'
import SecretaryForm from 'presentation/doctor/components/Forms/Secretary'
import Header from 'presentation/shared/components/Header'
import { Container } from 'presentation/shared/components/Container'
import { UnlinkSecretary } from 'domain/usecases/secretary/unlink-secretary'
import { UpdateUser } from 'domain/usecases/user/update-user'

type Props = {
  secretary: Secretary
  updateSecretaryProfile: UpdateUser
  unlinkSecretary?: UnlinkSecretary
}

export default function SecretaryDetailsLayout({
  secretary,
  updateSecretaryProfile,
  unlinkSecretary
}: Props) {
  return (
    <>
      <Header />
      <Container hasHeader>
        <SecretaryForm
          updateSecretaryProfile={updateSecretaryProfile}
          initialValues={{ ...secretary }}
          unlinkSecretary={unlinkSecretary}
          secretary_id={secretary.secretary_id ?? 0}
          user_id={secretary.user_id}
        />
      </Container>
    </>
  )
}

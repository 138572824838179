import React, { useEffect } from 'react'
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
  withRouter
} from 'react-router-dom'
import { Profile } from 'common/enum/profile'
import AddProfessional from 'presentation/doctor/pages/AddProfessional'
import makePatientLogin from 'main/factories/pages/patient/login/patient-login-factory'
import makePasswordRecovery from 'main/factories/pages/patient/password-recovery/patient-password-recovery-factory'
import makeDoctorSignup from 'main/factories/pages/doctor/signup/doctor-signup-factory'
import makeDoctorHome from 'main/factories/pages/doctor/home/doctor-home-factory'
import makeDoctorAddSecretary from 'main/factories/pages/doctor/add-secretary/doctor-add-secretary-factory'
import makeDoctorSecretaries from 'main/factories/pages/doctor/secretaries/doctor-secretaries-factory'
import makeDoctorSecretaryDetails from 'main/factories/pages/doctor/secretary-details/doctor-secretary-details-factory'
import makeDoctorAddSignature from 'main/factories/pages/doctor/add-signature/doctor-add-signature-factory'
import makeDoctorSignature from 'main/factories/pages/doctor/signature/doctor-signature-factory'
import PrivateRoute from 'presentation/shared/components/PrivateRoute'
import Allergies from 'presentation/doctor/pages/Allergies'
import makeDoctorAddAllergy from 'main/factories/pages/doctor/add-allergy/doctor-add-allergy-factory'
import makeDoctorAddPatient from 'main/factories/pages/doctor/add-patient/doctor-add-patient-factory'
import makeDoctorCreateSurgicalOrder from 'main/factories/pages/doctor/add-surgical-order/create-surgical-order/doctor-create-surgical-order'
import makeDoctorAddSurgicalOrder from 'main/factories/pages/doctor/add-surgical-order/doctor-add-surgical-oder-factory'
import makeDoctorSelectProcedure from 'main/factories/pages/doctor/add-surgical-order/select-procedure/doctor-select-procedure-factory'
import makeDoctorSelectOpme from 'main/factories/pages/doctor/add-surgical-order/select-opme/doctor-select-opme-factory'
import makeDoctorCreateMedicalRecord from 'main/factories/pages/doctor/add-surgical-order/create-medical-record/doctor-create-medical-record'
import makeDoctorReviewSurgicalOrder from 'main/factories/pages/doctor/add-surgical-order/review-surgical-order/doctor-review-surgical-order-factory'
import { useStores } from 'presentation/hooks/use-stores'
import makeDoctorAnswerPendency from 'main/factories/pages/doctor/pendency/doctor-pendency-factory'
import makePatientProfile from 'main/factories/pages/patient/profile/patient-profile-factory'
import makeDoctorPersonalInfo from 'main/factories/pages/doctor/personal-info/doctor-personal-info-Factory'
import { AppendSurgicalOrderDocument } from 'presentation/shared/pages/AppendSurgicalOrderDocument'
import { MedicalReports } from 'presentation/shared/pages/MedicalReports'
import PatientProfileLayout from 'presentation/patient/layouts/Profile'
import SurgeryDetails from 'presentation/shared/pages/SurgeryDetails'
import makeSurgicalOrderListPage from 'main/factories/pages/doctor/SurgeryOrderListPage/doctor-surgical-order-list'
import SurgicalOrderDocuments from 'presentation/doctor/pages/SurgicalOrderDocuments'
import makeReviewSurgicalOrderList from 'main/factories/pages/doctor/review-surgical-order-list/review-surgical-order-list'
import { Pendencies } from 'presentation/doctor/pages/Pendencies'
import makeDoctorAddNewBornPatient from 'main/factories/pages/doctor/add-newborn-patient/doctor-add-newborn-patient-factory'
import makeDoctorFavoritesFactory from 'main/factories/pages/doctor/favorites/doctor-favorites-factory'
import makeOnboarding from 'main/factories/pages/doctor/onboarding/onboarding-factory'
import makeDoctorCreateReservationInfo from 'main/factories/pages/doctor/add-surgical-order/create-reservation-info/doctor-create-reservation-info'
import makeDoctorCreateSurgicalInfo from 'main/factories/pages/doctor/add-surgical-order/create-surgical-info/doctor-create-surgical-info'
import makeDoctorCreateMedicalTeam from 'main/factories/pages/doctor/add-surgical-order/create-medical-team/doctor-create-medical-team'
import makeDoctorCreateBloodBank from 'main/factories/pages/doctor/add-surgical-order/create-blood-bank/doctor-create-blood-bank'
import makeDoctorCreateEquipments from 'main/factories/pages/doctor/add-surgical-order/create-equipments/doctor-create-equipments'
import makeDoctorCreateCme from 'main/factories/pages/doctor/add-surgical-order/create-cme/doctor-create-cme'

export const ScrollToTop = withRouter(() => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
})

export default function DoctorRouter() {
  const stores = useStores()
  const currentUser = stores.currentAccount
  const surgicalstatus = stores.surgicalstatus

  useEffect(() => {
    async function loadData() {
      if (!window.location.pathname.match('login')) {
        await surgicalstatus.load()
      }
    }
    loadData()
  }, [])

  return (
    <BrowserRouter
      basename={process.env.NODE_ENV === 'development' ? '/doctor' : ''}
    >
      <ScrollToTop />
      <Switch>
        <Route
          path="/"
          exact
          component={() => {
            return (
              <>
                <Redirect
                  to={
                    currentUser.getCurrentAccount()?.accessToken
                      ? '/home'
                      : '/login'
                  }
                />
              </>
            )
          }}
        />
        <Route
          path="/login"
          exact
          component={() => makePatientLogin(Profile.DOCTOR)}
        />
        <Route path="/cadastro/:hash" exact component={makeDoctorSignup} />
        <PrivateRoute
          path="/home"
          exact
          component={makeDoctorHome}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/doctor-onboarding"
          exact
          component={makeOnboarding}
          profile={[Profile.DOCTOR]}
        />
        <Route
          path="/recuperar-senha/:type"
          exact
          component={() => makePasswordRecovery({ client: Profile.DOCTOR })}
        />
        <PrivateRoute
          path="/vincular-profissional"
          exact
          component={AddProfessional}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/vincular-profissional/secretaria"
          exact
          component={makeDoctorAddSecretary}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/secretaria"
          exact
          component={makeDoctorSecretaries}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/secretaria/detalhes"
          exact
          component={makeDoctorSecretaryDetails}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/assinatura/save"
          exact
          component={makeDoctorAddSignature}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/assinatura"
          exact
          component={makeDoctorSignature}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/alergia/save"
          exact
          component={makeDoctorAddAllergy}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/alergia"
          exact
          component={Allergies}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/pendencias"
          exact
          component={Pendencies}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/pendencia"
          exact
          component={makeDoctorAnswerPendency}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/pedido/novo"
          exact
          component={makeDoctorCreateSurgicalOrder}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/paciente/save"
          exact
          component={makeDoctorAddPatient}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/paciente/recem-nascido/save"
          exact
          component={makeDoctorAddNewBornPatient}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/pedido-cirurgico"
          exact
          component={makeDoctorAddSurgicalOrder}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/pedido-cirurgico/procedimento"
          exact
          component={makeDoctorSelectProcedure}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/pedido-cirurgico/opme"
          exact
          component={makeDoctorSelectOpme}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/pedido-cirurgico/ficha-clinica"
          exact
          component={makeDoctorCreateMedicalRecord}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/pedido-cirurgico/carater-reserva"
          exact
          component={makeDoctorCreateReservationInfo}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/pedido-cirurgico/equipe-medica"
          exact
          component={makeDoctorCreateMedicalTeam}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/pedido-cirurgico/banco-de-sangue"
          exact
          component={makeDoctorCreateBloodBank}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/pedido-cirurgico/equipamentos"
          exact
          component={makeDoctorCreateEquipments}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/pedido-cirurgico/cme"
          exact
          component={makeDoctorCreateCme}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/pedido-cirurgico/dados-cirurgicos"
          exact
          component={makeDoctorCreateSurgicalInfo}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/pedido-cirurgico/resumo"
          exact
          component={makeDoctorReviewSurgicalOrder}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/perfil"
          exact
          component={makePatientProfile}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/informacoes-pessoais"
          exact
          component={makeDoctorPersonalInfo}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/upload/documentos/:type"
          exact
          component={AppendSurgicalOrderDocument}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/upload/laudos"
          exact
          component={MedicalReports}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/pedido-anexo"
          exact
          component={SurgicalOrderDocuments}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/laudos"
          exact
          component={MedicalReports}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/agendamentos"
          exact
          component={makeSurgicalOrderListPage}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/perfil"
          exact
          component={PatientProfileLayout}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/cirurgia"
          component={SurgeryDetails}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/pedidos/resumo"
          component={makeReviewSurgicalOrderList}
          profile={[Profile.DOCTOR]}
        />
        <PrivateRoute
          path="/favoritos"
          exact
          component={makeDoctorFavoritesFactory}
          profile={[Profile.DOCTOR]}
        />
      </Switch>
    </BrowserRouter>
  )
}

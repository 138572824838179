import React from 'react'

import { Container } from 'presentation/shared/components/Container'
import Header from 'presentation/shared/components/Header'
import UpdateDoctorPersonalInfoForm from 'presentation/doctor/components/Forms/UpdatePersonalInfo'
import { Doctor } from 'domain/entities/doctor-model'

type Props = {
  data: Doctor
}
export default function UpdateDoctorPersonalInfoLayout({ data }: Props) {
  return (
    <>
      <Header />
      <Container hasHeader>
        <UpdateDoctorPersonalInfoForm initialValues={data} />
      </Container>
    </>
  )
}

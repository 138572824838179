import styled from 'styled-components'

export const HeaderContainer = styled.div`
  display: flex;
  text-align: center;
  gap: 8px;
  word-break: break-word;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.gray};
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  overflow-y: auto;
  word-break: normal;
  text-align: left;
`

export const ContainerData = styled.div`
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  label {
    margin-bottom: 12px;
  }
`

import React, { useEffect, useState } from 'react'

import { useFormik } from 'formik'
import moment from 'moment-timezone'
import * as yup from 'yup'
import 'main/config/yup'

import { InvitePatient as InviteUseCase } from 'domain/usecases/patient/invite-patient'
import Button from 'presentation/shared/components/Button'
import SupportText from 'presentation/shared/components/SupportText'
import TextField from 'presentation/shared/components/TextField'
import { Container } from 'presentation/shared/components/Container'
import Carousel, {
  CarouselState
} from 'presentation/shared/components/Carousel'
import { cpfMask, dateMask, phoneMask } from 'presentation/utils/masks'
import SelectField from 'presentation/shared/components/SelectField'
import { Gender } from 'common/enum/gender'
import { LoadHealthInsurance } from 'domain/usecases/health-insurance/load-health-insurance'
import { toast } from 'react-toastify'
import { UpdatePatientHealthInsurance } from 'domain/usecases/patient/update-patient-health-insurance'
import Modal from 'presentation/shared/components/Modal'
import { User } from 'domain/entities/user-model'
import { maritalStatus } from 'presentation/utils/default-marital-status'
import { useHistory } from 'react-router'
import { useStores } from 'presentation/hooks/use-stores'
import { UserStatusEnum } from 'common/enum/user-status'
import { Hospital } from 'domain/entities/hospital-model'
import { useServices } from 'presentation/hooks/use-services'
import { HealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'
import LoadingModal from '../../../../shared/components/LoadingModal'

export type InvitePatientProps = {
  invitePatient?: InviteUseCase
  loadHealthInsurance?: LoadHealthInsurance
  updateInsuranceInfo?: UpdatePatientHealthInsurance
  hospital: Hospital
}

export default function InvitePatient({
  invitePatient,
  hospital
}: InvitePatientProps) {
  const insuranceServices = useServices().healthInsurance
  const [carousel, setCarousel] = useState<CarouselState>({} as CarouselState)
  const [handleSuccessModal, setHandleSuccessModal] = useState<boolean>(false)
  const [healthInsurances, setHealthInsurances] = useState(
    [] as HealthInsurancePlans[]
  )

  const history = useHistory()
  const currentSurgery = useStores().currentSurgery

  const formik = useFormik({
    initialValues: {} as InvitePatientFormValues,
    validateOnMount: true,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const { healthInsurance, ...personalInfo } = values

        const birthday = moment(values.birthday, 'DD/MM/YYYY', true)
          .tz('America/Sao_Paulo')
          .toISOString()

        const patient = await invitePatient?.update({
          healthInsurance: healthInsurance && {
            healthInsuranceName: healthInsurance.description,
            healthInsuranceCode: healthInsurance.code,
            ansRegister: healthInsurance.ansRegister
          },
          birthday,
          gender: personalInfo.gender,
          maritalStatus: personalInfo.maritalStatus,
          user: {
            name: values.name,
            cpf: values.cpf ? values.cpf.replace(/[^\w\s]/gi, '') : '',
            email: values.email,
            status: UserStatusEnum.PENDING,
            phone: values.phone.replace(/\D/g, '')
          } as User
        })

        if (patient) {
          const surgery = currentSurgery.getSurgery()

          surgery.patient = patient
          currentSurgery.setSurgery(surgery)

          setHandleSuccessModal(true)

          setTimeout(() => {
            const location = {
              pathname: '/pedido/novo',
              state: { patient, step: 2 }
            }
            history.push(location)
          }, 2000)
        }
      } catch (e: any) {
        toast.error(e.message)
      }
    }
  })

  useEffect(() => {
    const loadData = async () => {
      const insurances = await insuranceServices.loadHealthInsurancePlans({
        hospital_id: hospital.hospital_id,
        params: ['code', 'description', 'ansRegister']
      })
      setHealthInsurances(insurances)
    }
    loadData()
  }, [])

  const isValid = (step: number) => {
    switch (step) {
      case 0:
        return (
          !formik.errors.cpf && !formik.errors.name && !formik.errors.birthday
        )
      case 1:
        return !formik.errors.email && !formik.errors.phone
      case 2:
        return !formik.errors.gender && !formik.errors.maritalStatus
      case 3:
        return formik.isValid || !formik.isSubmitting
      default:
        return false
    }
  }

  return (
    <Container
      noPadding
      primaryButton={
        <Button
          fullWidth
          type={carousel.isLast ? 'submit' : 'button'}
          disabled={!isValid(carousel.activeIndex) || formik.isSubmitting}
          onClick={carousel.isLast ? formik.submitForm : carousel.slideNext}
        >
          Próximo
        </Button>
      }
      title="Convidar Paciente"
      subtitle="Para convidar o paciente, adicione os dados abaixo."
      secondaryButton={
        <SupportText onClick={() => history.goBack()} color="primary">
          Cancelar
        </SupportText>
      }
      justifyContent="center"
    >
      <Carousel state={carousel} setState={setCarousel} touch={false}>
        <div style={divStyle}>
          <TextField
            label="CPF"
            name="cpf"
            id="cpf"
            onChange={formik.handleChange}
            mask={cpfMask}
            required
          />
          <TextField
            label="Nome do paciente"
            name="name"
            id="name"
            onChange={formik.handleChange}
            required
            style={{ marginTop: '12px' }}
          />
          <TextField
            label="Data de nascimento"
            name="birthday"
            id="birthday"
            onChange={formik.handleChange}
            mask={dateMask}
            required
            style={{ marginTop: '12px' }}
          />
        </div>
        <div style={divStyle}>
          <TextField
            label="E-mail"
            name="email"
            id="email"
            onChange={formik.handleChange}
            required
          />
          <TextField
            label="Telefone"
            name="phone"
            id="phone"
            onChange={formik.handleChange}
            required
            style={{ marginTop: '12px' }}
            defaultValue="+55"
            mask={phoneMask}
          />
        </div>
        <div style={divStyle}>
          <SelectField
            label="Sexo"
            name="gender"
            id="gender"
            onChange={formik.handleChange}
            required
            value={formik.values.gender || ''}
            items={[
              {
                label: 'Masculino',
                value: Gender.MALE
              },
              {
                label: 'Feminino',
                value: Gender.FEMALE
              }
            ]}
          />
          <SelectField
            label="Estado civil"
            name="maritalStatus"
            required
            items={maritalStatus}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.maritalStatus || ''}
          />
        </div>
        <div style={divStyle}>
          <SelectField
            label="Convênio"
            items={healthInsurances.map((insurance) => ({
              label: insurance.description,
              value: insurance.code
            }))}
            name="healthInsurance"
            value={formik.values.healthInsurance?.code || ''}
            id="healthInsurance"
            onInputChange={(id) => {
              formik.setFieldValue(
                'healthInsurance',
                healthInsurances.find(
                  (insurance) => insurance.code === Number(id)
                )
              )
            }}
          />
        </div>
      </Carousel>
      <LoadingModal show={formik.isSubmitting} />
      <Modal
        close={() => setHandleSuccessModal(false)}
        title="Paciente cadastrado com sucesso!"
        show={handleSuccessModal}
      />
    </Container>
  )
}

const validationSchema = yup.object().shape({
  cpf: yup.string().cpf().required(),
  name: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup
    .string()
    .matches(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/, 'Telefone inválido')
    .required(),
  gender: yup.string().required(),
  maritalStatus: yup.string().required(),
  birthday: yup.date().format('DD/MM/YYYY', true).required()
})

type InvitePatientFormValues = {
  cpf: string
  name: string
  email: string
  phone: string
  gender: string
  maritalStatus: string
  healthInsurance: HealthInsurancePlans
  birthday: string
}

const divStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1
}

import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import { DoctorRepository } from 'repository/repositories/doctor/doctor-repository'
import SurgeryOrderListPage from 'presentation/doctor/pages/SurgeryOrderList'
import { RemoteLoadSurgicalOrderByDoctor } from 'service/usecases/load-surgical-order-by-doctor/remote-load-surgical-order-by-doctor'

export default function makeSurgicalOrderListPage() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const doctorRepository = new DoctorRepository(apiRepository)

  const loadDoctorSurgicalOrders = new RemoteLoadSurgicalOrderByDoctor(
    doctorRepository
  )

  return (
    <SurgeryOrderListPage loadDoctorSurgicalOrders={loadDoctorSurgicalOrders} />
  )
}

import React from 'react'

import { Label } from 'presentation/shared/components/TextField/styles'
import * as FormS from '../../styles'
import { defaultOptions } from 'presentation/utils/default-boolean-options'
import Chip from 'presentation/shared/components/Chip'
import { useFormikContext } from 'formik'
import { AddMedicalTeamFormValues } from '..'
import Button from 'presentation/shared/components/Button'
import { useHistory } from 'react-router-dom'
import { TeamTable } from '../TeamTable'
import TextField from 'presentation/shared/components/TextField'

export default function ListMedicalTeam() {
  const history = useHistory()

  const {
    setFieldValue,
    values,
    setValues,
    handleBlur,
    handleChange,
    errors,
    touched
  } = useFormikContext<AddMedicalTeamFormValues>()

  const isNextDisabled = (() => {
    if (values.surgicalValues.isCoupleSurgery === undefined) {
      return true
    }
    if (values.surgicalValues.isCoupleSurgery) {
      if (!values.surgicalValues.medicalTeamName) {
        return true
      }
    }
    return false
  })()

  return (
    <>
      <FormS.LabelContainer>
        <Label>
          Cirurgia conjugada <span>*</span>
        </Label>
      </FormS.LabelContainer>

      <FormS.FlexContainer>
        {defaultOptions.map((option) => (
          <Chip
            variant="square"
            key={option.label}
            label={option.label}
            customValue={option.value}
            name="surgicalValues.isCoupleSurgery"
            onCheck={(val) => {
              if (val !== values.surgicalValues.isCoupleSurgery) {
                setFieldValue('surgicalValues.isCoupleSurgery', val)
              } else {
                setFieldValue('surgicalValues.isCoupleSurgery', undefined)
              }
            }}
            checked={option.value === values.surgicalValues.isCoupleSurgery}
            dataTestId={option.value + 'isCoupleSurgery'}
          />
        ))}
      </FormS.FlexContainer>

      {values.surgicalValues.isCoupleSurgery && (
        <FormS.FlexContainer>
          <TextField
            style={{ width: '100%' }}
            autoComplete="off"
            name="surgicalValues.medicalTeamName"
            id="surgicalValues.medicalTeamName"
            onChange={handleChange}
            defaultValue={values.surgicalValues.medicalTeamName || ''}
            error={
              touched.surgicalValues?.medicalTeamName
                ? errors.surgicalValues?.medicalTeamName
                : undefined
            }
            required
            data-testid="name-input"
            placeholder="Nome da equipe médica"
            onBlur={handleBlur}
          />
        </FormS.FlexContainer>
      )}

      <TeamTable type="medicalTeam" />
      <Button
        type="button"
        fullWidth
        variant="white"
        onClick={() => {
          setValues({
            ...values,
            step: 'upsert-medical-team',
            editingMedicalTeamIndex: undefined
          })
        }}
        style={{ marginTop: '2rem', marginBottom: '1rem' }}
      >
        + Adicionar Profissional
      </Button>

      <Button
        type="button"
        fullWidth
        onClick={() => setFieldValue('step', 'list-anesthetist-team')}
        style={{ marginTop: 'auto' }}
        disabled={isNextDisabled}
      >
        Prosseguir
      </Button>
      <Button
        type="button"
        fullWidth
        variant="outlined"
        onClick={() => history.goBack()}
        style={{ marginTop: '0.5rem' }}
      >
        Cancelar
      </Button>
    </>
  )
}

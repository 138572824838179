import React from 'react'

import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'
import { RemoteUpdateSurgicalOrder } from 'service/usecases/update-surgical-order/remote-update-surgical-order'
import CreateCme from 'presentation/doctor/pages/AddSurgicalOrder/CreateCme'

export default function makeDoctorCreateCme() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )

  const surgicalOrderRepository = new SurgicalOrderRepository(apiRepository)

  const updateSurgicalOrder = new RemoteUpdateSurgicalOrder(
    surgicalOrderRepository
  )

  return <CreateCme updateSurgicalOrder={updateSurgicalOrder} />
}

import React, { useEffect, useState } from 'react'
import * as S from './styles'
import SupportText from 'presentation/shared/components/SupportText'
import { useServices } from 'presentation/hooks/use-services'
import { DownloadPatientDocument } from 'domain/usecases/patient/download-patient-document'
// @ts-ignore
import * as pdfjs from 'pdfjs-dist/legacy/build/pdf'
// @ts-ignore
import PDFJSWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry'
import LoadingGif from 'presentation/assets/icons/loading.gif'
import downloadFileFromBlob from 'common/utils/downloadFileFromBlob'

pdfjs.GlobalWorkerOptions.workerSrc = PDFJSWorker

type Props = {
  fileName: string
  documentId: number
  emptyMessage?: string
}

export function DownloadCard({ documentId, emptyMessage, fileName }: Props) {
  const [file, setFile] = useState<DownloadPatientDocument.Model>()
  const [thumb, setThumb] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const services = useServices().patient

  useEffect(() => {
    ;(async () => {
      if (documentId) {
        const document = await services.downloadPatientDocument(documentId)
        setFile(document)
      } else {
        setFile(undefined)
      }
    })()
  }, [documentId])

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        if (file) {
          const extractContent = file?.contentType.split('/')
          if (extractContent?.[0] === 'image') {
            setThumb(URL.createObjectURL(file?.data))
          } else if (extractContent?.[1] === 'pdf') {
            const canvas = document.createElement('canvas')
            const pdf = await pdfjs.getDocument(URL.createObjectURL(file?.data))
              .promise
            const page = await pdf.getPage(1)
            const viewport = page.getViewport({ scale: 1 })
            const context = canvas.getContext('2d')
            canvas.height = viewport.height
            canvas.width = viewport.width
            if (context) {
              await page.render({ canvasContext: context, viewport: viewport })
                .promise
              setThumb(canvas.toDataURL())
            }
          }
        } else {
          setThumb('')
        }
      } catch (e) {
        return
      } finally {
        setIsLoading(false)
      }
    })()
  }, [file])

  const downloadFile = () => {
    if (file) {
      downloadFileFromBlob(file?.data, file?.contentType, fileName)
    }
  }

  return (
    <S.Wrapper onClick={downloadFile} bgImg={thumb} style={{ margin: 0 }}>
      <SupportText>{emptyMessage}</SupportText>
      {isLoading && <S.Loading src={LoadingGif} />}
      {documentId && (
        <S.Info>
          <SupportText>Clique para baixar</SupportText>
        </S.Info>
      )}
    </S.Wrapper>
  )
}

import React from 'react'

import List from 'presentation/shared/components/List'
import { Secretary } from 'domain/entities/secretary-model'
import { Header } from 'presentation/shared/components/Header'
import { Container } from 'presentation/shared/components/Container'
import moment from 'moment-timezone'

import * as S from './styles'
import { useHistory } from 'react-router-dom'
import Button from 'presentation/shared/components/Button'

type Props = {
  secretaries?: Secretary[]
}

export default function SecretariesLayout({ secretaries }: Props) {
  const history = useHistory()

  return (
    <>
      <Header />
      <Container
        hasHeader
        title="Secretária"
        actualPageText="Voltar"
        actualPageOnClick={history.goBack}
      >
        {secretaries && secretaries.length > 0 ? (
          <List
            size="medium"
            arrow
            items={
              secretaries?.map((secretary) => ({
                title: <S.ListTitle>{secretary.name}</S.ListTitle> ?? '',
                icon: 'https://source.unsplash.com/random' ?? '',
                description: (
                  <S.ListDescription>
                    {'Adicionado em ' +
                      moment(secretary.createdAt)
                        .tz('America/Sao_Paulo')
                        .locale('pt-br')
                        .format('DD MMM YYYY')}
                  </S.ListDescription>
                ),
                click: () =>
                  history.push('/secretaria/detalhes', {
                    secretary
                  })
              })) ?? []
            }
          />
        ) : (
          <>
            <S.EmptyList>
              Você não possui nenhuma <br /> secretaria vinculada
            </S.EmptyList>
          </>
        )}
        <S.ButtonWrapper>
          <Button
            variant={'outlined'}
            onClick={() => history.push('/vincular-profissional/secretaria')}
            backgroundColor={'mainBg'}
            fullWidth
          >
            Adicionar Secretária
          </Button>
        </S.ButtonWrapper>
      </Container>
    </>
  )
}

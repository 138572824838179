import React from 'react'

import { Label } from 'presentation/shared/components/TextField/styles'
import * as FormS from '../../styles'
import Chip from 'presentation/shared/components/Chip'
import { useFormikContext } from 'formik'
import { AddMedicalTeamFormValues } from '..'
import Button from 'presentation/shared/components/Button'
import { TeamTable } from '../TeamTable'

export default function ListAnesthetistTeam() {
  const { setFieldValue, values, setValues, submitForm } =
    useFormikContext<AddMedicalTeamFormValues>()

  const isNextDisabled = (() => {
    if (values.surgicalValues.isAnesthetistExternal === undefined) {
      return true
    }
    if (values.surgicalValues.isAnesthetistExternal) {
      if (!values.surgicalValues.anesthetistTeam?.length) {
        return true
      }
    }
    return false
  })()

  return (
    <>
      <FormS.LabelContainer>
        <Label>
          Anestesia <span>*</span>
        </Label>
      </FormS.LabelContainer>
      <FormS.FlexContainer>
        {AnesthetistOptions.map((option) => (
          <Chip
            variant="square"
            key={option.label}
            label={option.label}
            customValue={option.value}
            name="surgicalValues.isAnesthetistExternal"
            onCheck={(val) => {
              if (val !== values.surgicalValues.isAnesthetistExternal) {
                setFieldValue('surgicalValues.isAnesthetistExternal', val)
              } else {
                setFieldValue('surgicalValues.isAnesthetistExternal', undefined)
              }
            }}
            checked={
              option.value === values.surgicalValues.isAnesthetistExternal
            }
            dataTestId={option.value + 'isAnesthetistExternal'}
          />
        ))}
      </FormS.FlexContainer>

      {values.surgicalValues.isAnesthetistExternal && (
        <>
          <TeamTable type="anesthetistTeam" />
          <Button
            type="button"
            fullWidth
            variant="white"
            onClick={() => {
              setValues({
                ...values,
                step: 'upsert-anesthetist-team',
                editingMedicalTeamIndex: undefined
              })
            }}
            style={{ marginTop: '2rem', marginBottom: '1rem' }}
          >
            + Adicionar Profissional
          </Button>
        </>
      )}

      <Button
        type="button"
        fullWidth
        disabled={isNextDisabled}
        onClick={() => submitForm()}
        style={{ marginTop: 'auto' }}
      >
        Prosseguir
      </Button>
      <Button
        type="button"
        fullWidth
        variant="outlined"
        onClick={() => setFieldValue('step', 'list-medical-team')}
        style={{ marginTop: '0.5rem' }}
      >
        Voltar
      </Button>
    </>
  )
}

const AnesthetistOptions = [
  {
    label: 'Externo',
    value: true
  },
  {
    label: 'Casa',
    value: false
  }
]

import styled from 'styled-components'

export const CmeCell = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.font.sizes.medium} !important;
  font-weight: ${({ theme }) => theme.font.normal} !important;
  justify-content: space-between;

  span,
  p {
    color: ${({ theme }) => theme.colors.gray} !important;
  }

  div {
    display: flex;
    gap: 10px;
  }
`

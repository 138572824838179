import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import DoctorHome from 'presentation/doctor/pages/Home'
import { useStores } from 'presentation/hooks/use-stores'
import React, { useEffect } from 'react'
import { ApiRepository } from 'repository/api-repository'
import { DoctorRepository } from 'repository/repositories/doctor/doctor-repository'
import { RemoteDoctorLoadSurgeryRequestCount } from 'service/usecases/doctor-load-surgery-request-count/remote-doctor-load-surgery-request-count'

export default function makeDoctorHome() {
  const stores = useStores()
  const surgicalstatus = stores.surgicalstatus

  useEffect(() => {
    async function loadData() {
      await surgicalstatus.load()
    }
    loadData()
  }, [])
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const doctorRepository = new DoctorRepository(apiRepository)

  const countSurgeryRequest = new RemoteDoctorLoadSurgeryRequestCount(
    doctorRepository
  )
  return <DoctorHome loadSurgeryDoctorRequestCount={countSurgeryRequest} />
}

import React, { useEffect, useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'

import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { LoadSurgicalOrder } from 'domain/usecases/surgical-order/load-surgical-order'
import CheckIcon from 'presentation/assets/icons/big-check.svg'
import ArrowIcon from 'presentation/assets/icons/rounded-arrow.svg'
import { ListItem } from 'presentation/shared/components/List'
import { Patient as PatientModel } from 'domain/entities/patient-model'
import { AddFavorite } from 'domain/usecases/surgical-order/add-favorite'
import { Hospital } from 'domain/entities/hospital-model'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { HealthInsurance } from 'domain/entities/health-insurance'
import { Doctor } from 'domain/entities/doctor-model'
import { toast } from 'react-toastify'
import AddSurgicalOrderLayout from 'presentation/doctor/layouts/AddSurgicalOrder'
import {
  reservationInfoValidationSchema,
  adaptSurgicalOrderToFormValues as adaptReservationInfo
} from 'presentation/doctor/components/Forms/AddSurgicalOrder/AddReservationInfo'
import { procedureValidationSchema } from './SelectProcedure'
import { opmeValidationSchema } from './SelectOpme'
import {
  surgicalInfoValidationSchema,
  adaptSurgicalOrderToFormValues as adaptSurgicalInfo
} from 'presentation/doctor/components/Forms/AddSurgicalOrder/AddSurgicalInfo'
import { medicalTeamValidationSchema } from 'presentation/doctor/components/Forms/AddSurgicalOrder/AddMedicalTeam'
import {
  bloodBankValidationSchema,
  adaptSurgicalOrderToFormValues as adaptBloodBank
} from 'presentation/doctor/components/Forms/AddSurgicalOrder/AddBloodBank'
import { equipmentValidationSchema } from 'presentation/doctor/components/Forms/AddSurgicalOrder/AddEquipments'
import { cmeValidationSchema } from 'presentation/doctor/components/Forms/AddSurgicalOrder/AddCme'

const schemas = {
  reservationInfo: {
    validationSchema: reservationInfoValidationSchema,
    adapter: adaptReservationInfo
  },
  procedure: {
    validationSchema: procedureValidationSchema,
    adapter: (value: SurgicalOrderModel) => value
  },
  surgicalInfo: {
    validationSchema: surgicalInfoValidationSchema,
    adapter: adaptSurgicalInfo
  },
  opme: {
    validationSchema: opmeValidationSchema,
    adapter: (value: SurgicalOrderModel) => value
  },
  medicalTeam: {
    validationSchema: medicalTeamValidationSchema,
    adapter: (value: SurgicalOrderModel) => value
  },
  bloodBank: {
    validationSchema: bloodBankValidationSchema,
    adapter: adaptBloodBank
  },
  equipments: {
    validationSchema: equipmentValidationSchema,
    adapter: (value: SurgicalOrderModel) => value
  },
  cme: {
    validationSchema: cmeValidationSchema,
    adapter: (value: SurgicalOrderModel) => value
  }
}

type Props = {
  loadSurgicalOrder: LoadSurgicalOrder
  addFavorite?: AddFavorite
} & WithLoadingProps

type Location = {
  surgicalOrder: SurgicalOrderModel
}

const AddSurgicalOrder = WithLoading(
  ({ loadSurgicalOrder, addFavorite, setIsLoading }: Props) => {
    const [surgicalOrder, setSurgicalOrder] = useState<SurgicalOrderModel>({})
    const { state } = useLocation<Location>()
    const history = useHistory()

    useEffect(() => {
      async function loadData() {
        try {
          setIsLoading(true)

          const response = await loadSurgicalOrder.load(
            state.surgicalOrder.surgical_order_id as number,
            [
              'surgical_order_id',
              'patient_id',
              'patientName',
              'patient { phone, email, birthday, gender, cpf, healthInsurance {healthInsuranceName, healthInsuranceCode}}',
              'procedure {procedure_code, code, description, quantity, doctor_name, isMain, surgery_id, pro_fat_id}',
              'opme {solicitations {description, quantity, opme_id}, providers}',
              'cid {code, description}',
              'hospitalizationMode',
              'hospitalizationType',
              'healthInsurance {healthInsuranceName, healthInsuranceCode}',
              'allergy',
              'hospital {name, hospital_id}',
              'clinicalHistory',
              'surgicalCenter',
              'documents {document_id, type, name}',
              'expectedDate',
              'expectedDuration',
              'hospitalizationAvgTimeInDays',
              'patientHospitalized',
              'requestedUtiDiaries',
              'laterality',
              'preliminaryScintigraphyTime',
              'xrayOfPieceTime',
              'medicalTeamName',
              'medicalTeam { type, name, crm, phone }',
              'anesthetistTeam { anesthesiaType, name, crm, phone }',
              'hasBloodType',
              'hasHemoconcentrates',
              'cellSaverSpec',
              'bloodBagQuantity',
              'equipment { name, specification }',
              'cme { description, quantity }'
            ]
          )

          setSurgicalOrder(response)
        } catch (e: any) {
          toast.error(e.message)
        } finally {
          setIsLoading(false)
        }
      }
      loadData()
    }, [])

    const {
      procedure,
      cid,
      opme,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      healthInsurance: insurance,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      hospital
    } = surgicalOrder

    const documentList: ListItem[] = [
      {
        title: 'Adicionar documento',
        click: () => {
          history.push('/pedido-anexo', {
            surgicalOrder: surgicalOrder
          })
        },
        icon: ArrowIcon
      }
    ]

    const isStepValid = (schema: keyof typeof schemas): boolean => {
      const { validationSchema, adapter } = schemas[schema]
      try {
        const isValid = validationSchema.isValidSync(adapter(surgicalOrder))
        return !!isValid
      } catch (error) {
        return false
      }
    }

    const isAllValid = (() => {
      const keys = Object.keys(schemas) as (keyof typeof schemas)[]
      const hasInvalid = keys.some((key) => !isStepValid(key))
      return !hasInvalid
    })()

    const items: ListItem[] = [
      {
        title: 'Caráter da Reserva',
        icon: isStepValid('reservationInfo') ? CheckIcon : ArrowIcon,
        click: () =>
          history.push('/pedido-cirurgico/carater-reserva', {
            surgical_order_id: state.surgicalOrder.surgical_order_id,
            surgicalOrder
          })
      },
      {
        title: 'Procedimento',
        icon: isStepValid('procedure') ? CheckIcon : ArrowIcon,
        click: () =>
          history.push('/pedido-cirurgico/procedimento', {
            procedure,
            cid,
            surgical_order_id: state.surgicalOrder.surgical_order_id,
            clinicalHistory: surgicalOrder.clinicalHistory,
            expectedDate: moment(surgicalOrder.expectedDate).format(
              'DD/MM/YYYY'
            )
          })
      },
      {
        title: 'Dados Cirúrgicos',
        icon: isStepValid('surgicalInfo') ? CheckIcon : ArrowIcon,
        click: () =>
          history.push('/pedido-cirurgico/dados-cirurgicos', {
            surgical_order_id: state.surgicalOrder.surgical_order_id,
            surgicalOrder
          })
      },
      {
        title: 'Equipe Médica',
        icon: isStepValid('medicalTeam') ? CheckIcon : ArrowIcon,
        click: () =>
          history.push('/pedido-cirurgico/equipe-medica', {
            surgical_order_id: state.surgicalOrder.surgical_order_id,
            surgicalOrder
          })
      },
      {
        title: 'Banco de Sangue',
        icon: isStepValid('bloodBank') ? CheckIcon : ArrowIcon,
        click: () =>
          history.push('/pedido-cirurgico/banco-de-sangue', {
            surgical_order_id: state.surgicalOrder.surgical_order_id,
            surgicalOrder
          })
      },
      {
        title: 'Equipamento',
        icon: isStepValid('equipments') ? CheckIcon : ArrowIcon,
        click: () =>
          history.push('/pedido-cirurgico/equipamentos', {
            surgical_order_id: state.surgicalOrder.surgical_order_id,
            surgicalOrder
          })
      },
      {
        title: 'OPME',
        icon: isStepValid('opme') ? CheckIcon : ArrowIcon,
        click: () =>
          history.push('/pedido-cirurgico/opme', {
            surgical_order_id: state.surgicalOrder.surgical_order_id,
            opme
          })
      },
      {
        title: 'CME',
        icon: isStepValid('cme') ? CheckIcon : ArrowIcon,
        click: () =>
          history.push('/pedido-cirurgico/cme', {
            surgical_order_id: state.surgicalOrder.surgical_order_id,
            cmes: surgicalOrder.cme
          })
      }
    ]

    return (
      <AddSurgicalOrderLayout
        items={items}
        patientName={surgicalOrder?.patientName}
        surgeryName={procedure?.[0].description}
        addFavorite={addFavorite}
        opme={surgicalOrder?.opme}
        disabled={!isAllValid}
        documentList={documentList}
        surgicalOrder={surgicalOrder}
      />
    )
  }
)

export default AddSurgicalOrder

export type AddDoctorSurgicalOrderFormValues = {
  patient: PatientModel
  hospital: Hospital
  clinicalHistory: string
  provider1: string
  provider2: string
  provider3: string
  observation?: string
  otherAllergies: string
  hospitalizationType: string
  hospitalizationMode: string
  room: string
  freeze: boolean
  dialitic: boolean
  bloodTransfusion: boolean
  specialTreatment: boolean
  latex: any
  tuss: any[]
  cids: any[]
  opmes: any[]
  use_opme: boolean
  healthInsurance?: HealthInsurance
  allergy?: string[]
  doctor?: Doctor
}

type Solicitations = {
  description: string
  quantity: number
  observation: string
}

export type Procedure = {
  isMain: boolean
  procedure_code: string
  code: string
  description: string
  quantity: number
  doctor_name: string
}

export type Cid = {
  code: string
  description: string
}

export type Opme = {
  solicitations: Array<Solicitations>
  providers: string[]
}

import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  overflow-x: auto;
`

export const InputsContainer = styled.div`
  margin-top: 12px;
`

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;

  & + & {
    margin-top: 25px;
  }

  & > div {
    margin: 0 !important;
    width: 100%;

    &:first-child {
      input {
        padding-right: 54px;
      }
    }
  }

  button {
    width: 54px;
    height: 54px;
    position: absolute;
    left: calc(100% - 56px);
    bottom: 0;
    border-radius: 0px 10px 10px 0px;

    path {
      fill: white;
      opacity: 1;
    }
  }
`

export const ProcedureTable = styled.div`
  min-height: 20rem;
`

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 0;
`

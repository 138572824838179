import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  overflow-x: auto;
`

export const InputsContainer = styled.div`
  margin-top: 12px;
`

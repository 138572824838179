import React, { useEffect, useRef, useState } from 'react'

import { useFormik } from 'formik'
import { toast } from 'react-toastify'

import { UpdateSurgicalOrder } from 'domain/usecases/surgical-order/update-surgical-order'
import Button from 'presentation/shared/components/Button'
import Chip from 'presentation/shared/components/Chip'
import { Container } from 'presentation/shared/components/Container'
import { Label } from 'presentation/shared/components/TextField/styles'
import { useHistory } from 'react-router-dom'
import * as FormS from '../styles'
import * as yup from 'yup'

import Header from 'presentation/shared/components/Header'
import TextField from 'presentation/shared/components/TextField'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'

type Props = {
  updateSurgicalOrder: UpdateSurgicalOrder
  surgicalOrderId: number
  initialValues?: SurgicalOrderModel
}

export const adaptSurgicalOrderToFormValues = (
  surgicalOrder?: SurgicalOrderModel
): AddSurgicalInfoFormValues => {
  return {
    ...surgicalOrder,
    hasAllergy: !!surgicalOrder.allergy,
    hasPreliminaryScintigraphyTime: !!surgicalOrder.preliminaryScintigraphyTime,
    hasXrayOfPieceTime: !!surgicalOrder.xrayOfPieceTime
  } as AddSurgicalInfoFormValues
}

export default function AddSurgicalInfo({
  updateSurgicalOrder,
  surgicalOrderId,
  initialValues
}: Props) {
  const history = useHistory()

  const [scrollToAllergy, setScrollToAllergy] = useState(false)

  const {
    values,
    handleChange,
    submitForm,
    setFieldValue,
    handleBlur,
    touched,
    errors,
    isValid
  } = useFormik({
    initialValues: adaptSurgicalOrderToFormValues(initialValues),
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: surgicalInfoValidationSchema,
    onSubmit: async (formValue) => {
      try {
        const body = {
          preliminaryScintigraphyTime: formValue.hasPreliminaryScintigraphyTime
            ? formValue.preliminaryScintigraphyTime
            : null,
          xrayOfPieceTime: formValue.hasXrayOfPieceTime
            ? formValue.xrayOfPieceTime
            : null,
          allergy: formValue.hasAllergy ? formValue.allergy : null,
          laterality: formValue.laterality
        }
        await updateSurgicalOrder.update({
          surgical_order_id: surgicalOrderId,
          ...body
        })
        return history.goBack()
      } catch (e: any) {
        toast.error(e.message)
      }
    }
  })

  const setChipField = (fieldName: string, value: boolean | string) => {
    setFieldValue(fieldName, values[fieldName] === value ? null : value)
  }

  const containerBottomRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollToAllergy && values.hasAllergy) {
      containerBottomRef.current?.scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest'
      })
    }
  }, [scrollToAllergy, values.hasAllergy])

  return (
    <>
      <Header />
      <Container
        title="Dados cirúrgicos"
        primaryButton={
          <Button
            type="button"
            disabled={!isValid}
            fullWidth
            onClick={() => submitForm()}
          >
            Próximo
          </Button>
        }
        secondaryButton={
          <Button variant="outlined" fullWidth onClick={history.goBack}>
            Cancelar
          </Button>
        }
      >
        <FormS.LabelContainer>
          <Label>Cintilografia prévia</Label>
        </FormS.LabelContainer>
        <FormS.FlexContainer>
          {defaultOptions.map((option) => (
            <Chip
              variant="square"
              key={option.label}
              label={option.label}
              customValue={option.value}
              name="hasPreliminaryScintigraphyTime"
              onCheck={(val) =>
                setChipField('hasPreliminaryScintigraphyTime', val)
              }
              checked={option.value === values.hasPreliminaryScintigraphyTime}
              dataTestId={option.value + 'hasPreliminaryScintigraphyTime'}
            />
          ))}
        </FormS.FlexContainer>
        {values.hasPreliminaryScintigraphyTime && (
          <TextField
            onChange={handleChange('preliminaryScintigraphyTime')}
            value={values.preliminaryScintigraphyTime}
            name="preliminaryScintigraphyTime"
            id="preliminaryScintigraphyTime"
            placeholder="Hora"
            style={{ marginTop: '24px' }}
            onBlur={handleBlur}
            error={
              touched.preliminaryScintigraphyTime
                ? errors.preliminaryScintigraphyTime
                : undefined
            }
          />
        )}
        <FormS.LabelContainer>
          <Label>
            Lateralidade<span>*</span>
          </Label>
        </FormS.LabelContainer>
        <FormS.FlexContainer>
          {lateralities.map((laterality) => (
            <Chip
              variant="square"
              key={laterality.label}
              label={laterality.label}
              customValue={laterality.value}
              name="laterality"
              onCheck={(val) => setChipField('laterality', val)}
              checked={laterality.value === values.laterality}
              dataTestId={laterality.value}
            />
          ))}
        </FormS.FlexContainer>

        <FormS.LabelContainer>
          <Label>Raio-X da peça</Label>
        </FormS.LabelContainer>
        <FormS.FlexContainer>
          {defaultOptions.map((option) => (
            <Chip
              variant="square"
              key={option.label}
              label={option.label}
              customValue={option.value}
              name="hasXrayOfPieceTime"
              onCheck={(val) => setChipField('hasXrayOfPieceTime', val)}
              checked={option.value === values.hasXrayOfPieceTime}
              dataTestId={option.value + 'hasXrayOfPieceTime'}
            />
          ))}
        </FormS.FlexContainer>
        {values.hasXrayOfPieceTime && (
          <TextField
            onChange={handleChange('xrayOfPieceTime')}
            value={values.xrayOfPieceTime}
            name="xrayOfPieceTime"
            id="xrayOfPieceTime"
            placeholder="Hora"
            style={{ marginTop: '24px' }}
            onBlur={handleBlur}
            error={touched.xrayOfPieceTime ? errors.xrayOfPieceTime : undefined}
          />
        )}

        <FormS.LabelContainer>
          <Label>Alergias</Label>
        </FormS.LabelContainer>
        <FormS.FlexContainer style={{ marginBottom: '20px' }}>
          {allergiesOptions.map((option) => (
            <Chip
              variant="square"
              key={option.label}
              label={option.label}
              customValue={option.value}
              name="hasAllergy"
              onCheck={(val) => {
                setChipField('hasAllergy', val)
                setScrollToAllergy(true)
              }}
              checked={option.value === values.hasAllergy}
              dataTestId={option.value + 'hasAllergy'}
            />
          ))}
        </FormS.FlexContainer>
        {values.hasAllergy && (
          <TextField
            onChange={handleChange('allergy')}
            value={values.allergy}
            name="allergy"
            id="allergy"
            placeholder="Especificar alergia"
            style={{ marginBottom: '24px' }}
            onBlur={handleBlur}
            error={touched.allergy ? errors.allergy : undefined}
          />
        )}
        <div ref={containerBottomRef}></div>
      </Container>
    </>
  )
}

const defaultOptions: ChipItem[] = [
  { label: 'Sim', value: true },
  {
    label: 'Não',
    value: false
  }
]

const lateralities = [
  {
    label: 'Esquerdo',
    value: 'LEFT'
  },
  {
    label: 'Direito',
    value: 'RIGHT'
  },
  {
    label: 'Bilateral',
    value: 'BILATERAL'
  },
  {
    label: 'Não se aplica',
    value: 'NOT_APPLICABLE'
  }
]

const allergiesOptions = [
  {
    label: 'Não informado',
    value: false
  },
  {
    label: 'Sim',
    value: true
  }
]

type ChipItem = {
  label: string
  value: any
}

export type AddSurgicalInfoFormValues = {
  hasPreliminaryScintigraphyTime: boolean
  preliminaryScintigraphyTime?: string

  laterality?: string

  hasXrayOfPieceTime: boolean
  xrayOfPieceTime?: string

  hasAllergy: boolean
  allergy: string
}

const isAStringRequiredWhenIsTrue = (field: string) => {
  return yup.string().optional().nullable().notRequired().when(field, {
    is: true,
    then: yup.string().nullable().required()
  })
}

const defaultBoolean = yup.boolean().optional().nullable().notRequired()

export const surgicalInfoValidationSchema = yup.object().shape({
  hasPreliminaryScintigraphyTime: defaultBoolean,
  preliminaryScintigraphyTime: isAStringRequiredWhenIsTrue(
    'hasPreliminaryScintigraphyTime'
  ),
  hasXrayOfPieceTime: defaultBoolean,
  xrayOfPieceTime: isAStringRequiredWhenIsTrue('hasXrayOfPieceTime'),
  hasAllergy: defaultBoolean,
  allergy: isAStringRequiredWhenIsTrue('hasAllergy'),
  laterality: yup.string().nullable().required()
})

import React, { useState } from 'react'
import { LoadDoctorSurgeryRequestCount } from 'domain/usecases/doctor/load-doctor-surgery-request-count'
import { DoctorScheduleCalender } from 'presentation/doctor/components/DoctorScheduleCalendar'
import BottomMenu from 'presentation/shared/components/BottomMenu'
import { Container } from 'presentation/shared/components/Container'
import Header from 'presentation/shared/components/Header'
import Heading from 'presentation/shared/components/Heading'
import SupportText from 'presentation/shared/components/SupportText'
import { useHistory } from 'react-router-dom'
import { ScheduleState } from './Constants/schedule-state'
import * as S from './styles'
import { LoadDoctorSurgicalOrders } from 'domain/usecases/doctor/load-doctor-surgical-orders'
import Carousel, {
  CarouselState
} from 'presentation/shared/components/Carousel'
import ServiceOverview from 'presentation/shared/components/ServiceOverview'
import LoadingGif from 'presentation/shared/components/LoadingGif'
import moment from 'moment-timezone'

type DoctorHomeLayoutProps = {
  name?: string
  doctorSchedule?: LoadDoctorSurgeryRequestCount.Model
  pendenciesUnresolved?: number
  unfinishedOrders: number
  surgicalOrders?: LoadDoctorSurgicalOrders.Model
  isLoading?: boolean
}

export default function DoctorHomeLayout({
  name,
  doctorSchedule,
  pendenciesUnresolved,
  unfinishedOrders = 0,
  surgicalOrders,
  isLoading
}: DoctorHomeLayoutProps) {
  const history = useHistory()
  const [carousel, setCarousel] = useState({} as CarouselState)

  const CarouselChildren: JSX.Element[] = []

  if (surgicalOrders) {
    surgicalOrders?.data?.forEach((surgicalOrder) => {
      CarouselChildren.push(
        <ServiceOverview
          type="Cirurgia"
          unit={surgicalOrder.hospital.nickname}
          date={moment(surgicalOrder.createdAt).format('DD/MM/YYYY - HH:mm')}
          patientName={surgicalOrder.patientName}
          title={surgicalOrder.procedure[0].description}
          status={
            surgicalOrder.status
              .filter((status) => status.isActive === true)
              .map((status) => status.status)[0]
          }
          onClick={() =>
            history.push('/cirurgia', {
              id: surgicalOrder.surgical_order_id
            })
          }
        />
      )
    })
  }

  return (
    <>
      <Header />
      {pendenciesUnresolved && pendenciesUnresolved > 0 ? (
        <S.PendencyAlert onClick={() => history.push('/pendencias')}>
          Você possui {pendenciesUnresolved} pendências
        </S.PendencyAlert>
      ) : null}
      <Container>
        <SupportText style={{ fontWeight: 400 }}>Seja bem-vindo(a)</SupportText>
        <Heading style={{ fontWeight: 700 }} size="small" as="h3">
          {name}
        </Heading>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Heading
            color="primary"
            style={{ fontWeight: 700, marginTop: '24px', marginBottom: '18px' }}
            size="small"
            as="h4"
          >
            Próximos atendimentos
          </Heading>
          {surgicalOrders?.pageInfo?.totalItems > 5 ? (
            <SupportText
              onClick={() =>
                history.push('/agendamentos', {
                  params: ScheduleState.ALL,
                  unfinished: false
                })
              }
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                fontSize: '14px'
              }}
            >
              Ver todos
            </SupportText>
          ) : null}
        </div>

        {isLoading ? (
          <LoadingGif />
        ) : (
          <div
            className="container-card"
            style={{
              marginBottom: '1rem'
            }}
          >
            {CarouselChildren.length === 0 && !isLoading ? (
              <S.EmptyAttendances>
                Você não possui nenhum atendimento próximo
              </S.EmptyAttendances>
            ) : (
              <Carousel
                state={carousel}
                setState={setCarousel}
                slidesPerView={1}
                spaceBetween={50}
                dynamicHeight={true}
                pagination={{ type: 'bullets' }}
                bulletBottomPosition={'0'}
              >
                {[...CarouselChildren.slice(0, 5)]}
              </Carousel>
            )}
          </div>
        )}

        <S.UnfinishedOrdersWrapper
          onClick={() =>
            history.push('/agendamentos', {
              params: ScheduleState.UNFINISHED,
              unfinished: true
            })
          }
        >
          <p>Pedidos não finalizados</p>
          <span>{unfinishedOrders}</span>
        </S.UnfinishedOrdersWrapper>
        <Heading
          color="primary"
          as="h4"
          size="small"
          style={{
            marginBottom: '18px',
            fontWeight: 700
          }}
        >
          Agenda Médica
        </Heading>
        <DoctorScheduleCalender />
      </Container>
      <BottomMenu />
    </>
  )
}

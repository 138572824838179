import React, { useState } from 'react'
import * as S from '../styles'
import { useFormik } from 'formik'
import * as yup from 'yup'
import 'main/config/yup'

import Button from 'presentation/shared/components/Button'
import TextField from 'presentation/shared/components/TextField'
import { cpfMask, phoneMask } from 'presentation/utils/masks'
import { AddSecretary } from 'domain/usecases/secretary/add-secretary'
import { toast } from 'react-toastify'
import Modal from 'presentation/shared/components/Modal'
import { useHistory } from 'react-router'
import { Container } from 'presentation/shared/components/Container'
import { validateCpf } from 'presentation/utils/validators/cpf-validator'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { useServices } from 'presentation/hooks/use-services'

type AddSecretaryFormProps = {
  addSecretary?: AddSecretary
  initialValues?: AddSecretaryFormValues
} & WithLoadingProps

const AddSecretaryForm = WithLoading(
  ({
    addSecretary,
    initialValues = {} as AddSecretaryFormValues,
    setIsLoading
  }: AddSecretaryFormProps) => {
    const secretaryService = useServices().secretary
    const doctorService = useServices().doctor
    const [secretaryNotExist, setSecretaryNotExist] = useState(false)
    const [handleSuccessModal, setHandleSuccessModal] = useState(false)
    const history = useHistory()

    const validationSchema = yup.object().shape({
      name: yup.string().required(),
      cpf: yup.string().required('Informe um CPF').cpf(),
      email: yup.string().email().required(),
      phone: secretaryNotExist
        ? yup
            .string()
            .matches(
              /^(?:\+)?[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/,
              'Telefone inválido'
            )
            .required()
        : yup.string().required()
    })

    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      validateOnMount: true,
      onSubmit: async (values) => {
        try {
          if (secretaryNotExist) {
            await addSecretary?.add({
              ...values,
              cpf: values.cpf.replace(/\D+/g, ''),
              phone: values.phone.replace(/\D+/g, '')
            })
            setHandleSuccessModal(true)
          } else {
            if (values.secretary_id) {
              const linked = await doctorService.linkSecretary({
                secretary_id: values.secretary_id
              })
              if (linked) {
                setHandleSuccessModal(true)
              } else {
                toast.error('Não foi possível vincular a secretária!')
              }
            }
          }
        } catch (error: any) {
          toast.error(error.message)
        }
      }
    })

    const handleCpfChange = async (value: string) => {
      formik.setFieldValue('cpf', value)
      if (validateCpf(value) && !secretaryNotExist) {
        try {
          setIsLoading(true)

          const secretaryRegistered = await secretaryService.getSecretaryByCpf({
            cpf: value.replace(/\D+/g, ''),
            fields: ['secretary_id, name, email, phone']
          })
          if (secretaryRegistered.secretary_id) {
            formik.setFieldValue(
              'secretary_id',
              secretaryRegistered.secretary_id
            )
          }
          formik.setFieldValue('name', secretaryRegistered.name)
          formik.setFieldValue('email', secretaryRegistered.email)
          formik.setFieldValue('phone', secretaryRegistered.phone)
        } catch (error: any) {
          if (error.name === 'NotFoundError') {
            toast.error('CPF não encontrado. Informe os dados para vincular')
            setSecretaryNotExist(true)
          } else {
            toast.error(error.message)
          }
        } finally {
          setIsLoading(false)
        }
      }
    }

    return (
      <Container
        actualPageText="Voltar"
        actualPageOnClick={history.goBack}
        title="Nova Secretária"
        primaryButton={
          <Button
            fullWidth
            disabled={!formik.isValid || formik.isSubmitting}
            onClick={formik.submitForm}
            type="button"
          >
            {secretaryNotExist ? 'Enviar convite' : 'Vincular'}
          </Button>
        }
        secondaryButton={
          <Button
            variant="outlined"
            fullWidth
            onClick={history.goBack}
            type="button"
            style={{ marginTop: '0.5rem' }}
          >
            Cancelar
          </Button>
        }
      >
        {formik.isValid}
        <S.Wrapper role="form" onSubmit={formik.handleSubmit}>
          <TextField
            label="CPF"
            onInputChange={handleCpfChange}
            onBlur={formik.handleBlur('cpf')}
            initialValue={formik.initialValues.cpf}
            error={formik.touched.cpf ? formik.errors.cpf : undefined}
            mask={cpfMask}
            required
          />
          <TextField
            label="Nome Completo"
            onInputChange={formik.handleChange('name')}
            onBlur={formik.handleBlur('name')}
            initialValue={formik.initialValues.name}
            defaultValue={formik.values.name}
            error={formik.touched.name ? formik.errors.name : undefined}
            style={{ marginTop: '1rem' }}
            disabled={!secretaryNotExist}
            required
          />
          <TextField
            name="email"
            label="E-mail"
            onInputChange={formik.handleChange('email')}
            onBlur={formik.handleChange('email')}
            initialValue={formik.initialValues.email}
            defaultValue={formik.values.email}
            error={formik.touched.email ? formik.errors.email : undefined}
            style={{ marginTop: '1rem' }}
            disabled={!secretaryNotExist}
            required
          />
          <TextField
            name="phone"
            label="Celular"
            onInputChange={formik.handleChange('phone')}
            onBlur={formik.handleChange('phone')}
            initialValue={formik.initialValues.email || '55'}
            defaultValue={formik.values.phone}
            error={formik.touched.email ? formik.errors.email : undefined}
            mask={phoneMask}
            disabled={!secretaryNotExist}
            style={{ margin: '1rem 0' }}
            required
          />
          <Modal
            title="Secretária vinculada com sucesso"
            show={handleSuccessModal}
            close={() => history.push('/secretaria')}
          />
        </S.Wrapper>
      </Container>
    )
  }
)

type AddSecretaryFormValues = {
  name: string
  cpf: string
  email: string
  phone: string
  secretary_id?: number
}

export default AddSecretaryForm

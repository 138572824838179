import { ScheduleType } from 'common/enum/schedule-type'
import { Schedule } from 'domain/entities/schedule-model'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { ScheduleData } from 'domain/usecases/doctor/load-doctor-schedule'
import Button from 'presentation/shared/components/Button'
import Heading from 'presentation/shared/components/Heading'
import SheetModal from 'presentation/shared/components/SheetModal'
import React from 'react'
import { AmbulatoryScheduleDetails } from './AmbulatoryScheduleDetails'
import * as S from './styles'
import { SurgicalScheduleDetails } from './SurgicalScheduleDetails'

type DoctorScheduleModalProps = {
  show: boolean
  onClose: () => void
  data: ScheduleData
}

export const DoctorScheduleModal = ({
  show,
  onClose,
  data
}: DoctorScheduleModalProps) => {
  const getTitle = () => {
    switch (data?.type) {
      case ScheduleType.AMBULATORY:
        return (data?.data as Schedule)?.serviceDescription
      case ScheduleType.SURGICAL:
        return (data?.data as SurgicalOrderModel)?.procedure?.find(
          (surgicalProcedure) => surgicalProcedure.isMain
        )?.description
      default:
        return '-'
    }
  }

  const getComponent = () => {
    switch (data?.type) {
      case ScheduleType.AMBULATORY:
        return <AmbulatoryScheduleDetails data={data?.data as Schedule} />
      case ScheduleType.SURGICAL:
        return (
          <SurgicalScheduleDetails data={data?.data as SurgicalOrderModel} />
        )
      default:
        return <></>
    }
  }

  return (
    <SheetModal size={1000} isOpen={show} onClose={onClose}>
      <S.Container>
        <S.HeaderContainer>
          <Heading size="large">{getTitle()}</Heading>
        </S.HeaderContainer>
        {getComponent()}
        <Button fullWidth onClick={onClose}>
          Fechar
        </Button>
      </S.Container>
    </SheetModal>
  )
}

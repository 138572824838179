import React, { useEffect, useState } from 'react'

import { useFormikContext } from 'formik'
import { sortBy } from 'lodash'
import TextField from 'presentation/shared/components/TextField'
import { Label } from 'presentation/shared/components/TextField/styles'
import Button from 'presentation/shared/components/Button'
import { Container } from 'presentation/shared/components/Container'
import * as S from './styles'
import { CreateSurgicalOrderFormValues } from 'presentation/doctor/pages/AddSurgicalOrder/CreateSurgicalOrder'
import Carousel, {
  CarouselState
} from 'presentation/shared/components/Carousel'
import { cpfMask, phoneMask } from 'presentation/utils/masks'
import SelectField from 'presentation/shared/components/SelectField'
import { HealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'
import getPhoneWithCountryCode from 'common/utils/getPhoneWithCountryCode'
import { CountryPhoneCode } from 'common/enum/country-phone-codes'
import { UploadSection } from 'presentation/shared/components/UploadSection'
import { useUploadSection } from 'presentation/hooks/use-upload-section'
import { DocumentsType } from 'domain/entities/document-types'
import userEvent from '@testing-library/user-event'

type Props = {
  submit: () => void
  insurances: HealthInsurancePlans[]
  goBack: () => void
  setGoBackModal(): void
  loadHealthInsurances?: (hospital_id: number) => void
}

export default function ReviewPatient({
  submit,
  insurances,
  goBack,
  setGoBackModal,
  loadHealthInsurances
}: Props) {
  const [carousel, setCarousel] = useState({} as CarouselState)

  const { values, setFieldValue } =
    useFormikContext<CreateSurgicalOrderFormValues>()

  const handleInsuranceChange = (id: string) => {
    const insurance = insurances?.find((val) => val.code.toString() === id)
    const formikValue = {
      healthInsuranceCode: insurance?.code,
      healthInsuranceName: insurance?.description,
      ansRegister: insurance?.ansRegister
    }
    setFieldValue('healthInsurance', formikValue)
  }
  const handleHealthCard = (healthCard: string) => {
    setFieldValue('healthInsurance.healthCard', healthCard)
  }

  const healthCardDocumentType = DocumentsType.healthCardDocumentType

  const { filesInfo, onAdd, onUpdate, onDownload, onDelete } = useUploadSection(
    {
      ...healthCardDocumentType,
      verifyUniqueFile: true,
      initialFiles: values.healthCardFiles,
      stateFiles: values.healthCardFiles,
      setFilesFunction: (files) => {
        setFieldValue('healthCardFiles', files)
      }
    }
  )

  const canUpload = {
    canAdd: filesInfo.canAdd,
    canDelete: true,
    canDownload: true,
    canUpdate: true,
    canPreview: false
  }
  useEffect(() => {
    if (values?.hospital?.hospital_id) {
      loadHealthInsurances(values?.hospital?.hospital_id)
    }
  }, [values?.hospital?.hospital_id])

  return (
    <Container
      actualPageText="Voltar"
      actualPageOnClick={goBack}
      title="Dados do paciente"
      subtitle="Confirme os dados do paciente"
      primaryButton={
        <Button
          onClick={carousel.activeIndex === 1 ? submit : carousel.slideNext}
          disabled={
            carousel.activeIndex === 1 &&
            !values.healthInsurance?.healthInsuranceCode
          }
          fullWidth
        >
          Confirmar
        </Button>
      }
      secondaryButton={
        <Button onClick={() => setGoBackModal()} fullWidth variant="outlined">
          Cancelar
        </Button>
      }
    >
      <Carousel state={carousel} setState={setCarousel} touch={false}>
        <S.Wrapper>
          <TextField
            label="CPF"
            disabled
            defaultValue={values.patient?.cpf}
            mask={cpfMask}
            data-testid="review-patient-cpf-input"
          />
          <TextField
            label="Nome do paciente"
            disabled
            value={values.patient?.name}
            data-testid="review-patient-name-input"
          />
          <TextField
            label="E-mail"
            disabled
            value={values.patient?.email}
            data-testid="review-patient-email-input"
          />
          <TextField
            label="Celular"
            disabled
            defaultValue={getPhoneWithCountryCode(
              values.patient?.phone,
              CountryPhoneCode.BRAZIL
            )}
            mask={phoneMask}
            data-testid="review-patient-phone-input"
          />
        </S.Wrapper>
        <S.Wrapper>
          <SelectField
            label="Convênio"
            value={values.healthInsurance?.healthInsuranceCode || ''}
            onInputChange={(id) => handleInsuranceChange(id)}
            items={sortBy(
              insurances.map((insurance) => ({
                label: insurance.description,
                value: insurance.code
              })),
              (val) => val.label
            )}
            defaultValue=""
            required
          />
          <TextField
            label="Número da Carteirinha"
            defaultValue={values.healthInsurance?.healthCard}
            onInputChange={(healthCard) => handleHealthCard(healthCard)}
            placeholder="Número da Carteinha"
          />
          <Label labelColor="primary" style={{ marginTop: '8px' }}>
            Carteirinha
          </Label>
          <S.Documents>
            <UploadSection
              files={values.healthCardFiles || []}
              can={canUpload}
              onAdd={onAdd}
              onDelete={onDelete}
              onUpdate={onUpdate}
              onDownload={onDownload}
              errors={filesInfo.errorMessage}
              acceptFiles={healthCardDocumentType.acceptFiles}
            />
          </S.Documents>
        </S.Wrapper>
      </Carousel>
    </Container>
  )
}

import React, { useState } from 'react'
import Carousel, {
  CarouselState
} from 'presentation/shared/components/Carousel'
import OnboardingPage from 'presentation/shared/pages/Onboarding'
import FirstSlide from 'presentation/assets/onboarding-images/welcome.svg'
import SecondSlide from 'presentation/assets/onboarding-images/simplified-shipping.svg'
import ThirdSlide from 'presentation/assets/onboarding-images/journey.svg'
import FourthSlide from 'presentation/assets/onboarding-images/ambulatorial-schedule.svg'
import FifthSlide from 'presentation/assets/onboarding-images/secretary.svg'
import SixthSlide from 'presentation/assets/onboarding-images/favorite.svg'
import * as S from './styles'
import { useHistory } from 'react-router'
import { FinishAppOnboarding } from 'domain/usecases/user/finish-app-onboarding'
import { toast } from 'react-toastify'

type DoctorOnboardingLayoutProps = {
  finishAppOnboarding: FinishAppOnboarding
}

export default function DoctorOnboardingLayout({
  finishAppOnboarding
}: DoctorOnboardingLayoutProps) {
  const [carouselState, setCarouselState] = useState({} as CarouselState)
  const history = useHistory()

  const handleGoBack = () => carouselState.slidePrev()
  const handleNext = () => carouselState.slideNext()
  const handleClickOnButton = () =>
    finishAppOnboarding
      .finishAppOnboarding()
      .then(() => history.push('/home'))
      .catch((error) => toast.error(error.message))

  return (
    <S.Wrapper>
      <Carousel
        state={carouselState}
        setState={setCarouselState}
        slidesPerView={1}
        touch={false}
        pagination={
          !carouselState.isLast
            ? {
                clickable: true
              }
            : false
        }
        fade={{ crossFade: true }}
        effect={'fade'}
        speed={1000}
      >
        <OnboardingPage
          title={'Seja bem-vindo(a)!'}
          body={
            <S.TextContainer>
              <p>
                Agora você faz parte do <span>Jornada Cirúrgica</span>, a nossa
                linha de cuidado!
              </p>
              <p>
                Conheça algumas facilidades que você terá ao utilizar essa
                plataforma!
              </p>
            </S.TextContainer>
          }
          image={FirstSlide}
          slideNext={handleNext}
          buttonAction={handleClickOnButton}
        />
        <OnboardingPage
          title={'Envio simplificado'}
          body={
            <S.TextContainer>
              <p>
                O pedido cirúrgico agora é <span>100% digital</span> pelo
                Jornada Cirúrgica, em poucos minutos é possível{' '}
                <span>realizar a solicitação.</span>
              </p>
            </S.TextContainer>
          }
          image={SecondSlide}
          goBackAction={handleGoBack}
          slideNext={handleNext}
          buttonAction={handleClickOnButton}
        />
        <OnboardingPage
          title={'Status do pedido e documentação'}
          body={
            <S.TextContainer>
              <p>
                Você poderá acompanhar em <span>tempo real</span> os status do
                pedido e gerenciar todas as <span>pendências</span> e/ou{' '}
                <span>documentações.</span>
              </p>
            </S.TextContainer>
          }
          image={ThirdSlide}
          goBackAction={handleGoBack}
          slideNext={handleNext}
          buttonAction={handleClickOnButton}
        />
        <OnboardingPage
          title={'Agenda ambulatorial e cirúrgica'}
          body={
            <S.TextContainer>
              <p>
                Você também poderá <span>visualizar</span> e{' '}
                <span>acessar</span> os agendamentos <span>ambulatoriais</span>{' '}
                e <span>cirúrgicos</span> integrados.
              </p>
            </S.TextContainer>
          }
          image={FourthSlide}
          goBackAction={handleGoBack}
          slideNext={handleNext}
          buttonAction={handleClickOnButton}
        />
        <OnboardingPage
          title={'Vínculo de secretárias'}
          body={
            <S.TextContainer>
              <p>
                Possibilidade de <span>vincular</span> as secretárias ao{' '}
                <span>seu perfil</span> e permitir que elas{' '}
                <span>realizem pedidos por você.</span>
              </p>
            </S.TextContainer>
          }
          image={FifthSlide}
          goBackAction={handleGoBack}
          slideNext={handleNext}
          buttonAction={handleClickOnButton}
        />
        <OnboardingPage
          title={'Favoritar procedimentos'}
          body={
            <S.TextContainer>
              <p>
                Você poderá favoritar os <span>procedimentos cirúrgicos</span>{' '}
                que preferir ou os que forem do seu maior interesse.
              </p>
            </S.TextContainer>
          }
          image={SixthSlide}
          goBackAction={handleGoBack}
          buttonAction={handleClickOnButton}
          showButton
        />
      </Carousel>
    </S.Wrapper>
  )
}

import React, { useEffect, useState } from 'react'

import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'

import { Patient } from 'domain/entities/patient-model'
import { Hospital } from 'domain/entities/hospital-model'
import { LoadPatientByCpf } from 'domain/usecases/patient/load-patient-by-cpf'
import SelectHospital from 'presentation/doctor/components/Forms/AddSurgicalOrder/SelectHospital'
import SelectPatient from 'presentation/doctor/components/Forms/AddSurgicalOrder/SelectPatient'
import { SurgeryCenter } from 'domain/entities/surgery-center-model'
import Carousel, {
  CarouselState
} from 'presentation/shared/components/Carousel'
import { LoadHospitals } from 'domain/usecases/hospital/load-hospitals'
import { LoadSurgeryCenter } from 'domain/usecases/surgery-center/load-surgery-center'
import ReviewPatient from 'presentation/doctor/components/Forms/AddSurgicalOrder/ReviewPatient'
import { AddDoctorSurgicalOrder } from 'domain/usecases/doctor/add-doctor-surgical-order'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import Modal from 'presentation/shared/components/Modal'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import {
  HealthInsurancePlans,
  LoadHealthInsurancePlans
} from 'domain/usecases/health-insurance/load-health-insurance-plans'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { HealthInsurance } from 'domain/entities/health-insurance'
import { UploadPatientDocument } from 'domain/usecases/patient/upload-patient-document'
import { PatientDocument } from 'common/enum/patient-document'
import { CreateSurgicalOrderGuides } from 'domain/usecases/surgical-order/create-surgical-order-guides'
import * as S from '../../../components/Forms/AddSurgicalOrder/ReviewPatient/styles'
import Button from '../../../../shared/components/Button'
import { ListFile } from 'presentation/shared/components/UploadSection/UploadListFiles/UploadListFile'
import { renameFile } from 'common/utils/file/renameFile'

type Props = {
  findPatient?: LoadPatientByCpf
  loadHospitals?: LoadHospitals
  loadSurgeryCenter?: LoadSurgeryCenter
  addDoctorSurgicalOrder?: AddDoctorSurgicalOrder
  loadInsurances: LoadHealthInsurancePlans
  uploadSurgicalOrderDocument: UploadPatientDocument
  createSurgicalOrderGuides: CreateSurgicalOrderGuides
} & WithLoadingProps

type LocationType = {
  state: {
    patient: Patient
    step: number
  }
}

export const CreateSurgicalOrder = WithLoading(
  ({
    findPatient,
    loadHospitals,
    loadSurgeryCenter,
    addDoctorSurgicalOrder,
    loadInsurances,
    uploadSurgicalOrderDocument,
    setIsLoading
  }: Props) => {
    const location: LocationType = useLocation()

    const [carousel, setCarousel] = useState({} as CarouselState)
    const [hospitals, setHospitals] = useState<Hospital[]>([])
    const [surgeryCenters, setSurgeryCenters] = useState<SurgeryCenter[]>([])
    const [successModal, setSuccessModal] = useState<boolean>(false)
    const [insurances, setInsurances] = useState<HealthInsurancePlans[]>([])
    const [goBackModal, setGoBackModal] = useState<boolean>(false)

    const caroselStepFromPath: number | undefined =
      location.state && location.state.step

    const [addedSurgicalOrder, setAddSurgicalOrder] =
      useState<SurgicalOrderModel>()

    useEffect(() => {
      ;(async () => {
        try {
          setIsLoading(true)
          setHospitals(
            (await loadHospitals?.load(['hospital_id', 'name', 'nickname'])) ??
              []
          )
        } catch (error: any) {
          toast.error(error.message)
        } finally {
          setIsLoading(false)
        }
      })()
      if (location.state) {
        const { patient } = location.state
        formik.setFieldValue('patient', patient)
        formik.setFieldValue('healthInsurance', patient.healthInsurance)
      }
    }, [])

    const formik = useFormik<CreateSurgicalOrderFormValues>({
      initialValues: {
        healthCardFiles: []
      } as unknown as CreateSurgicalOrderFormValues,
      onSubmit: async () => null,
      validateOnMount: true,
      enableReinitialize: true,
      validationSchema
    })

    const history = useHistory()

    const loadSurgeryCentersByHospital = async (hospital_id: number) => {
      setSurgeryCenters(
        (await loadSurgeryCenter?.load({
          hospital_id,
          fields: ['codeCenter', 'description']
        })) ?? []
      )
    }

    const loadHealthInsurances = async (hospital_id: number) => {
      setIsLoading(true)
      try {
        const insurances = await loadInsurances.load({
          hospital_id: hospital_id,
          params: [
            'code',
            'description',
            'ansRegister',
            'plans {code, description, subPlan{code, description}}'
          ]
        })

        setInsurances(insurances)
      } catch (e: any) {
        toast.error(e.message)
      } finally {
        setIsLoading(false)
      }
    }

    const addSurgicalOrder = async () => {
      try {
        setIsLoading(true)
        const surgicalOrder = await addDoctorSurgicalOrder?.add({
          patient_id: formik.values.patient.patient_id,
          patientName: formik.values.patient.name,
          hospital_id: formik.values.hospital.hospital_id,
          surgical_center_id: formik.values.surgeryCenter.codeCenter,
          surgicalCenter: formik.values.surgeryCenter.description,
          healthInsurance: {
            healthInsuranceCode:
              formik.values.healthInsurance.healthInsuranceCode,
            healthInsuranceName:
              formik.values.healthInsurance.healthInsuranceName,
            ansRegister: formik.values.healthInsurance.ansRegister,
            healthCard: formik.values.healthInsurance.healthCard
          }
        })
        if (
          formik.values.healthCardFiles.length > 0 &&
          surgicalOrder?.surgical_order_id
        ) {
          await Promise.all(
            formik.values.healthCardFiles.map(async ({ file }) => {
              const type = PatientDocument.HEALTH_CARD
              const renamedFile = renameFile(file, type, {
                forceOriginalExtension: true
              })
              const form = new FormData()
              form.append(
                'surgical_order_id',
                `${surgicalOrder.surgical_order_id}`
              )
              form.append('type', type)
              form.append('file', renamedFile)
              await uploadSurgicalOrderDocument.upload({
                file,
                type,
                form
              })
            })
          )
        }
        setAddSurgicalOrder(surgicalOrder)
        setSuccessModal(true)
      } catch (e: any) {
        toast.error(e.message)
      } finally {
        setIsLoading(false)
      }
    }

    return (
      <FormikProvider value={formik}>
        <>
          {goBackModal && (
            <Modal show={goBackModal}>
              <S.BackModal>
                Deseja realmente cancelar?
                <div>
                  <Button type="button" onClick={() => setGoBackModal(false)}>
                    Não
                  </Button>
                  <Button type="button" onClick={() => history.goBack()}>
                    Sim
                  </Button>
                </div>
              </S.BackModal>
            </Modal>
          )}
          <Carousel
            changeSlideTo={caroselStepFromPath}
            state={carousel}
            setState={setCarousel}
            touch={false}
            containerStyle={{ height: '100%' }}
          >
            <SelectHospital
              submit={carousel.slideNext}
              hospitals={hospitals}
              loadSurgeryCenter={loadSurgeryCentersByHospital}
              surgeryCenters={surgeryCenters}
            />
            <SelectPatient
              goBack={carousel.slidePrev}
              findPatient={findPatient}
              submit={carousel.slideNext}
            />
            <ReviewPatient
              submit={addSurgicalOrder}
              insurances={insurances}
              goBack={carousel.slidePrev}
              setGoBackModal={() => setGoBackModal(true)}
              loadHealthInsurances={loadHealthInsurances}
            />
          </Carousel>
          <Modal
            title="Dados enviados com sucesso!"
            show={successModal}
            close={() =>
              history.push('/pedido-cirurgico', {
                surgicalOrder: addedSurgicalOrder
              })
            }
          />
        </>
      </FormikProvider>
    )
  }
)

export type CreateSurgicalOrderFormValues = {
  patient: Patient
  hospital: Hospital
  surgeryCenter: SurgeryCenter
  healthInsurance: HealthInsurance
  healthCardFiles: ListFile[]
}

const validationSchema = yup.object().shape({
  patient: yup.object().required(),
  hospital: yup.object().required(),
  surgeryCenter: yup.mixed().required()
})

import styled from 'styled-components'

export const Wrapper = styled.div``

export const PatientInfoContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px;
  border-radius: 10px;
`

export const Patient = styled.div`
  display: flex;
  align-items: center;

  & > div {
    margin-left: 8px;
    margin-bottom: 8px;
  }
`

export const PatientInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  margin-top: 8px;

  strong {
    font-weight: ${({ theme }) => theme.font.bold};
    color: ${({ theme }) => theme.colors.primary};
  }

  p {
    font-weight: ${({ theme }) => theme.font.normal};
    color: ${({ theme }) => theme.colors.primary};
  }

  label {
    font-weight: ${({ theme }) => theme.font.normal};
    color: ${({ theme }) => theme.colors.gray};
  }

  & + & {
    margin-top: 4px;
  }
`

export const SurgicalOrders = styled.section`
  cursor: pointer;
`

export const SurgicalOrder = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px;
  border-radius: 10px;
`

export const SurgicalStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SurgicalNumber = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
`

export const Status = styled.div`
  font-weight: 500;
  border: ${({ theme }) => '1px solid' + theme.colors.brightOrange};
  border-radius: 60px;
  color: ${({ theme }) => theme.colors.brightOrange};
  padding: 4px 8px;
  font-size: ${({ theme }) => theme.font.sizes.xxsmall};
`

export const SurgicalInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  margin-top: 8px;

  strong {
    font-weight: ${({ theme }) => theme.font.bold};
    color: ${({ theme }) => theme.colors.primary};
  }

  p {
    font-weight: ${({ theme }) => theme.font.normal};
    color: ${({ theme }) => theme.colors.primary};
  }

  label {
    font-weight: ${({ theme }) => theme.font.normal};
    color: ${({ theme }) => theme.colors.gray};
  }

  & + & {
    margin-top: 4px;
  }
`

export const NoSurgicalOrders = styled.div`
  text-align: center;
  margin-top: 40px;
`

import styled from 'styled-components'

export const Input = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`
export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  width: 51px;
  // it might broke something
  // margin-top: 65px;
  border-radius: 0px 10px 10px 0px;
  background: ${({ theme }) => theme.colors.primary};
  svg {
    path {
      fill: #fff;
      opacity: 1;
    }
  }
`
export const EmptyList = styled.p`
  align-self: center;
  margin-top: 20px;
`

import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import LoadingGif from 'presentation/assets/icons/loading.gif'
import { LoadSurgicalOrdersByDoctor } from 'domain/usecases/doctor/load-surgical-order'
import ScheduleCard from 'presentation/doctor/components/ScheduleCard'
import { Container } from 'presentation/shared/components/Container'
import Header from 'presentation/shared/components/Header'
import { InfoList } from 'presentation/shared/components/SurgeryInfo/SurgeryInfoTabs/styles'
import theme from 'presentation/styles/theme'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import getTittleBySearchType from './Constants/get-title'
import Button from 'presentation/shared/components/Button'
import {
  LoadingMore,
  LoadMoreButton
} from 'presentation/doctor/components/SurgicalPendencyList/styles'
import * as S from './styles'

type Props = {
  order_status: LoadSurgicalOrdersByDoctor.Params['order_status']
  loadDoctorSurgicalOrders: LoadSurgicalOrdersByDoctor
  unfinishedOrders?: boolean
}
type filterTypes = 'active' | 'cancelled'
type pageInfo = {
  totalPages?: number
  actualPage?: number
}
type setAllInfoProps = {
  data: {
    setData: (data: SurgicalOrderModel[]) => void
    newData: SurgicalOrderModel[]
  }
  pageInfo: {
    setPageInfo: (pageInfo: pageInfo) => void
    newPageInfo: pageInfo
  }
}
const PAGE_SIZE = 5
export default function SurgeryOrderList({
  order_status,
  unfinishedOrders,
  loadDoctorSurgicalOrders
}: Props) {
  const history = useHistory()
  const [activeData, setActiveData] = useState<SurgicalOrderModel[]>([])
  const [loadingMore, setLoadingMore] = useState<boolean>(false)
  const [activePageInfo, setActivePageInfo] = useState<pageInfo>()
  const [cancelledData, setCancelledData] = useState<SurgicalOrderModel[]>([])
  const [cancelledPageInfo, setCancelledPageInfo] = useState<pageInfo>()

  const [type, setType] = useState<filterTypes>('active')

  const getData = (data: SurgicalOrderModel[], accumulate: boolean) => {
    const realData = type === 'active' ? activeData : cancelledData
    return accumulate ? [...realData, ...data] : data
  }

  const setAllInfo = ({ data, pageInfo }: setAllInfoProps) => {
    const { newData, setData } = data
    const { newPageInfo, setPageInfo } = pageInfo
    setData(newData)
    setPageInfo(newPageInfo)
  }

  const loadData = async (page: number, accumulate?: boolean) => {
    try {
      setLoadingMore(true)
      const response = await loadDoctorSurgicalOrders.loadOrders({
        order_status,
        fields: [
          'data {',
          'surgical_order_id',
          'patient {name}',
          'expectedDate',
          'surgeryDate',
          'procedure { description }',
          ' hospital { name, hospital_id }',
          'createdBy {role}',
          'status { status isActive }',
          'isSolicitation',
          'isComplete',
          'createdAt',
          '}',
          'pageInfo {',
          '  currentPage',
          '  itemsPerPage',
          '  totalItems',
          '  totalPages',
          '}'
        ],
        pagination: {
          pageNumber: page,
          pageSize: PAGE_SIZE
        },
        cancelled: type === 'cancelled',
        unfinished: unfinishedOrders === false ? undefined : unfinishedOrders
      })
      const data = getData(response?.data || [], !!accumulate)
      const pageInfo = response?.pageInfo
      const setInfo: setAllInfoProps = {
        data: {
          newData: data,
          setData: type === 'active' ? setActiveData : setCancelledData
        },
        pageInfo: {
          newPageInfo: {
            actualPage: pageInfo.currentPage,
            totalPages: pageInfo.totalPages
          },
          setPageInfo:
            type === 'active' ? setActivePageInfo : setCancelledPageInfo
        }
      }
      setAllInfo(setInfo)
    } catch (error: any) {
      toast.error(error?.message)
    } finally {
      setLoadingMore(false)
    }
  }

  useEffect(() => {
    loadData(1)
  }, [])

  const getClassName = (status: string): string => {
    return status === type ? 'selected' : ''
  }
  const onClickNav = (status: filterTypes) => {
    setType(status)
  }
  useEffect(() => {
    if (cancelledData?.length === 0 || activeData?.length === 0) {
      loadData(1)
    }
  }, [type])

  const getBottomButton = () => {
    if (loadingMore) {
      return (
        <LoadingMore>
          <img src={LoadingGif} />
        </LoadingMore>
      )
    }
    const pageInfo = type === 'active' ? activePageInfo : cancelledPageInfo
    if (pageInfo) {
      const { totalPages = 0, actualPage = 0 } = pageInfo
      if (totalPages > actualPage) {
        return (
          <Button
            fullWidth
            type="button"
            variant="outlined"
            onClick={() => loadData(actualPage + 1, true)}
          >
            Ver mais resultados
          </Button>
        )
      }
    }
    return <></>
  }

  const data = type === 'active' ? activeData : cancelledData

  return (
    <>
      <Header />
      <Container
        hasHeader
        actualPageText="Voltar"
        actualPageOnClick={() => history.goBack()}
        title={getTittleBySearchType(order_status, unfinishedOrders)}
        subtitle={
          unfinishedOrders
            ? 'Complete o cadastro dos seus pedidos para envia-los ao hospital'
            : undefined
        }
        subtitleColor="darkGray"
      >
        {/* <InfoList style={StyledInfoList}>
            <a
              className={getClassName('active')}
              onClick={() => onClickNav('active')}
            >
              Ativos
            </a>
            <a
              className={getClassName('cancelled')}
              onClick={() => onClickNav('cancelled')}
            >
              Cancelados
            </a>
          </InfoList> */}
        {data.length ? (
          data.map((order) =>
            unfinishedOrders
              ? order.isSolicitation && (
                  <ScheduleCard
                    patientName={order.patient?.name}
                    surgicalOrder={order}
                    key={order.surgical_order_id}
                    onDelete={() => loadData(1)}
                  />
                )
              : order.isComplete && (
                  <ScheduleCard
                    patientName={order.patient?.name}
                    surgicalOrder={order}
                    key={order.surgical_order_id}
                    onDelete={() => loadData(1)}
                  />
                )
          )
        ) : (
          <>
            {!loadingMore && (
              <S.NoSurgicalOrder>
                Não há pedidos não finalizados no momento
              </S.NoSurgicalOrder>
            )}
          </>
        )}
        <LoadMoreButton>{getBottomButton()}</LoadMoreButton>
      </Container>
    </>
  )
}

const StyledInfoList = {
  background: theme.colors.mainBg,
  marginTop: '0px'
}

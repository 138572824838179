import React from 'react'
import AddSecretaryForm from 'presentation/doctor/components/Forms/AddProfessional/AddSecretary'
import Header from 'presentation/shared/components/Header'
import { Container } from 'presentation/shared/components/Container'
import { AddSecretary } from 'domain/usecases/secretary/add-secretary'

type AddSecretaryLayoutProps = {
  addSecretary: AddSecretary
}

export default function AddSecretaryLayout({
  addSecretary
}: AddSecretaryLayoutProps) {
  return (
    <>
      <Header />
      <Container hasHeader noPadding>
        <AddSecretaryForm addSecretary={addSecretary} />
      </Container>
    </>
  )
}

import React from 'react'
import { useHistory } from 'react-router'
import { useFormik } from 'formik'
import * as S from './styles'
import * as yup from 'yup'
import 'main/config/yup'

import ActualPage from 'presentation/shared/components/ActualPage'
import Button from 'presentation/shared/components/Button'
import Heading from 'presentation/shared/components/Heading'
import Radio from 'presentation/shared/components/RadioButton'
import SupportText from 'presentation/shared/components/SupportText'

export default function AddProfessionalForm() {
  const history = useHistory()

  const formik = useFormik({
    initialValues: {} as AddProfessionalFormValues,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      switch (values.type) {
        case 'secretary':
          return history.push('/vincular-profissional/secretaria')
      }
    }
  })

  return (
    <S.Wrapper role="form" onSubmit={formik.handleSubmit}>
      <ActualPage
        onClick={() => history.goBack()}
        text="Vincular novo profissional"
      />
      <Heading size="large" as="h1">
        Adicionar Secretária
      </Heading>
      <SupportText color="lightGray">
        Preencha aqui os dados da secretária.
      </SupportText>
      <S.Content>
        <Radio
          name="type"
          labelFor="secretary"
          value="secretary"
          label="Secretária"
          checked={formik.values.type === 'secretary'}
          onCheck={() => formik.setFieldValue('type', 'secretary')}
        />
      </S.Content>
      <S.Buttons>
        <Button
          disabled={!formik.isValid || formik.isSubmitting}
          fullWidth
          type="submit"
        >
          Enviar
        </Button>
      </S.Buttons>
    </S.Wrapper>
  )
}

type AddProfessionalFormValues = {
  type: string
}

const validationSchema = yup.object().shape({
  type: yup.string().oneOf(['secretary'], 'Selecione um valor').required()
})

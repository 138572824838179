import React from 'react'

import { useFormik } from 'formik'

import { UpdateSurgicalOrder } from 'domain/usecases/surgical-order/update-surgical-order'
import Button from 'presentation/shared/components/Button'
import { Container } from 'presentation/shared/components/Container'
import { Label } from 'presentation/shared/components/TextField/styles'
import { useHistory } from 'react-router-dom'
import * as FormS from '../styles'
import * as S from './styles'
import { ReactComponent as DeleteIcon } from 'presentation/assets/icons/trash-outline-purple.svg'

import Header from 'presentation/shared/components/Header'
import TextField from 'presentation/shared/components/TextField'

import * as yup from 'yup'
import Table from 'presentation/shared/components/Table'
import { toast } from 'react-toastify'

type Props = {
  updateSurgicalOrder: UpdateSurgicalOrder
  surgicalOrderId: number
  cmes?: Cme[]
}

const initialFormValues = {
  description: '',
  quantity: 1
}

export default function AddCme({
  cmes,
  updateSurgicalOrder,
  surgicalOrderId
}: Props) {
  const history = useHistory()

  const { values, handleChange, isValid, setValues } =
    useFormik<AddCmeFormValues>({
      initialValues: {
        form: initialFormValues,
        cmes: cmes || []
      },
      validationSchema,
      validateOnMount: true,
      enableReinitialize: true,
      onSubmit: async () => {
        return null
      }
    })

  const onSubmit = async () => {
    try {
      await updateSurgicalOrder.update({
        surgical_order_id: surgicalOrderId,
        cme: values.cmes || []
      })
      return history.goBack()
    } catch (e: any) {
      toast.error(e.message)
    }
  }

  const formattedData = values.cmes.map((cme, index) => {
    return {
      value: cme,
      display: (
        <S.CmeCell id="cme-cell">
          <span>{cme.description}</span>
          <div>
            <p>{cme.quantity}</p>
            <FormS.DeleteButton
              onClick={(e) => {
                e.stopPropagation()
                const newCmes = [...values.cmes]
                newCmes.splice(index, 1)
                setValues({
                  ...values,
                  cmes: newCmes
                })
              }}
              data-testid="delete-cme-button"
            >
              <DeleteIcon height={20} />
            </FormS.DeleteButton>
          </div>
        </S.CmeCell>
      )
    }
  })

  return (
    <>
      <Header />
      <Container
        title="CME"
        primaryButton={
          <Button type="button" fullWidth onClick={onSubmit}>
            Próximo
          </Button>
        }
        secondaryButton={
          <Button variant="outlined" fullWidth onClick={history.goBack}>
            Cancelar
          </Button>
        }
      >
        <FormS.LabelContainer>
          <Label>
            Descrição da CME <span>*</span>
          </Label>
        </FormS.LabelContainer>
        <TextField
          onChange={handleChange('form.description')}
          value={values.form.description}
          name="form.description"
          id="form.description"
        />

        <FormS.LabelInputContainer>
          <FormS.LabelContainer>
            <Label>
              Quantidade <span>*</span>
            </Label>
          </FormS.LabelContainer>
          <TextField
            style={{ maxWidth: '25%' }}
            onChange={handleChange('form.quantity')}
            value={values.form.quantity}
            name="form.quantity"
            id="form.quantity"
            type="number"
            min={1}
            max={99}
          />
        </FormS.LabelInputContainer>

        <Button
          type="button"
          fullWidth
          variant="white"
          onClick={() => {
            setValues({
              form: initialFormValues,
              cmes: [...values.cmes, values.form]
            })
          }}
          style={{ marginTop: '2rem', marginBottom: '1rem' }}
          disabled={!isValid}
        >
          + Adicionar CME
        </Button>
        <FormS.TableContainer>
          <Table
            title="Cme"
            pagination={false}
            columns={columns}
            data={formattedData}
          />
        </FormS.TableContainer>
      </Container>
    </>
  )
}

const columns = [
  {
    name: 'value',
    label: ' ',
    options: {
      display: false
    }
  },
  {
    name: 'display',
    label: 'Descrição',
    options: {
      filter: false,
      sort: false
    }
  }
]

type Cme = {
  quantity: number
  description: string
}

type AddCmeFormValues = {
  form: Cme
  cmes: Cme[]
}

const validationSchema = yup.object().shape({
  form: yup.object().shape({
    description: yup.string().required(),
    quantity: yup.number().required()
  })
})

export const cmeValidationSchema = yup.object().shape({
  cme: yup.array().required()
})

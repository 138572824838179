import { ScheduleType } from 'common/enum/schedule-type'
import styled from 'styled-components'

export const Wrapper = styled.div<{ type: ScheduleType }>`
  background-color: white;
  padding: 10px 32px;
  display: flex;
  flex-direction: column;
  height: 130px;
  border-radius: 8px;
  position: relative;
  font-size: 12px;
  cursor: pointer;
  margin-left: 48px;

  h2 {
    margin-top: auto;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 4px;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
    overflow: hidden;
  }

  p {
    line-height: 12.5px;
    margin-bottom: 4px;
  }

  & > div {
    align-items: flex-start;

    svg {
      margin-right: 4px;
      min-width: 10px;
      min-height: 12px;
      width: 10px;
      height: 12px;
    }
  }

  & + div {
    margin-top: 12px;
  }

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    border-radius: 8px 0 0 8px;
    width: 20px;
    left: 0;
    top: 0;
    background-color: ${({ type }) => getTypeColor[type]};
  }

  svg path {
    fill: #888888 !important;
  }
`

export const StatusWrapper = styled.p`
  display: inline-block;
  font-size: 12px;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 60px;
  width: fit-content;
  min-width: 132px;
  padding: 2px 8px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 2px;
`

const getTypeColor: Record<ScheduleType, string> = {
  [ScheduleType.SURGICAL]: '#00CFAA',
  [ScheduleType.AMBULATORY]: '#FA7676'
}

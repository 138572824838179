import styled from 'styled-components'

export const AddEquipmentsContainer = styled.div`
  margin-top: 16px;
  gap: 24px;
  display: flex;
  flex-direction: column;
`

export const InputContainer = styled.div`
  gap: 15px;
  display: flex;
  flex-direction: column;
`

import React, { useState } from 'react'
import * as S from './styles'
import { ReactComponent as LocationIcon } from 'presentation/assets/icons/location.svg'
import { ReactComponent as ScheduleIcon } from 'presentation/assets/icons/calendar.svg'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { SurgicalOrderStatus } from 'presentation/shared/components/Timeline/status'
import moment from 'moment-timezone'
import { Hospital } from 'domain/entities/hospital-model'
import normalizeText from 'common/utils/getNormalizedText'
import { useHistory } from 'react-router-dom'
import theme from 'presentation/styles/theme'
import Modal from 'presentation/shared/components/Modal'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import Heading from 'presentation/shared/components/Heading'
import SupportText from 'presentation/shared/components/SupportText'
import Button from 'presentation/shared/components/Button'

type Props = {
  patientName?: string
  surgicalOrder?: SurgicalOrderModel
  onDelete?: () => void
}

const ScheduleCard = ({ patientName, surgicalOrder, onDelete }: Props) => {
  const [deleteMode, setDeleteMode] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const history = useHistory()
  const surgicalOrderService = useServices().surgicalOrder
  const actualStatus = surgicalOrder?.status?.filter(
    (status) => status.isActive
  )[0]?.status

  let surgicalJourneyColor

  switch (actualStatus) {
    case SurgicalOrderStatus.ORDER_REGISTERED:
      surgicalJourneyColor = theme.colors.blue
      break
    case SurgicalOrderStatus.REVIEW:
      surgicalJourneyColor = theme.colors.orange
      break
    default:
      surgicalJourneyColor = theme.colors.primary
  }

  const statusColor =
    actualStatus == SurgicalOrderStatus.CANCELLED
      ? theme.colors.lightRed
      : actualStatus == SurgicalOrderStatus.AUTHORIZED
      ? theme.colors.secondary
      : surgicalJourneyColor

  const statusLabel =
    surgicalOrder?.isSolicitation && !surgicalOrder?.isComplete
      ? 'Pedido não finalizado'
      : actualStatus || ''

  const date = surgicalOrder?.surgeryDate
    ? surgicalOrder?.surgeryDate
    : surgicalOrder?.expectedDate?.[0]

  const formattedDate =
    date &&
    moment
      .utc(date)
      .format(surgicalOrder?.surgeryDate ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY')

  const formatCreateDate =
    surgicalOrder?.createdAt &&
    moment(surgicalOrder?.createdAt).utc(true).format('DD/MM/YYYY HH:mm')

  const deleteSurgicalOrder = async () => {
    try {
      await surgicalOrderService.deleteSurgicalOrderSolicitation({
        surgical_order_id: surgicalOrder?.surgical_order_id as number
      })
      setIsDeleted(true)
      if (onDelete) {
        onDelete()
      }
      toast.success('Pedido excluido com sucesso')
    } catch (e) {
      toast.error('Ocorreu um erro ao tentar excluir esse pedido cirúrgico')
    }
  }

  if (isDeleted) {
    return <></>
  }

  return (
    <S.Wrapper
      data-testid="scheduleCard-Wrapper"
      onClick={() => {
        history.push(
          surgicalOrder?.isComplete ? '/cirurgia' : '/pedido-cirurgico',
          {
            [surgicalOrder?.isComplete ? 'id' : 'surgicalOrder']:
              surgicalOrder?.isComplete
                ? surgicalOrder?.surgical_order_id
                : surgicalOrder
          }
        )
      }}
      statusColor={surgicalJourneyColor || ''}
    >
      {surgicalOrder?.isSolicitation && !surgicalOrder?.isComplete && (
        <S.DeleteIcon
          data-testid="scheduleCard-deleteIcon"
          onClick={(e) => {
            e.stopPropagation()
            setDeleteMode(true)
          }}
        />
      )}
      <S.Header>
        <h3 data-testid="scheduleCard-procedureDescription">
          {surgicalOrder?.procedure?.[0].description}
        </h3>
        <p data-testid="scheduleCard-patientName">Paciente: {patientName}</p>
        <p data-testid="scheduleCard-SurgicalOrderId">
          Número do aviso:{' '}
          {!surgicalOrder?.isSolicitation
            ? surgicalOrder?.surgical_order_id
            : ''}
        </p>
        {surgicalOrder?.createdAt ? (
          <p data-testid="scheduleCard-CreateDate">
            Data de criação: {formatCreateDate}
          </p>
        ) : null}
        {/*<p>Criado por: {translateRole(surgicalOrder?.createdBy?.role)}</p>*/}
        <div />
      </S.Header>
      <S.ScheduleInfo>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div />
          <div>
            <ScheduleIcon />
            <span>{formattedDate}</span>
          </div>
        </div>
        <S.Footer>
          <div>
            <LocationIcon />
            <span style={{ textAlign: 'left' }}>
              {normalizeText(
                new Hospital(
                  surgicalOrder?.hospital?.hospital_id ?? 0,
                  surgicalOrder?.hospital?.name ?? '',
                  surgicalOrder?.hospital?.name ?? ''
                ).name
              ).replace('Hospital', '')}
            </span>
          </div>
          <S.Status statusColor={statusColor || ''}>
            <p>{statusLabel}</p>
          </S.Status>
        </S.Footer>
      </S.ScheduleInfo>
      <Modal show={deleteMode}>
        <S.CloseModalWrapper data-testid="scheduleCard-modal">
          <Heading as="h1">Excluir pedido não finalizado</Heading>
          <SupportText>Deseja excluir permanentemente esse pedido?</SupportText>
          <div>
            <Button
              variant="outlined"
              type="button"
              onClick={() => setDeleteMode(false)}
              data-testid="scheduleCard-modal-cancel"
            >
              Cancelar
            </Button>
            <Button
              type="button"
              onClick={deleteSurgicalOrder}
              data-testid="scheduleCard-modal-confirm"
            >
              Sim
            </Button>
          </div>
        </S.CloseModalWrapper>
      </Modal>
    </S.Wrapper>
  )
}

export default ScheduleCard

import React from 'react'

import { useLocation } from 'react-router-dom'
import { Hospital } from 'domain/entities/hospital-model'
import AddNewBornPatientLayout from 'presentation/doctor/layouts/AddNewBornPatient'

export default function AddNewBornPatient() {
  const { state } = useLocation<{ hospital: Hospital }>()
  return <AddNewBornPatientLayout hospital={state.hospital} />
}

import styled from 'styled-components'

export const ReviewEmployeeContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius.small};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  font-size: ${({ theme }) => theme.font.sizes.small};
  color: ${({ theme }) => theme.colors.gray};

  label {
    color: ${({ theme }) => theme.colors.lightGray};
  }

  strong {
    color: ${({ theme }) => theme.colors.mediumLightGray};
    font-weight: ${({ theme }) => theme.font.bold};
  }

  text-overflow: ellipsis;
  overflow: hidden;

  div {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  margin-bottom: 15px;
`

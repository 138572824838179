import React from 'react'

import { useLocation } from 'react-router-dom'

import { UpdateSurgicalOrder } from 'domain/usecases/surgical-order/update-surgical-order'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import AddCme from 'presentation/doctor/components/Forms/AddSurgicalOrder/AddCme'

type Props = {
  updateSurgicalOrder: UpdateSurgicalOrder
}

type Location = {
  surgical_order_id: number
  cmes: SurgicalOrderModel['cme']
}

export default function CreateCme({ updateSurgicalOrder }: Props) {
  const { state } = useLocation<Location>()

  const { surgical_order_id, cmes } = state || ({} as unknown as Location)
  return (
    <AddCme
      surgicalOrderId={surgical_order_id}
      updateSurgicalOrder={updateSurgicalOrder}
      cmes={cmes}
    />
  )
}

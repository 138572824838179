import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  overflow-x: auto;
`

export const InputsContainer = styled.div`
  margin-top: 12px;
`

export const InputContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto 70px;
  gap: 18px;

  & + & {
    margin-top: 25px;
  }

  & > div {
    margin: 0 !important;
    width: 100%;
  }

  button {
    position: absolute;
    left: calc(100% - 50px);
    bottom: 0;
    border-radius: 0px 4px 4px 0px;

    path {
      fill: white;
      opacity: 1;
    }
  }
`

export const ChipsContainer = styled.div`
  display: flex;
  margin-top: 12px;
  margin-bottom: 20px;

  & > div + div {
    margin-left: 20px;
  }
`

import React from 'react'

import { Container } from 'presentation/shared/components/Container'
import Header from 'presentation/shared/components/Header'
import { Hospital } from 'domain/entities/hospital-model'
import InviteNewBornPatient from 'presentation/doctor/components/Forms/InviteNewBornPatient'

type Props = {
  hospital: Hospital
}

export default function AddNewBornPatientLayout({ hospital }: Props) {
  return (
    <>
      <Header />
      <Container>
        <InviteNewBornPatient hospital={hospital} />
      </Container>
    </>
  )
}

import styled from 'styled-components'

export const DateSupportText = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  width: fit-content;
  font-style: italic;
  margin-bottom: 2rem;
  margin-top: 1rem;
`

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
`

import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import moment from 'moment-timezone'
import { useServices } from 'presentation/hooks/use-services'
import Button from 'presentation/shared/components/Button'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as S from '../styles'

type SurgicalScheduleDetailsProps = {
  data: SurgicalOrderModel
}

export const SurgicalScheduleDetails = ({
  data
}: SurgicalScheduleDetailsProps) => {
  const [surgicalOrderInfo, setSurgicalOrderInfo] =
    useState<SurgicalOrderModel>()
  const surgicalOrderService = useServices().surgicalOrder
  const history = useHistory()

  useEffect(() => {
    surgicalOrderService
      .loadSurgicalOrder(data?.surgical_order_id as number, [
        'surgical_order_id',
        'medicalDischargeDate'
      ])
      .then((res) => setSurgicalOrderInfo(res))
  }, [data])

  const getPrimaryDoctor = () => {
    return 'Not implemented'
  }

  const getAnesthetist = () => {
    return 'Not implemented'
  }

  return (
    <>
      <S.ContainerData>
        <strong>PACIENTE</strong>
        <label>{data?.patientName}</label>

        <strong>CONVÊNIO</strong>
        <label>{data?.scheduleHealthInsurance}</label>

        <strong>RECURSO</strong>
        <label>{data?.surgicalCenter}</label>

        <strong>SETOR</strong>
        <label>{data?.sector}</label>

        <strong>AVISO</strong>
        <label>{data?.surgical_order_id}</label>

        <strong>CIRURGIÃO PRINCIPAL</strong>
        <label>{getPrimaryDoctor()}</label>

        <strong>ANESTESISTA</strong>
        <label>{getAnesthetist()}</label>

        <strong>PREVISTA DE ALTA</strong>
        <label>
          {surgicalOrderInfo?.medicalDischargeDate
            ? moment(surgicalOrderInfo?.medicalDischargeDate)
                .utc(true)
                .format('DD/MM/YYYY')
            : '-'}
        </label>

        <strong>DATA DE CRIAÇÃO</strong>
        <label>
          {data?.createdAt &&
            moment(data.createdAt).utc(true).format('DD/MM/YYYY - HH[h]')}
        </label>
      </S.ContainerData>
      <Button
        fullWidth
        style={{ marginTop: '20px' }}
        onClick={() => {
          if (data?.surgical_order_id)
            history.push('/cirurgia', {
              id: data?.surgical_order_id
            })
        }}
        variant="outlined"
      >
        Ver mais informações
      </Button>
    </>
  )
}

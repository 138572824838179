import React from 'react'

import { FormikProvider, useFormik } from 'formik'
import { toast } from 'react-toastify'

import { UpdateSurgicalOrder } from 'domain/usecases/surgical-order/update-surgical-order'
import { Container } from 'presentation/shared/components/Container'
import { useHistory } from 'react-router-dom'

import Header from 'presentation/shared/components/Header'
import ListMedicalTeam from './ListMedicalTeam'
import { UpsertMedicalTeam } from './UpsertMedicalTeam'
import { ReviewEmployee } from './ReviewEmployee'
import ListAnesthetistTeam from './ListAnesthetistTeam'

import * as yup from 'yup'
import { UpsertAnesthetist } from './UpsertAnesthetist'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { SurgicalMedicalTeamModel } from 'domain/entities/surgical-medical-team-model'
import { SurgicalAnesthetistTeamModel } from 'domain/entities/surgical-anesthetist-team-model'

type Props = {
  updateSurgicalOrder: UpdateSurgicalOrder
  surgicalOrderId: number
  initialValues?: SurgicalOrderModel
}

export const adaptSurgicalOrderToFormValues = (
  surgicalOrder: SurgicalOrderModel
): AddMedicalTeamFormValues['surgicalValues'] => {
  return {
    ...surgicalOrder,
    isAnesthetistExternal: !!surgicalOrder.anesthetistTeam,
    isCoupleSurgery: !!surgicalOrder.medicalTeamName
  }
}

export const MedicalTeamContext = React.createContext<{
  removeItem: (
    arrayType: 'medicalTeam' | 'anesthetistTeam',
    index: number
  ) => void
}>({
  removeItem: () => {
    return null
  }
})

export default function AddMedicalTeam({
  updateSurgicalOrder,
  surgicalOrderId,
  initialValues = {} as AddMedicalTeamFormValues['surgicalValues']
}: Props) {
  const history = useHistory()

  const formik = useFormik<AddMedicalTeamFormValues>({
    initialValues: {
      step: 'list-medical-team',
      surgicalValues: adaptSurgicalOrderToFormValues(initialValues)
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: async (formValues) => {
      try {
        const { surgicalValues } = formValues
        const {
          isCoupleSurgery,
          medicalTeamName,
          medicalTeam,
          anesthetistTeam,
          isAnesthetistExternal
        } = surgicalValues
        const adaptBody: UpdateSurgicalOrder.Params = {
          surgical_order_id: surgicalOrderId,
          medicalTeamName: isCoupleSurgery ? medicalTeamName : null,
          medicalTeam: medicalTeam || [],
          anesthetistTeam: isAnesthetistExternal ? anesthetistTeam : null
        }
        await updateSurgicalOrder.update(adaptBody)
        return history.goBack()
      } catch (e: any) {
        toast.error(e.message)
      }
    }
  })

  const content: ContentType = {
    'list-medical-team': {
      title: 'Equipe médica',
      component: <ListMedicalTeam />
    },
    'upsert-medical-team': {
      title:
        formik.values.editingMedicalTeamIndex !== undefined
          ? 'Editar profissional'
          : 'Adicionar profissional',
      component: <UpsertMedicalTeam />,
      goBackFunction: () => {
        const newStep =
          formik.values.editingMedicalTeamIndex !== undefined
            ? 'review-employee'
            : 'list-medical-team'
        formik.setValues({
          ...formik.values,
          step: newStep
        })
      }
    },
    'review-employee': {
      component: <ReviewEmployee />,
      goBackFunction: () => {
        const newValue = formik.values
        if (formik.values.editingMedicalTeamIndex !== undefined) {
          newValue.editingMedicalTeamIndex = undefined
          newValue.step = 'list-medical-team'
        }
        if (formik.values.editingAnesthetistTeamIndex !== undefined) {
          newValue.editingAnesthetistTeamIndex = undefined
          newValue.step = 'list-anesthetist-team'
        }
        formik.setValues(newValue)
      }
    },
    'list-anesthetist-team': {
      title: 'Anestesia',
      component: <ListAnesthetistTeam />
    },
    'upsert-anesthetist-team': {
      title:
        formik.values.editingAnesthetistTeamIndex !== undefined
          ? 'Editar profissional'
          : 'Adicionar profissional',
      component: <UpsertAnesthetist />,
      goBackFunction: () => {
        const newStep =
          formik.values.editingAnesthetistTeamIndex !== undefined
            ? 'review-employee'
            : 'list-anesthetist-team'
        formik.setValues({
          ...formik.values,
          step: newStep
        })
      }
    }
  } as ContentType

  const actualContent = content[formik.values.step]

  const removeItem = (
    arrayType: 'medicalTeam' | 'anesthetistTeam',
    index: number
  ) => {
    const filteredArray = [...formik.values.surgicalValues?.[arrayType]]
    filteredArray.splice(index, 1)
    formik.setFieldValue(`surgicalValues.${arrayType}`, filteredArray)
  }

  return (
    <>
      <Header />
      <FormikProvider value={formik}>
        <MedicalTeamContext.Provider value={{ removeItem }}>
          <Container
            title={actualContent?.title || ' '}
            actualPageText={
              actualContent?.goBackFunction ? 'Voltar' : undefined
            }
            actualPageOnClick={actualContent?.goBackFunction}
          >
            {actualContent?.component}
          </Container>
        </MedicalTeamContext.Provider>
      </FormikProvider>
    </>
  )
}

type MedicalTeamSteps =
  | 'list-medical-team'
  | 'upsert-medical-team'
  | 'review-employee'
  | 'list-anesthetist-team'
  | 'upsert-anesthetist-team'

type ContentType = Record<
  MedicalTeamSteps,
  {
    title?: string
    component: JSX.Element
    goBackFunction?: () => void
  }
>

export type AddMedicalTeamFormValues = {
  surgicalValues: {
    isCoupleSurgery?: boolean
    medicalTeamName?: string
    medicalTeam?: SurgicalMedicalTeamModel[]
    anesthetistTeam?: SurgicalAnesthetistTeamModel[]
    isAnesthetistExternal?: boolean
  }
  step: MedicalTeamSteps
  editingMedicalTeamIndex?: number
  editingAnesthetistTeamIndex?: number
}

const validationSchema = yup.object().shape({
  surgicalValues: yup.object().shape({
    isCoupleSurgery: yup.boolean().required('Campo obrigatório'),
    medicalTeamName: yup
      .string()
      .optional()
      .nullable()
      .when('isCoupleSurgery', {
        is: true,
        then: yup.string().required('Campo obrigatório')
      })
  })
})

export const medicalTeamValidationSchema = yup.object().shape({
  medicalTeam: yup.array().min(0)
})

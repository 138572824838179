import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-top: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  justify-content: flex-start;

  div span {
    text-align: center;
    font-weight: 400;
  }

  & > p {
    cursor: pointer;
    text-align: center;
    padding: 10px 0;
  }
`

export const Header = styled.header`
  h1 {
    font-size: ${({ theme }) => theme.font.sizes.large};
    margin-bottom: 12px;
  }
  p {
    color: ${({ theme }) => theme.colors.lightGray};
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
  }

  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`

export const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: ${({ theme }) => theme.font.sizes.xsmall};

  & > div {
    margin-top: 12px;
  }

  & > div {
    margin-right: 8px;
  }
`

export const LabelInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 1.5rem 0 0;

  gap: 15px;

  div {
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    label {
      margin: 0;
    }
  }
`

export const LabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: ${({ theme }) => theme.font.sizes.small};
  color: ${({ theme }) => theme.colors.gray};

  label {
    color: ${({ theme }) => theme.colors.gray};
    margin-bottom: 12px;
  }

  margin-top: 24px;
  margin-right: 1rem;

  & > div {
    margin-top: 12px;
  }

  & > div {
    margin-right: 8px;
  }

  span {
    color: ${({ theme }) => theme.colors.red};
  }
`

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 0;

  svg {
    path {
      fill: ${({ theme }) => theme.colors.lightGray};
    }
  }
`

export const TableContainer = styled.div`
  .MuiTableHead-root,
  .MuiTableCell-head {
    z-index: 1;
    height: 32px !important;
    padding: 0 0 0 14px !important;
    text-align: left !important;
  }
`

import React, { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import SurgicalOrderDocumentsLayout from 'presentation/doctor/layouts/SurgicalOrderDocuments'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'

type Props = WithLoadingProps

function SurgicalOrderDocuments({ setIsLoading }: Props) {
  const { state } = useLocation<{ surgicalOrder: SurgicalOrderModel }>()
  const [surgicalOrder, setSurgicalOrder] = useState<SurgicalOrderModel>()
  const surgicalOrderService = useServices().surgicalOrder

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        setSurgicalOrder(
          await surgicalOrderService.loadSurgicalOrder(
            state.surgicalOrder.surgical_order_id ?? 0,
            [
              'surgical_order_id',
              'isSolicitation',
              `documents {
                type,
                name,
                document_id,
                documentUri
              }`
            ]
          )
        )
      } catch (error) {
        toast.error('Ocorreu um erro ao buscar o pedido cirúrgico')
      } finally {
        setIsLoading(false)
      }
    })()
  }, [])
  return (
    <SurgicalOrderDocumentsLayout
      surgicalOrder={{ ...state.surgicalOrder, ...surgicalOrder }}
    />
  )
}

export default WithLoading(SurgicalOrderDocuments)

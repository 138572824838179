import React from 'react'
import { Container } from 'presentation/shared/components/Container'
import Header from 'presentation/shared/components/Header'
import ActualPage from 'presentation/shared/components/ActualPage'
import { useHistory } from 'react-router-dom'
import * as S from './styles'
import FavoritesTable from 'presentation/doctor/components/Favorites/FavoritesTable'

const FavoritesLayout = () => {
  const history = useHistory()

  return (
    <>
      <Header />
      <S.Heading>
        <ActualPage
          data-testid="btn-go-back"
          text="Voltar"
          onClick={() => history.push('/home')}
        />
      </S.Heading>
      <Container title="Favoritos" hasHeader>
        <FavoritesTable />
      </Container>
    </>
  )
}

export default FavoritesLayout

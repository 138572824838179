import { SurgicalOrderStatus } from 'presentation/shared/components/Timeline/status'

export function translateSurgeryRequestStatus(
  status?: SurgicalOrderStatus | string
) {
  switch (status) {
    case SurgicalOrderStatus.AUTHORIZED:
      return 'Autorizado'
    case SurgicalOrderStatus.REVIEW:
      return 'Em revisão'
    case SurgicalOrderStatus.CANCELLED:
      return 'Cancelado'
    case SurgicalOrderStatus.OPME_QUOTATION:
      return 'Em cotação de OPME'
    case SurgicalOrderStatus.ORDER_REGISTERED:
      return 'Pedido cadastrado'
    case SurgicalOrderStatus.FASTPASS:
      return 'Fastpass'
    case SurgicalOrderStatus.SCHEDULED:
      return 'Agendado'
    case SurgicalOrderStatus.PRE_SCHEDULE:
      return 'Pré-agendado'
  }
}

import React, { useState } from 'react'

import { useFormik } from 'formik'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import Table from 'presentation/shared/components/Table'
import { Container } from 'presentation/shared/components/Container'
import TextField from 'presentation/shared/components/TextField'
import Button from 'presentation/shared/components/Button'
import { Tuss } from 'domain/entities/tuss'
import { UpdateSurgicalOrder } from 'domain/usecases/surgical-order/update-surgical-order'
import { useStores } from 'presentation/hooks/use-stores'
import AutoComplete from 'presentation/shared/components/AutoComplete'
import SupportText from 'presentation/shared/components/SupportText'
import { ReactComponent as DeleteIcon } from 'presentation/assets/icons/trash-outline-purple.svg'
import { WithLoadingProps } from 'presentation/shared/components/HOCs/WithLoading'

import * as S from './styles'

type Props = {
  tussList?: Tuss[]
  initialValues?: SelectProcedureFormValues
  updateSurgicalOrder?: UpdateSurgicalOrder
  loadSurgeries: (name?: string) => Promise<void>
  submit?: () => void
  surgicalOrderId: number
} & WithLoadingProps

const SelectProcedure = ({
  tussList,
  initialValues = {} as SelectProcedureFormValues,
  updateSurgicalOrder,
  submit,
  surgicalOrderId,
  loadSurgeries,
  setIsLoading
}: Props) => {
  const [tussQuery, setTussQuery] = useState('')
  const [tussQuantity, setTussQuantity] = useState(1)
  const [actualTuss, setActualTuss] = useState<Tuss>({} as Tuss)

  const { user } = useStores().currentAccount.getCurrentAccount()
  const history = useHistory()

  const { handleSubmit, setFieldValue, values, errors } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        const formatProcedures = () =>
          values.tuss.map((item, index) => ({
            isMain: index === 0,
            code: item.tuss_id,
            description: item.description,
            doctor_name: user.name,
            quantity: Number(item.quantity),
            procedure_code: item.tuss_id,
            surgery_id: item.surgery_id,
            pro_fat_id: item.pro_fat_id
          }))

        await updateSurgicalOrder?.update({
          procedure: formatProcedures(),
          surgical_order_id: surgicalOrderId
        })
        if (submit) {
          submit()
        }
      } catch (e: any) {
        toast.error(e.message)
      } finally {
        setIsLoading(false)
      }
    }
  })

  const addTuss = () => {
    setFieldValue('tuss', [
      ...values.tuss,
      {
        ...actualTuss,
        quantity: tussQuantity,
        isMain: values.tuss?.length === 0
      }
    ])
    setActualTuss({})
    setTussQuery('')
  }

  const removeTuss = (procedureId: number) => {
    const newTuss = values.tuss.filter(
      (procedure) => procedure.tuss_id !== procedureId
    )
    setFieldValue('tuss', newTuss)
  }

  function selectTuss(actualProcedure: Tuss) {
    setActualTuss(actualProcedure)
    setTussQuery(actualProcedure.tuss_id + ' - ' + actualProcedure.description)
    const tussAlreadyLinked = values.tuss.find(
      (surgery) => surgery.tuss_id === actualProcedure.tuss_id
    )
    if (tussAlreadyLinked) {
      toast.error('Procedimento já adicionado')
      throw new Error('Procedimento já adicionado')
    }
  }

  return (
    <>
      <S.Wrapper role="form">
        <Container
          title="Procedimento"
          primaryButton={
            <Button
              fullWidth
              disabled={!!errors.tuss || values.tuss?.length === 0}
              type="submit"
              onClick={() => handleSubmit()}
            >
              Próximo
            </Button>
          }
          secondaryButton={
            <Button fullWidth variant="outlined" onClick={history.goBack}>
              Cancelar
            </Button>
          }
        >
          <SupportText>
            Adicione os procedimentos que serão realizados e suas quantidades.
          </SupportText>
          <S.InputsContainer>
            <S.InputContainer>
              <AutoComplete
                label={'Código. Tuss'}
                placeholder="TUSS"
                onSuggestionClick={(procedure) => {
                  selectTuss(procedure)
                }}
                onType={loadSurgeries}
                onInputChange={(newValue: string) => {
                  if (actualTuss.tuss_id) {
                    setActualTuss({} as Tuss)
                    setTussQuery('')
                  } else {
                    setTussQuery(newValue)
                  }
                }}
                suggestions={tussList?.map((tuss) => ({
                  label: tuss.tuss_id + ' - ' + tuss.description,
                  value: tuss
                }))}
                value={tussQuery}
                required
                bgColor="white"
                inputBgColor="white"
              />
              <TextField
                type="number"
                label="Qt."
                required
                defaultValue={tussQuantity}
                min={1}
                value={tussQuantity}
                onInputChange={(val) => setTussQuantity(Number(val))}
              />
            </S.InputContainer>
          </S.InputsContainer>
          <Button
            fullWidth
            style={{
              marginTop: '24px'
            }}
            onClick={addTuss}
            disabled={Object.keys(actualTuss).length === 0}
          >
            Adicionar Código Tuss
          </Button>
          <S.ProcedureTable>
            <Table
              columns={tableColumns}
              data={values.tuss?.map((procedure) => ({
                tuss_id: procedure.tuss_id,
                description: procedure.description,
                quantity: procedure.quantity,
                delete: (
                  <S.DeleteButton onClick={() => removeTuss(procedure.tuss_id)}>
                    <DeleteIcon height={20} />
                  </S.DeleteButton>
                )
              }))}
              pagination={false}
              noItemsDescription={
                'Busque acima e adicione o Código Tuss junto da quantidade de procedimentos'
              }
            />
          </S.ProcedureTable>
        </Container>
      </S.Wrapper>
    </>
  )
}

type SelectProcedureFormValues = {
  tuss: any[]
}

const validationSchema = yup.object().shape({
  tuss: yup.array().of(
    yup.object().shape({
      tuss_id: yup.string().required(),
      description: yup.string().optional(),
      quantity: yup.number().min(1).required()
    })
  )
})

const tableColumns = [
  {
    name: 'tuss_id',
    label: 'TUSS',
    options: { filter: false, sort: false }
  },
  {
    name: 'description',
    label: 'Descrição',
    options: { filter: false, sort: false }
  },
  {
    name: 'quantity',
    label: 'Qt',
    options: { filter: false, sort: false }
  },
  {
    name: 'delete',
    label: ' ',
    options: { filter: false, sort: false }
  }
]

export default SelectProcedure

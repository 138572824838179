import React from 'react'

import { useFormik } from 'formik'
import { Gender } from 'common/enum/gender'
import TextField from 'presentation/shared/components/TextField'
import SelectField from 'presentation/shared/components/SelectField'
import { dateMask } from 'presentation/utils/masks'
import * as S from './styles'
import ProfilePic from 'presentation/shared/components/ProfilePic'
import Modal from 'presentation/shared/components/Modal'
import { useState } from 'react'
import { toast } from 'react-toastify'
import ActualPage from 'presentation/shared/components/ActualPage'
import { useHistory } from 'react-router'
import Button from 'presentation/shared/components/Button'
import { ethnicities } from 'presentation/utils/translators/ethnicities'

type Props = {
  initialValues: updatePersonalInfoFormValues
}

export default function UpdateDoctorPersonalInfoForm({
  initialValues = {} as updatePersonalInfoFormValues
}: Props) {
  const [successModal, setSuccessModal] = useState<boolean>(false)
  const history = useHistory()
  const formik = useFormik<updatePersonalInfoFormValues>({
    initialValues: initialValues,
    onSubmit: async () => {
      try {
        setSuccessModal(true)
      } catch (error) {
        toast.error(error.message)
      }
    }
  })

  return (
    <>
      <ActualPage text="Perfil" onClick={history.goBack} />
      <ProfilePic
        src="https://source.unsplash.com/random"
        outlined
        size="large"
        style={{
          display: 'block',
          margin: '0 auto',
          marginTop: '36px',
          marginBottom: '4px'
        }}
      />
      <p style={{ alignSelf: 'center' }}>{formik.initialValues.name}</p>
      <TextField
        label="Nome Completo"
        value={formik.values.name}
        initialValue={`${formik.initialValues.name} ${formik.initialValues.lastName}`}
        onInputChange={formik.handleChange('name')}
      />
      <TextField label="Como gostaria de ser chamado?" />

      <S.Row cols={1}>
        <SelectField label="Etnia" items={ethnicities} />
      </S.Row>

      <S.Row cols={1}>
        <TextField label="Naturalidade" />
      </S.Row>

      <S.Row cols={2}>
        <TextField
          label="Data de nascimento"
          mask={dateMask}
          value={''}
          onInputChange={formik.handleChange('birthday')}
        />

        <SelectField
          label="Sexo"
          value={formik.values.gender}
          items={[
            { label: 'Masculino', value: Gender.MALE },
            { label: 'Feminino', value: Gender.FEMALE }
          ]}
          onInputChange={formik.handleChange('gender')}
        />
      </S.Row>

      <S.Wrapper onSubmit={formik.handleSubmit}>
        <Button color="primary" fullWidth type="submit">
          Salvar
        </Button>
      </S.Wrapper>
      {/* to-do: add a real email above */}
      <Modal
        title="Ação não permitida"
        description="Favor procurar a diretoria técnica ou enviar um email para contato@redfox.tech, solicitando a edição dos seus dados. "
        show={successModal}
        close={() => setSuccessModal(false)}
        preventAutomateClose
        type="alert"
      />
    </>
  )
}

type updatePersonalInfoFormValues = {
  name: string
  lastName?: string
  gender: string
}

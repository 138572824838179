import styled from 'styled-components'

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  overflow-y: auto;

  div + div {
    margin-top: 20px;
  }
`

import styled from 'styled-components'

export const Highlights = styled.div`
  height: 13px;
  width: 13px;
  background-color: #00cfaa;
  border-radius: 50%;
  position: absolute;
  bottom: 0px;
  font-size: 10px;
  color: white;
  z-index: 2;

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
  }
`

export const DateText = styled.div`
  font-weight: 500;
  margin: 10px 0;
  color: #6a6a6a;
`

export const Wrapper = styled.div`
  position: relative;
  hr {
    border-color: #bfbfbf;
    background-color: #bfbfbf;
    color: #bfbfbf;
    border-top: 1px solid #bfbfbf;
  }
`

export const Loading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #888888d0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;

  span {
    font-weight: 600;
    color: white;
    font-size: 24px;
  }

  img {
    width: 100px;
    height: 100px;
  }
`

import styled, { css } from 'styled-components'

export const AttendancesOverview = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 12px;
  height: 128px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span,
  label {
    font-weight: 700 !important;
  }
`

export const ScheduledOrders = styled.div<{ color: 'blue' | 'orange' }>`
  ${({ theme, color }) => css`
    color: ${color === 'blue' ? '#5d86da' : theme.colors.lightOrange};
    height: 58px;
    grid-column: 1/3;
    font-weight: 700;
    border-bottom: 2px solid #f3f3f3;
    span {
      font-size: ${theme.font.sizes.xlarge};
    }

    label {
      font-size: ${theme.font.sizes.medium};
      margin-left: 9px;
    }
  `}
`

export const ApprovedOrders = styled.div`
  ${({ theme }) => css`
    height: 70px;
    border-right: 1px solid #f3f3f3;
    label {
      color: ${theme.colors.secondary};
      font-weight: ${theme.font.black};
      font-size: ${theme.font.sizes.xxsmall};
    }

    span {
      color: ${theme.colors.gray};
      font-weight: ${theme.font.black};
      font-size: ${theme.font.sizes.xlarge};
    }
  `}
  flex-direction: column;
`

export const RejectedOrders = styled.div`
  ${({ theme }) => css`
    height: 70px;
    border-left: 1px solid #f3f3f3;
    label {
      color: ${theme.colors.alert};
      font-weight: ${theme.font.black};
      font-size: ${theme.font.sizes.xxsmall};
    }

    span {
      color: ${theme.colors.gray};
      font-weight: ${theme.font.black};
      font-size: ${theme.font.sizes.xlarge};
    }
  `}
  flex-direction: column;
`

export const PendencyAlert = styled.div`
  width: 100%;
  color: white;
  height: 53px;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  background-color: #ff8787;
`

export const UnfinishedOrdersWrapper = styled.div`
  display: flex;
  padding: 16px 24px;
  height: 60px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  margin-bottom: 24px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius.small};
  font-weight: ${({ theme }) => theme.font.black};

  p {
    font-size: ${({ theme }) => theme.font.sizes.medium};
    color: #f48989;
  }

  span {
    font-size: ${({ theme }) => theme.font.sizes.large};
    color: ${({ theme }) => theme.colors.lightGray};
    font-weight: ${({ theme }) => theme.font.black} !important;
  }
`
export const EmptyAttendances = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  margin: auto;
  display: flex;
  text-align: center;
  height: 40px;
  align-items: center;
`

import React from 'react'

import { useFormikContext } from 'formik'

import SelectField from 'presentation/shared/components/SelectField'
import Button from 'presentation/shared/components/Button'
import { Container } from 'presentation/shared/components/Container'
import { Hospital } from 'domain/entities/hospital-model'
import { SurgeryCenter } from 'domain/entities/surgery-center-model'
import Chip from 'presentation/shared/components/Chip'
import * as S from './styles'
import { CreateSurgicalOrderFormValues } from 'presentation/doctor/pages/AddSurgicalOrder/CreateSurgicalOrder'
import { useHistory } from 'react-router'

type Props = {
  submit: () => void
  hospitals?: Hospital[]
  surgeryCenters?: SurgeryCenter[]
  loadSurgeryCenter?: (hospital_id: number) => void
}

const SelectHospital = ({
  submit,
  hospitals,
  surgeryCenters,
  loadSurgeryCenter
}: Props) => {
  const { errors, setFieldValue, values } =
    useFormikContext<CreateSurgicalOrderFormValues>()

  function selectHospital(id: string) {
    const hospital = hospitals?.find(
      (hospital) => hospital.hospital_id == Number(id)
    )
    if (hospital) {
      setFieldValue('hospital', hospital)
      setFieldValue('surgeryCenter', undefined)
      loadSurgeryCenter?.(hospital.hospital_id ?? 0)
    } else {
      setFieldValue('hospital', undefined)
    }
  }

  async function selectSurgicalCenter(surgicalCenter: SurgeryCenter) {
    if (surgicalCenter) {
      await setFieldValue('surgeryCenter', surgicalCenter)
    } else {
      await setFieldValue('surgeryCenter', undefined)
    }
  }

  const history = useHistory()
  return (
    <Container
      actualPageText="Voltar"
      actualPageOnClick={() => history.goBack()}
      title="Novo pedido cirúrgico"
      subtitle="Selecione o hospital em que o
      procedimento será realizado."
      justifyContent="center"
      primaryButton={
        <Button
          fullWidth
          onClick={submit}
          disabled={!!errors.hospital || !!errors.surgeryCenter}
        >
          Confirmar
        </Button>
      }
    >
      <SelectField
        label="Hospital"
        items={hospitals?.map((hospital) => ({
          value: hospital.hospital_id,
          label: hospital.nickname
        }))}
        onInputChange={selectHospital}
        defaultValue=""
      />
      <S.SurgeryCenters>
        {surgeryCenters?.map((center) => (
          <Chip
            key={center.codeCenter}
            label={center.description}
            customValue={center}
            name="surgeryCenter"
            onCheck={selectSurgicalCenter}
            checked={values.surgeryCenter === center}
            dataTestId={`surgery-center-chip-${center.codeCenter}`}
          />
        ))}
      </S.SurgeryCenters>
    </Container>
  )
}

export default SelectHospital

import React from 'react'
import SurgeryOrderList from 'presentation/doctor/layouts/SurgeryOrderList'
import { LoadSurgicalOrdersByDoctor } from 'domain/usecases/doctor/load-surgical-order'
import { useLocation } from 'react-router-dom'

type Props = {
  loadDoctorSurgicalOrders: LoadSurgicalOrdersByDoctor
}

export default function SurgeryOrderListPage({
  loadDoctorSurgicalOrders
}: Props) {
  const { state } = useLocation<{
    params: LoadSurgicalOrdersByDoctor.Params['order_status']
    unfinished?: boolean
  }>()

  return (
    <SurgeryOrderList
      order_status={state.params}
      unfinishedOrders={state?.unfinished}
      loadDoctorSurgicalOrders={loadDoctorSurgicalOrders}
    />
  )
}

import styled from 'styled-components'

export const Wrapper = styled.div``

export const Title = styled.h1`
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.gray};
`

export const Complement = styled.span`
  margin-top: 24px;
`

export const Buttons = styled.div`
  margin-top: 40px;

  button + button {
    margin-top: 12px;
  }
`

import React, { useEffect } from 'react'

import { useFormik } from 'formik'
import { toast } from 'react-toastify'

import { UpdateSurgicalOrder } from 'domain/usecases/surgical-order/update-surgical-order'
import Button from 'presentation/shared/components/Button'
import Chip from 'presentation/shared/components/Chip'
import { Container } from 'presentation/shared/components/Container'
import TextField from 'presentation/shared/components/TextField'
import { Label } from 'presentation/shared/components/TextField/styles'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import * as FormS from '../styles'
import * as S from './styles'

import moment from 'moment'
import Header from 'presentation/shared/components/Header'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { addPlural } from 'presentation/utils/add-plural'

type Props = {
  updateSurgicalOrder: UpdateSurgicalOrder
  surgicalOrderId: number
  initialValues?: SurgicalOrderModel
}

const formatExpectedDates = (dates: string[]) => {
  return dates.map((date) => {
    return new Date(date).toISOString().slice(0, 10)
  })
}

const formatExpectedDuration = (duration?: string) => {
  const expectedDuration = duration
    ?.replace(' minutos', '')
    ?.replace(' minuto', '')
  return Number.isNaN(Number(expectedDuration))
    ? undefined
    : Number(expectedDuration)
}

export const adaptSurgicalOrderToFormValues = (
  surgicalOrder: SurgicalOrderModel
): AddReservationInfoFormValues => {
  return {
    ...surgicalOrder,
    hospitalizationType: surgicalOrder.hospitalizationType?.toUpperCase(),
    hospitalizationMode: surgicalOrder.hospitalizationMode
      ?.toUpperCase()
      .replace('DAY HOSPITAL', 'DAY_HOSPITAL'),
    expectedDate: surgicalOrder.expectedDate
      ? formatExpectedDates(surgicalOrder.expectedDate as unknown as string[])
      : [],
    expectedDuration: formatExpectedDuration(surgicalOrder.expectedDuration),
    useICU: !!surgicalOrder.requestedUtiDiaries
  } as AddReservationInfoFormValues
}

export default function AddReservationInfo({
  updateSurgicalOrder,
  surgicalOrderId,
  initialValues = {} as SurgicalOrderModel
}: Props) {
  const history = useHistory()

  const {
    errors,
    values,
    handleChange,
    setFieldValue,
    submitForm,
    isValid,
    handleBlur,
    touched,
    setValues
  } = useFormik({
    initialValues: adaptSurgicalOrderToFormValues(initialValues),
    validationSchema: reservationInfoValidationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      try {
        const updateData = {
          hospitalizationType: formValues.hospitalizationType,
          expectedDate: formValues.expectedDate,
          expectedDuration: `${formValues.expectedDuration} ${addPlural(
            'minuto',
            formValues.expectedDuration
          )}`,
          requestedUtiDiaries: formValues.requestedUtiDiaries,
          hospitalizationMode: formValues.hospitalizationMode,
          patientHospitalized: formValues.patientHospitalized,
          hospitalizationAvgTimeInDays: formValues.hospitalizationAvgTimeInDays
        }
        await updateSurgicalOrder.update({
          ...updateData,
          surgical_order_id: surgicalOrderId
        })

        return history.goBack()
      } catch (e: any) {
        toast.error(e.message)
      }
    }
  })

  const hasDurationFilled =
    values.expectedDuration >= 0 && String(values.expectedDuration) !== ''

  const hashospitalizationAvgTimeInDaysFilled =
    values.hospitalizationAvgTimeInDays >= 0 &&
    String(values.hospitalizationAvgTimeInDays) !== ''

  const getExpectedDateErrorMessage = (index: number) => {
    if (typeof errors?.expectedDate === 'string') {
      if (values?.expectedDate?.[index]) {
        return undefined
      }
      return touched?.expectedDate?.[index] ? errors?.expectedDate : undefined
    }
    return touched?.expectedDate?.[index] || values?.expectedDate?.[index]
      ? errors?.expectedDate?.[index]
      : undefined
  }

  return (
    <>
      <Header />
      <Container
        title="Caráter da Reserva"
        primaryButton={
          <Button
            type="button"
            fullWidth
            disabled={!isValid}
            onClick={() => submitForm()}
          >
            Próximo
          </Button>
        }
        secondaryButton={
          <Button variant="outlined" fullWidth onClick={history.goBack}>
            Cancelar
          </Button>
        }
      >
        <FormS.LabelContainer>
          <Label>
            Caráter<span>*</span>
          </Label>
        </FormS.LabelContainer>
        <FormS.FlexContainer>
          {characters.map((character) => (
            <Chip
              variant="square"
              key={character.label}
              label={character.label}
              customValue={character.value}
              name="hospitalizationType"
              onCheck={handleChange('hospitalizationType')}
              checked={character.value === values.hospitalizationType}
              dataTestId={character.value}
            />
          ))}
        </FormS.FlexContainer>

        <FormS.FlexContainer>
          <S.DateContainer>
            <FormS.LabelContainer>
              <Label>
                Sugestão 1 <span>*</span>
              </Label>
            </FormS.LabelContainer>
            <TextField
              style={{ width: '12rem' }}
              type="date"
              onBlur={handleBlur}
              showCalendarIcon
              name="expectedDate[0]"
              id="expectedDate[0]"
              onChange={handleChange}
              defaultValue={values.expectedDate[0]}
              error={getExpectedDateErrorMessage(0)}
              required={values.hospitalizationType === 'urgency'}
              data-testid="expected-date-input"
            />
          </S.DateContainer>
          <S.DateContainer>
            <FormS.LabelContainer>
              <Label>
                Sugestão 2 <span>*</span>
              </Label>
            </FormS.LabelContainer>
            <TextField
              style={{ width: '12rem' }}
              type="date"
              onBlur={handleBlur}
              showCalendarIcon
              name="expectedDate[1]"
              id="expectedDate[1]"
              onChange={handleChange}
              defaultValue={values.expectedDate[1]}
              error={getExpectedDateErrorMessage(1)}
              required={values.hospitalizationType === 'urgency'}
              disabled={!values.expectedDate[0]}
              data-testid="expected-date-input"
            />
          </S.DateContainer>
          <S.DateContainer>
            <FormS.LabelContainer>
              <Label>
                Sugestão 3 <span>*</span>
              </Label>
            </FormS.LabelContainer>
            <TextField
              style={{ width: '12rem' }}
              type="date"
              onBlur={handleBlur}
              showCalendarIcon
              name="expectedDate[2]"
              id="expectedDate[2]"
              onChange={handleChange}
              defaultValue={values.expectedDate[2]}
              error={getExpectedDateErrorMessage(2)}
              required={values.hospitalizationType === 'urgency'}
              data-testid="expected-date-input"
              disabled={!values.expectedDate[1]}
            />
          </S.DateContainer>
        </FormS.FlexContainer>
        <FormS.LabelContainer>
          <Label>
            Duração prevista<span>*</span>
          </Label>
        </FormS.LabelContainer>
        <FormS.FlexContainer>
          <TextField
            style={{ maxWidth: '40%' }}
            onChange={handleChange('expectedDuration')}
            onBlur={handleBlur}
            error={
              touched.expectedDuration ? errors.expectedDuration : undefined
            }
            value={values.expectedDuration}
            name="expectedDuration"
            id="expectedDuration"
            type="number"
            placeholder="Minutos"
            min={0}
          />
          {hasDurationFilled && (
            <FormS.LabelContainer
              style={{ display: 'flex', alignItems: 'flex-end' }}
            >
              <Label>{addPlural('Minuto', values.expectedDuration)}</Label>
            </FormS.LabelContainer>
          )}
        </FormS.FlexContainer>
        {}
        <FormS.LabelContainer>
          <Label>
            Uso de UTI?<span>*</span>
          </Label>
        </FormS.LabelContainer>
        <FormS.FlexContainer>
          {defaultOptions.map((item) => (
            <Chip
              variant="square"
              id={item.label + 'useICU'}
              name="useICU"
              label={item.label}
              key={item.label + 'useICU'}
              customValue={item.value}
              onCheck={(val) => {
                if (val) {
                  setValues({
                    ...values,
                    useICU: item.value,
                    requestedUtiDiaries: 1
                  })
                } else {
                  setValues({
                    ...values,
                    useICU: item.value,
                    requestedUtiDiaries: null
                  })
                }
              }}
              checked={item.value === values.useICU}
              dataTestId={item.label + 'useICU'}
            />
          ))}
        </FormS.FlexContainer>
        {values.useICU && (
          <FormS.LabelInputContainer>
            <FormS.LabelContainer>
              <Label>
                Quantidade de Diárias de UTI<span>*</span>
              </Label>
            </FormS.LabelContainer>
            <TextField
              style={{ maxWidth: '25%' }}
              onChange={handleChange('requestedUtiDiaries')}
              onBlur={handleBlur}
              value={values.requestedUtiDiaries}
              name="requestedUtiDiaries"
              id="requestedUtiDiaries"
              type="number"
              error={
                touched.requestedUtiDiaries
                  ? errors.requestedUtiDiaries
                  : undefined
              }
              min={1}
            />
          </FormS.LabelInputContainer>
        )}
        <FormS.LabelContainer>
          <Label>
            Regime hospitalar<span>*</span>
          </Label>
        </FormS.LabelContainer>
        <FormS.FlexContainer>
          {hospitalRegimes.map((item) => (
            <Chip
              variant="square"
              label={item.label}
              key={item.label}
              customValue={item.value}
              name="hospitalizationMode"
              onCheck={handleChange('hospitalizationMode')}
              checked={item.value === values.hospitalizationMode}
              dataTestId={item.value + '-hospitalization-mode'}
            />
          ))}
        </FormS.FlexContainer>
        <FormS.LabelContainer>
          <Label>
            Paciente já internado?<span>*</span>
          </Label>
        </FormS.LabelContainer>
        <FormS.FlexContainer>
          {defaultOptions.map((item) => (
            <Chip
              variant="square"
              id={item.label + 'patientHospitalized'}
              name="patientHospitalized"
              label={item.label}
              key={item.label + 'patientHospitalized'}
              customValue={item.value}
              onCheck={(val) => setFieldValue('patientHospitalized', val)}
              checked={item.value === values.patientHospitalized}
              dataTestId={item.label + 'patientHospitalized'}
            />
          ))}
        </FormS.FlexContainer>

        <FormS.LabelContainer>
          <Label>
            Tempo médio de internação<span>*</span>
          </Label>
        </FormS.LabelContainer>
        <FormS.FlexContainer style={{ marginBottom: '20px' }}>
          <TextField
            style={{ maxWidth: '40%' }}
            onChange={handleChange('hospitalizationAvgTimeInDays')}
            onBlur={handleBlur}
            error={
              touched.hospitalizationAvgTimeInDays
                ? errors.hospitalizationAvgTimeInDays
                : undefined
            }
            value={values.hospitalizationAvgTimeInDays}
            name="hospitalizationAvgTimeInDays"
            id="hospitalizationAvgTimeInDays"
            type="number"
            placeholder="Dias"
            min={0}
          />
          {hashospitalizationAvgTimeInDaysFilled && (
            <FormS.LabelContainer
              style={{ display: 'flex', alignItems: 'flex-end' }}
            >
              <Label>
                {addPlural('Dia', values.hospitalizationAvgTimeInDays)}
              </Label>
            </FormS.LabelContainer>
          )}
        </FormS.FlexContainer>
      </Container>
    </>
  )
}

const defaultOptions: ChipItem[] = [
  { label: 'Sim', value: true },
  {
    label: 'Não',
    value: false
  }
]

const characters = [
  {
    label: 'Eletivo',
    value: 'ELECTIVE'
  },
  {
    label: 'Urgência',
    value: 'URGENCY'
  },
  {
    label: 'Emergência',
    value: 'EMERGENCY'
  }
]

const hospitalRegimes = [
  {
    label: 'Ambulatorial',
    value: 'AMBULATORY'
  },
  {
    label: 'Hospital dia',
    value: 'DAY_HOSPITAL'
  },
  {
    label: 'Hospitalar',
    value: 'HOSPITAL'
  }
]

type ChipItem = {
  label: string
  value: any
}

export type AddReservationInfoFormValues = {
  hospitalizationType: string
  expectedDate?: string[]
  expectedDuration: number
  useICU: boolean
  requestedUtiDiaries?: number
  hospitalizationMode: string
  patientHospitalized: boolean
  hospitalizationAvgTimeInDays?: number
}

const yupDateValidation = yup
  .array()
  .min(3, 'Selecione as 3 datas.')
  .of(
    yup
      .date()
      .typeError('Data Inválida')
      .format('YYYY-MM-DD', true)
      .required()
      .test(
        'greater_then_today',
        'A data informada não pode ser menor que a atual.',
        (value) => {
          if (value) {
            const date = moment(value, 'YYYY-MM-DD').startOf('day')
            const now = moment().startOf('day')
            if (date.isValid()) {
              return date >= now
            }
          }
          return true
        }
      )
  )

export const reservationInfoValidationSchema = yup.object().shape({
  hospitalizationType: yup.string().required(),
  expectedDate: yupDateValidation,
  expectedDuration: yup.number().min(0).required(),
  useICU: yup.boolean().required(),
  requestedUtiDiaries: yup
    .number()
    .nullable()
    .notRequired()
    .when('useICU', {
      is: true,
      then: yup
        .number()
        .min(1, 'A quantidade precisa ser maior que 0')
        .required('Campo obrigatório')
    }),
  hospitalizationMode: yup.string().required(),
  patientHospitalized: yup.boolean().required(),
  hospitalizationAvgTimeInDays: yup.number().min(0).required()
})

import React, { useEffect, useState } from 'react'

import * as S from './styles'
import Heading from 'presentation/shared/components/Heading'
import SignatureInput from 'presentation/doctor/components/SignatureInput'
import Header from 'presentation/shared/components/Header'
import { Container } from 'presentation/shared/components/Container'
import { AddDoctorSignature } from 'domain/usecases/doctor/add-signature'
import { useStores } from 'presentation/hooks/use-stores'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import LoadingModal from 'presentation/shared/components/LoadingModal'

type Props = {
  addDoctorSignature?: AddDoctorSignature
}

export const AddSignatureLayout = observer(({ addDoctorSignature }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const history = useHistory()
  const permissions = useStores().permissions

  useEffect(() => {
    const loadPermissions = async () => {
      if (!permissions.getAbilities().find((ability) => ability === 'access')) {
        try {
          await permissions.load()
        } catch (e) {
          return
        }
      }
    }
    loadPermissions()
  }, [])

  const submit = async (file: Blob) => {
    const form = new FormData()
    form.append('file', file, 'signature.png')
    try {
      setIsLoading(true)
      await addDoctorSignature?.upload({ form })
      await permissions.load()
      history.push('/home')
    } catch (error) {
      toast.error('Occoreu um erro ao adicionar sua assinatura')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '100%',
        width: '100%'
      }}
    >
      <Header />
      <Container hasHeader>
        <S.Wrapper>
          <Heading color="primary">Cadastro de assinatura</Heading>
          <S.Subtitle style={{ margin: 'auto' }}>
            Vire o celular para criar sua assinatura
          </S.Subtitle>
          <SignatureInput onSubmit={submit} />
        </S.Wrapper>
      </Container>
      {isLoading && <LoadingModal show={true} />}
    </div>
  )
})

export default AddSignatureLayout

import React from 'react'

import { useLocation } from 'react-router-dom'

import { UpdateSurgicalOrder } from 'domain/usecases/surgical-order/update-surgical-order'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import AddEquipments from 'presentation/doctor/components/Forms/AddSurgicalOrder/AddEquipments'

type Props = {
  updateSurgicalOrder: UpdateSurgicalOrder
}

type Location = {
  surgical_order_id: number
  surgicalOrder: SurgicalOrderModel
}

export default function CreateEquipments({ updateSurgicalOrder }: Props) {
  const { state } = useLocation<Location>()

  const { surgical_order_id, surgicalOrder } =
    state || ({} as unknown as Location)
  return (
    <AddEquipments
      surgicalOrderId={surgical_order_id}
      updateSurgicalOrder={updateSurgicalOrder}
      initialValues={surgicalOrder}
    />
  )
}

import styled from 'styled-components'

import { ReactComponent as TrashIcon } from 'presentation/assets/icons/trashIcon.svg'

type StatusProps = {
  statusColor: string
}

export const Wrapper = styled.div<StatusProps>`
  display: flex;
  height: auto;
  width: 100%;
  position: relative;
  border-radius: 8px;
  padding: 10px;
  padding-left: 30px;
  background-color: white;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  min-height: 80px;
  flex-shrink: 0;
  cursor: pointer;
  &::before {
    width: 20px;
    height: 100%;
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    background-color: ${({ statusColor }) => statusColor};
    border-radius: 8px 0 0 8px;
  }
`

export const Header = styled.header`
  color: #888888;
  font-size: 12px;
  line-height: 14px;
  font-weight: ${({ theme }) => theme.font.normal};

  h3 {
    font-size: 1em;
    font-weight: ${({ theme }) => theme.font.black};
    text-transform: uppercase;
  }

  p {
    font-size: 1em;
  }
`

export const ScheduleInfo = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  div svg path {
    fill: #888888 !important;
  }

  & > div:first-child {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 130px;
  }

  div:first-child {
    color: #888888;
    display: flex;
    align-items: center;
    width: 100%;
    div {
      display: flex;
    }
    span {
      margin-left: 4px;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`

export const Status = styled.div<StatusProps>`
  background-color: ${({ statusColor }) => statusColor};
  margin-top: auto;
  color: white !important;
  padding: 4px;
  border-radius: 60px;
  font-weight: 600 !important;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  display: inline-block;
  max-height: 26px;
  width: fit-content;
`

export const DeleteIcon = styled(TrashIcon)`
  position: absolute;
  top: 10px;
  right: 13px;
  width: 15px;
  cursor: pointer;
`

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 5px;
`

export const CloseModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 160px;

  div {
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
    }
  }
`

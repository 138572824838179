import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  flex: 1;
  overflow-y: auto;

  & > div:not(:first-child) {
    margin-top: 8px;
  }
`

export const Documents = styled.div``
export const BackModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  div {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    button {
      margin-top: 20px;
      width: 48%;
    }
  }
`

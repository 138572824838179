import React, { useState } from 'react'

import List, { ListItem } from 'presentation/shared/components/List'
import * as S from './styles'
import { Container } from 'presentation/shared/components/Container'
import ProfilePic from 'presentation/shared/components/ProfilePic'
import Heading from 'presentation/shared/components/Heading'
import { useHistory } from 'react-router-dom'
import FavoriteButton from 'presentation/shared/components/FavoriteButton'
import { Opme } from 'presentation/doctor/pages/AddSurgicalOrder'
import { AddFavorite } from 'domain/usecases/surgical-order/add-favorite'
import Header from 'presentation/shared/components/Header'
import { ReactComponent as ArrowIcon } from 'presentation/assets/icons/rounded-arrow.svg'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import FavoriteSurgicalOrderModal from 'presentation/doctor/components/FavoriteSurgicalOrderModal'

type Props = {
  items: ListItem[]
  opme?: Opme
  addFavorite?: AddFavorite
  surgeryName?: string
  patientName?: string
  disabled: boolean
  documentList: ListItem[]
  surgicalOrder: SurgicalOrderModel
}

export default function AddSurgicalOrderLayout({
  items,
  opme,
  surgeryName,
  patientName,
  documentList,
  disabled,
  surgicalOrder
}: Props) {
  const history = useHistory()
  const [favoriteModal, setFavoriteModal] = useState(false)

  return (
    <>
      <Header />
      <Container
        actualPageText="Voltar para home"
        actualPageOnClick={() => history.push('/home')}
      >
        <S.Card>
          <header>
            <h6>CIRURGIA</h6>
            <span style={{ textAlign: 'left' }}>
              {surgeryName ? surgeryName : 'Nome do procedimento cirúrgico'}
            </span>
          </header>
          <div>
            <ProfilePic
              size="medium"
              src="https://source.unsplash.com/random"
            />
            <div>
              <span>Paciente</span>
              <p>{patientName}</p>
            </div>
          </div>
        </S.Card>
        <Heading
          size="medium"
          color="primary"
          style={{ marginTop: '40px', marginBottom: '20px' }}
        >
          Planejamento cirúrgico
        </Heading>
        <S.List>
          <List items={items} size="medium" />
        </S.List>

        <Heading
          size="medium"
          color="primary"
          style={{ marginTop: '40px', marginBottom: '20px' }}
        >
          Anexos
        </Heading>
        <S.List>
          <List items={documentList} size="medium" />
        </S.List>
        <S.ResumeButton
          onClick={() =>
            history.push('/pedido-cirurgico/resumo', {
              surgicalOrder
            })
          }
          disabled={disabled}
        >
          Resumo do pedido
          <ArrowIcon />
        </S.ResumeButton>
        <FavoriteButton
          data-testid="btn--doctor-procedure-favorite"
          onClick={() => setFavoriteModal(!favoriteModal)}
          disabled={!opme}
          style={{ marginTop: 10 }}
          fullWidth
        />
        {favoriteModal ? (
          <FavoriteSurgicalOrderModal
            onClose={() => setFavoriteModal(false)}
            surgicalOrder={surgicalOrder}
          />
        ) : null}
      </Container>
    </>
  )
}

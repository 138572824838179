import React from 'react'

import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'

import { Container } from 'presentation/shared/components/Container'
import Button from 'presentation/shared/components/Button'
import SupportText from 'presentation/shared/components/SupportText'
import { UpdateSurgicalOrder } from 'domain/usecases/surgical-order/update-surgical-order'
import TextField from 'presentation/shared/components/TextField'
import { WithLoadingProps } from 'presentation/shared/components/HOCs/WithLoading'

import * as S from './styles'

type Props = {
  initialValues: SelectProvidersFormValues
  values: SelectProvidersFormValues
  updateSurgicalOrder: UpdateSurgicalOrder
  surgicalOrderId: number
  goBack: () => void
  setSuccessModal: () => void
} & WithLoadingProps

const SelectProviders = ({
  initialValues,
  updateSurgicalOrder,
  surgicalOrderId,
  goBack,
  setSuccessModal,
  setIsLoading
}: Props) => {
  const { handleSubmit, values, errors, handleChange, isValid } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        const providers: string[] = []
        if (values.provider1) {
          providers.push(values.provider1.trim())
        }
        if (values.provider2) {
          providers.push(values.provider2.trim())
        }
        if (values.provider3) {
          providers.push(values.provider3.trim())
        }
        await updateSurgicalOrder?.update({
          opme: {
            solicitations: initialValues.opmes.map((item: Opme) => ({
              description: item.description,
              quantity: Number(item.quantity),
              opme_id: item.opme_id,
            })),
            providers: providers
          },
          surgical_order_id: surgicalOrderId
        })
        setSuccessModal()
      } catch (e: any) {
        toast.error(e.message)
      } finally {
        setIsLoading(false)
      }
    }
  })
  return (
    <S.Wrapper role="form">
      <Container
        title="OPME"
        primaryButton={
          <Button
            fullWidth
            disabled={
              !isValid
            }
            type="submit"
            onClick={() => handleSubmit()}
            data-testid="save-opme-providers-button"
          >
            Salvar
          </Button>
        }
        secondaryButton={
          <Button
            fullWidth
            variant="outlined"
            onClick={goBack}
            data-testid="go-back-opme-providers-button"
          >
            Voltar
          </Button>
        }
      >
        <SupportText>
          De acordo com a Agência Nacional de Saúde Suplementar (ANS), o prazo
          de autorização é de até 21 dias úteis.
        </SupportText>
        <S.InputsContainer>
          <TextField
            name="provider1"
            id="provider1"
            label="Fornecedor 1"
            style={{ marginTop: '24px' }}
            required
            onChange={handleChange}
            value={
              initialValues.provider1
                ? initialValues.provider1
                : values.provider1
            }
            data-testid="opme-provider-1-input"
          />
          <TextField
            label="Fornecedor 2"
            name="provider2"
            id="provider2"
            onChange={handleChange}
            required
            value={
              initialValues.provider2
                ? initialValues.provider2
                : values.provider2
            }
            style={{ marginTop: '24px' }}
            data-testid="opme-provider-2-input"
          />
          <TextField
            label="Fornecedor 3"
            name="provider3"
            id="provider3"
            onChange={handleChange}
            required
            value={
              initialValues.provider3
                ? initialValues.provider3
                : values.provider3
            }
            style={{ marginTop: '24px' }}
            data-testid="opme-provider-3-input"
          />
        </S.InputsContainer>
      </Container>
    </S.Wrapper>
  )
}

const validationSchema = yup.object().shape({
  provider1: yup.string().trim().required(),
  provider2: yup.string().trim().required(),
  provider3: yup.string().trim().required()
})

type SelectProvidersFormValues = {
  opmes: any[]
  provider1: string
  provider2: string
  provider3: string
}

type Opme = {
  description: string
  quantity: string
  opme_id: number
}

export default SelectProviders

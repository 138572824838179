import styled from 'styled-components'

export const SurgeryCenters = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  label {
    margin-right: 8px;
    margin-top: 8px;
  }
`

import React from 'react'

import AddPatientLayout from 'presentation/doctor/layouts/AddPatient'
import { InvitePatient } from 'domain/usecases/patient/invite-patient'
import { LoadHealthInsurance } from 'domain/usecases/health-insurance/load-health-insurance'
import { useLocation } from 'react-router-dom'
import { Hospital } from 'domain/entities/hospital-model'

type Props = {
  invitePatient: InvitePatient
  loadHealthInsurance: LoadHealthInsurance
}

export default function AddPatient({
  invitePatient,
  loadHealthInsurance
}: Props) {
  const { state } = useLocation<{ hospital: Hospital }>()
  return (
    <AddPatientLayout
      invitePatient={invitePatient}
      loadHealthInsurance={loadHealthInsurance}
      hospital={state.hospital}
    />
  )
}

import styled from 'styled-components'

export const Wrapper = styled.div<{
  bgImg?: string | Promise<string | undefined>
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
  min-height: 150px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px dashed ${({ theme }) => theme.colors.secondary};
  border-radius: ${({ theme }) => theme.border.radius.small};
  background-image: ${({ bgImg }) => `url(${bgImg})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  text-align: center;
`

export const Info = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: 0;
  padding: 0;
  bottom: 0;
  left: 0;
  background: #000000dd;
  border-radius: 0 0 ${({ theme }) => theme.border.radius.small}
    ${({ theme }) => theme.border.radius.small};

  p {
    color: white;
  }
`

export const Loading = styled.img`
  width: 50px;
`

import React, { useState } from 'react'

import * as S from './styles'
import moment from 'moment-timezone'
import SupportText from 'presentation/shared/components/SupportText'
import Heading from 'presentation/shared/components/Heading'
import {
  ScheduleData,
  ScheduleDataTypes
} from 'domain/usecases/doctor/load-doctor-schedule'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { Schedule } from 'domain/entities/schedule-model'
import { getStatusInfo } from 'presentation/utils/service-status'
import { ReactComponent as LocationIcon } from 'presentation/assets/icons/location.svg'
import { ReactComponent as ScheduleIcon } from 'presentation/assets/icons/calendar.svg'
import { Hospital } from 'domain/entities/hospital-model'
import { DoctorScheduleModal } from '../DoctorScheduleModal'
import { getScheduleStatus } from 'presentation/utils/schedule-status'

export function DoctorScheduleCard({ type, data, date }: ScheduleData) {
  const [showModal, setShowModal] = useState<boolean>(false)
  const content = dataAdapter(type, data)
  return (
    <>
      <S.Wrapper
        type={type}
        onClick={() => setShowModal(true)}
        data-testid="doctor-schedule-card-wrapper"
      >
        <S.StatusWrapper>{content.status}</S.StatusWrapper>
        <Heading
          style={{ color: '#888888' }}
          data-testid="doctor-schedule-card-title"
        >
          {content.title}
        </Heading>
        <SupportText
          data-testid="doctor-schedule-card-patient-name"
          style={{
            fontWeight: 500,
            color: '#888888',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            maxWidth: '100%',
            whiteSpace: 'nowrap'
          }}
        >
          Paciente: {content.patient}
        </SupportText>
        <div style={{ display: 'flex' }}>
          <ScheduleIcon />
          <SupportText
            data-testid="doctor-schedule-card-achievement-date"
            style={{ fontWeight: 500, color: '#888888' }}
          >
            Data de realização:{' '}
            {moment(content.date).utc(true).format('DD/MM/YYYY - HH:mm[h]')}
          </SupportText>
        </div>
        <div style={{ display: 'flex' }}>
          <LocationIcon />
          <SupportText
            data-testid="doctor-schedule-card-hospital-name"
            style={{ fontWeight: 500, color: '#888888' }}
          >
            {content.hospital}
          </SupportText>
        </div>
      </S.Wrapper>
      <DoctorScheduleModal
        show={showModal}
        data={{ type, date, data }}
        onClose={() => setShowModal(false)}
      />
    </>
  )
}

function dataAdapter(
  type: 'surgical' | 'ambulatory',
  data: ScheduleDataTypes
): CardContent {
  const response: CardContent = {
    patient: data.patientName,
    hospital:
      new Hospital(0, data.hospitalName ?? '', data.hospitalName ?? '').name ||
      ''
  } as CardContent
  switch (type) {
    case 'surgical':
      data = data as SurgicalOrderModel
      response.title = data.procedure?.[0].description || ''

      response.date = data.surgeryDate || ''
      response.status =
        getStatusInfo(data?.status?.slice(-1)?.[0]?.status)?.label || ''
      break
    case 'ambulatory':
      data = data as Schedule
      response.title = data.serviceDescription
      response.status = getScheduleStatus(data.status)
      // eslint-disable-next-line no-constant-condition
      //@ts-ignore
      response.date =
        typeof data.scheduleTime === 'string'
          ? data.scheduleTime
          : data.scheduleTime.toISOString() || ''
      break
    default:
      return response
  }
  return response
}

type CardContent = {
  status: string
  title: string
  patient: string
  date: string
  hospital: string
}

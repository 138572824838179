import React from 'react'

import { useFormik } from 'formik'
import { toast } from 'react-toastify'

import { UpdateSurgicalOrder } from 'domain/usecases/surgical-order/update-surgical-order'
import Button from 'presentation/shared/components/Button'
import { Container } from 'presentation/shared/components/Container'
import { useHistory } from 'react-router-dom'
import * as S from './styles'
import * as yup from 'yup'

import Header from 'presentation/shared/components/Header'
import TextField from 'presentation/shared/components/TextField'

import Checkbox from 'presentation/shared/components/Checkbox'
import { defaultEquipments } from 'presentation/utils/default-equipments'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'

type Props = {
  updateSurgicalOrder: UpdateSurgicalOrder
  surgicalOrderId: number
  initialValues?: SurgicalOrderModel
}

const adaptSurgicalOrderToFormValues = (
  surgicalOrder: SurgicalOrderModel
): Map<string, string> => {
  if (!surgicalOrder.equipment) return new Map<string, string>()
  return new Map<string, string>(
    surgicalOrder.equipment.map((equipment) => [
      equipment.name,
      equipment.specification
    ])
  )
}

export default function AddEquipments({
  updateSurgicalOrder,
  surgicalOrderId,
  initialValues = {} as SurgicalOrderModel
}: Props) {
  const history = useHistory()

  const { values, submitForm, setFieldValue, setValues } =
    useFormik<AddEquipmentsFormValues>({
      initialValues: {
        equipments: adaptSurgicalOrderToFormValues(initialValues),
        touchedEquipments: new Set<string>(),
        ...initialValues
      },
      onSubmit: async (formValues) => {
        try {
          const equipment = Array.from(formValues.equipments.entries()).map(
            (equip) => {
              return {
                name: equip[0],
                specification: equip[1]
              }
            }
          )
          await updateSurgicalOrder.update({
            surgical_order_id: surgicalOrderId,
            equipment
          })
          return history.goBack()
        } catch (e: any) {
          toast.error(e.message)
        }
      }
    })

  const isValid = (() => {
    return Array.from(values.equipments.values()).every((spec) => !!spec)
  })()

  return (
    <>
      <Header />
      <Container
        title="Equipamentos necessários"
        subtitle="Indique abaixo os equipamentos para a cirurgia"
        primaryButton={
          <Button
            type="button"
            fullWidth
            disabled={!isValid}
            onClick={() => submitForm()}
          >
            Próximo
          </Button>
        }
        secondaryButton={
          <Button variant="outlined" fullWidth onClick={history.goBack}>
            Cancelar
          </Button>
        }
      >
        <S.AddEquipmentsContainer>
          {defaultEquipments?.map((equipment) => (
            <S.InputContainer key={equipment}>
              <Checkbox
                labelFor={'equipments' + equipment}
                name={'equipments' + equipment}
                label={equipment}
                isChecked={values.equipments.has(equipment)}
                checked={values.equipments.has(equipment)}
                onCheck={(status) => {
                  if (status) {
                    values.equipments.set(equipment, '')
                  } else {
                    values.equipments.delete(equipment)
                    values.touchedEquipments.delete(equipment)
                  }
                  setValues(values)
                }}
              />
              {values.equipments.has(equipment) && (
                <TextField
                  name={'equipments' + equipment + 'specification'}
                  placeholder="Especificação"
                  value={values.equipments.get(equipment)}
                  onChange={(e) => {
                    values.equipments.set(equipment, e.target.value)
                    setFieldValue('equipments', values.equipments)
                  }}
                  onBlur={() => {
                    values.touchedEquipments.add(equipment)
                    setFieldValue('touchedEquipments', values.touchedEquipments)
                  }}
                  error={
                    values.touchedEquipments.has(equipment) &&
                    !values.equipments.get(equipment)
                      ? 'Campo obrigatório'
                      : undefined
                  }
                />
              )}
            </S.InputContainer>
          ))}
        </S.AddEquipmentsContainer>
      </Container>
    </>
  )
}

export type AddEquipmentsFormValues = {
  equipments: Map<string, string>
  touchedEquipments: Set<string>
}

export const equipmentValidationSchema = yup.object().shape({
  equipment: yup.array().required()
})

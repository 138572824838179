import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Container } from 'presentation/shared/components/Container'
import Heading from 'presentation/shared/components/Heading'
import Button from 'presentation/shared/components/Button'
import ProfilePic from 'presentation/shared/components/ProfilePic'
import { SearchSurgicalOrders } from 'domain/usecases/surgical-order/search-surgical-orders'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import getDateFormattedByLocale from 'common/utils/getDateFormmatedByLocale'
import { DateLocales } from 'common/enum/date-locales'
import { translateSurgeryRequestStatus } from 'presentation/utils/surgery-request-status-translator'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'

import * as S from './styles'
import Header from 'presentation/shared/components/Header'
import { SurgicalOrderStatus } from 'presentation/shared/components/Timeline/status'

type Props = {
  patientInfo?: {
    name: string
    birthdayDate: string
    age: number
    doctor: string
    healthInsurance: string
    cardNumber: string
  }
  surgicalOrder: SurgicalOrderModel
  searchSurgicalOrders: SearchSurgicalOrders
} & WithLoadingProps

const RewiewSurgicalOrderList = WithLoading(
  ({ patientInfo, searchSurgicalOrders, setIsLoading }: Props) => {
    const [surgicalOrders, setSurgicalOrders] = useState<SurgicalOrderModel[]>(
      []
    )

    const history = useHistory()

    async function loadPatientSurgicalOrders() {
      try {
        setIsLoading(true)
        const fields = [
          'data{surgical_order_id, procedure {description}, hospital{name, hospital_id}' +
            'patient { name, gender, birthday, patient_id}' +
            'healthInsurance {healthInsuranceName}' +
            'doctor {name, doctor_id}' +
            'status {status}' +
            'createdAt,' +
            'surgicalCenter,' +
            'patientHospitalized,' +
            'surgicalCenter,' +
            'pendencies {documents{base64,document_id, type},status, answeredAt,observation, answer, surgical_pendency_id, createdAt, answeredAt, requestedBy {name}}}'
        ]
        if (patientInfo && patientInfo.name) {
          const params = {
            patientName: patientInfo.name
          }
          const surgicalOrders = await searchSurgicalOrders.load({
            ...params,
            params: fields
          })
          if (surgicalOrders) {
            setSurgicalOrders(surgicalOrders.data)
          }
        }
        setIsLoading(false)
      } catch (err: any) {
        setIsLoading(false)
        toast.error(err.message)
      }
    }

    useEffect(() => {
      loadPatientSurgicalOrders()
    }, [])

    return (
      <>
        <Header />
        <Container
          actualPageText="Voltar"
          actualPageOnClick={() => history.push('/home')}
        >
          <S.Wrapper>
            <S.PatientInfoContainer>
              <S.Patient>
                <ProfilePic
                  outlined
                  size="medium"
                  src="https://source.unsplash.com/random"
                />
                <div>
                  <span>Paciente</span>
                  <p>{patientInfo?.name ?? '-'}</p>
                </div>
              </S.Patient>
              <div>
                <S.PatientInfo>
                  <div>
                    <strong>Nascimento: </strong>
                    <label>{patientInfo?.birthdayDate ?? '-'}</label>
                  </div>
                  <div>
                    <strong>Idade: </strong>
                    <label>{patientInfo?.age ?? 0}</label>
                  </div>
                </S.PatientInfo>
                <S.PatientInfo>
                  <div>
                    <strong>Médico(a): </strong>
                    <label>{patientInfo?.doctor ?? '-'}</label>
                  </div>
                </S.PatientInfo>
                <S.PatientInfo>
                  <div>
                    <strong>Convênio: </strong>
                    <label>{patientInfo?.healthInsurance ?? '-'}</label>
                  </div>
                  {patientInfo?.cardNumber && (
                    <div>
                      <strong>Carteira: </strong>
                      <label>{patientInfo?.cardNumber ?? '-'}</label>
                    </div>
                  )}
                </S.PatientInfo>
              </div>
            </S.PatientInfoContainer>
            <Button
              fullWidth
              type="button"
              onClick={() => history.push('/pedido/novo')}
            >
              Novo planejamento cirúrgico
            </Button>
            <S.SurgicalOrders>
              {surgicalOrders
                .sort((val) => {
                  return val?.status?.slice(-1)?.[0].status ===
                    SurgicalOrderStatus.CANCELLED
                    ? 1
                    : -1
                })
                .map((surgicalOrder) => (
                  <S.SurgicalOrder
                    key={surgicalOrder.surgical_order_id}
                    onClick={() =>
                      history.push('/cirurgia', {
                        id: surgicalOrder.surgical_order_id
                      })
                    }
                  >
                    <S.SurgicalStatus>
                      <div>
                        <Heading
                          color="primary"
                          style={{ textTransform: 'uppercase' }}
                        >
                          Pedido Cirúrgico
                        </Heading>
                        <S.SurgicalNumber>
                          {surgicalOrder.surgical_order_id}
                        </S.SurgicalNumber>
                      </div>
                      <S.Status>
                        <p>
                          {surgicalOrder.status
                            ? translateSurgeryRequestStatus(
                                surgicalOrder.status?.slice(-1)?.[0].status
                              )
                            : ''}
                        </p>
                      </S.Status>
                    </S.SurgicalStatus>
                    <S.SurgicalInfo>
                      <div>
                        <strong>Procedimentos: </strong>
                      </div>
                      {surgicalOrder.procedure &&
                      surgicalOrder.procedure.length > 0 ? (
                        <div>
                          {surgicalOrder.procedure?.map((procedure) => (
                            <div key={procedure.code}>
                              <label>{procedure.description}</label>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p>-</p>
                      )}
                      <div>
                        <strong>Data: </strong>
                        <label>
                          {getDateFormattedByLocale(
                            surgicalOrder.createdAt,
                            DateLocales.ptBr
                          )}
                        </label>
                      </div>
                    </S.SurgicalInfo>
                  </S.SurgicalOrder>
                ))}
              {surgicalOrders.length === 0 && (
                <S.NoSurgicalOrders>
                  <Heading color="lightGray">
                    O Paciente não possui nenhum planejamento cirúrgico em curso
                  </Heading>
                </S.NoSurgicalOrders>
              )}
            </S.SurgicalOrders>
          </S.Wrapper>
        </Container>
      </>
    )
  }
)

export default RewiewSurgicalOrderList

import React, { useEffect, useState } from 'react'

import { useFormik } from 'formik'
import { toast } from 'react-toastify'

import { Container } from 'presentation/shared/components/Container'
import TextField from 'presentation/shared/components/TextField'
import Chip from 'presentation/shared/components/Chip'
import SupportText from 'presentation/shared/components/SupportText'
import Button from 'presentation/shared/components/Button'
import Carousel, {
  CarouselState
} from 'presentation/shared/components/Carousel'
import { Label } from 'presentation/shared/components/TextField/styles'
import * as S from './styles'
import TextArea from 'presentation/shared/components/TextArea'
import { UpdateSurgicalOrder } from 'domain/usecases/surgical-order/update-surgical-order'
import Modal from 'presentation/shared/components/Modal'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { UpdatePatientInfo } from 'domain/usecases/patient/update-patient-info'
import Heading from 'presentation/shared/components/Heading'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { getBloodBankPhoneNumberByHospitalId } from 'presentation/utils/get-blood-bank-by-hospital'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'

import { ReactComponent as DocumentIcon } from 'presentation/assets/icons/document-icon.svg'
import moment from 'moment'
import { getDaysLeftToDate } from '../../../../../../common/utils/getDaysLeftToDate'

type Props = {
  updateSurgicalOrder: UpdateSurgicalOrder
  surgicalOrderId: number
  initialValues?: AddMedicalRecordFormValues
  updatePatientInfo?: UpdatePatientInfo
  patientId: number
  surgicalOrder: SurgicalOrderModel
}

export default function AddMedicalRecord({
  updateSurgicalOrder,
  surgicalOrderId,
  surgicalOrder,
  initialValues = {} as AddMedicalRecordFormValues
}: Props) {
  const [carousel, setCarousel] = useState({} as CarouselState)
  const [showDocumentsModal, setShowDocumentsModal] = useState<boolean>(false)
  const history = useHistory()

  const { errors, values, handleChange, setFieldValue, submitForm } = useFormik(
    {
      initialValues: {
        ...initialValues,
        hospitalizationType: initialValues.hospitalizationType?.toUpperCase(),
        hospitalizationMode: initialValues.hospitalizationMode
          ?.toUpperCase()
          .replace('DAY HOSPITAL', 'DAY_HOSPITAL'),
        expectedDate: initialValues.expectedDate
          ? new Date(initialValues.expectedDate).toISOString().slice(0, 10)
          : ''
      },
      validationSchema,
      validateOnMount: true,
      enableReinitialize: true,
      onSubmit: async (formValues) => {
        delete formValues.patientName
        const { otherAllergies, latex, ...form } = formValues
        const allergies: string[] = []

        if (latex && allergies[0]) allergies[0] = latex
        else allergies.push(latex)

        if (otherAllergies) {
          allergies.push(formValues.otherAllergies)
        }

        try {
          if (form.documents) delete form.documents
          await updateSurgicalOrder.update({
            ...form,
            expectedDate: [values.expectedDate],
            surgical_order_id: surgicalOrderId,
            allergy: values.allergy,
            bloodTransfusion: !!form.bloodTransfusion
          })
        } catch (e: any) {
          toast.error(e.message)
        }
      }
    }
  )

  const isValid = (step: number) => {
    switch (step) {
      case 0:
        return !errors.bloodTransfusion
      case 1:
        return !errors.latex && !errors.freeze
      case 2:
        // eslint-disable-next-line no-case-declarations
        let definedExpectedDate
        if (values.hospitalizationType === 'URGENCY') {
          definedExpectedDate = !values.expectedDate
        }
        return (
          !errors.hospitalizationType &&
          !errors.hospitalizationMode &&
          !errors.patientHospitalized &&
          !errors.expectedDate &&
          !definedExpectedDate
        )

      default:
        return true
    }
  }

  const finishFavoriteOrder = () => {
    makeLocalStorageAdapter().set('favorite_doctor', {})
  }

  const finishAndRedirectToUploadOrResum = async (redirectToUpload = false) => {
    finishFavoriteOrder()
    await submitForm()
    if (redirectToUpload) {
      history.push('/pedido-anexo', {
        surgicalOrder
      })
    } else {
      history.push('/pedido-cirurgico/resumo', {
        surgicalOrder
      })
    }
  }

  return (
    <Container
      title="Ficha clínica"
      primaryButton={
        <Button
          type="button"
          fullWidth
          disabled={!isValid(carousel.activeIndex)}
          onClick={
            carousel.activeIndex === 2
              ? () => setShowDocumentsModal(true)
              : carousel.slideNext
          }
        >
          Próximo
        </Button>
      }
      secondaryButton={
        <Button
          variant="white"
          fullWidth
          onClick={
            carousel.activeIndex === 0 ? history.goBack : carousel.slidePrev
          }
        >
          Voltar
        </Button>
      }
    >
      <Carousel
        setState={setCarousel}
        state={carousel}
        dynamicHeight={true}
        touch={false}
      >
        <S.Step>
          <S.LabelContainer>
            <Label>
              Transfusão sanguínea?<span>*</span>
            </Label>
          </S.LabelContainer>
          <S.FlexContainer>
            {bloodTransfusion.map((item) => (
              <Chip
                name="bloodTransfusion"
                label={item.label}
                key={item.label}
                customValue={item.value}
                onCheck={(val) => setFieldValue('bloodTransfusion', val)}
                checked={item.value === values.bloodTransfusion}
                dataTestId={item.label}
              />
            ))}
          </S.FlexContainer>
          <SupportText color="lightGray">
            Entre em contato com o banco de sangue e solicite a reserva através
            do telefone:{' '}
            {getBloodBankPhoneNumberByHospitalId(
              surgicalOrder?.hospital?.hospital_id
            )}
          </SupportText>
        </S.Step>
        <S.Step>
          <S.LabelContainer>
            <Label>
              Congelamento?<span>*</span>
            </Label>
          </S.LabelContainer>
          <S.FlexContainer>
            {defaultOptions.map((item) => (
              <Chip
                id={item.label + 'freeze'}
                name="freeze"
                label={item.label}
                key={item.label + 'freeze'}
                customValue={item.value}
                onCheck={(val) => setFieldValue('freeze', val)}
                checked={item.value === values.freeze}
                dataTestId={item.label + 'freeze'}
              />
            ))}
          </S.FlexContainer>
          <S.LabelContainer>
            <Label>
              Paciente possui alergia a Látex?<span>*</span>
            </Label>
          </S.LabelContainer>
          <S.FlexContainer>
            {latex.map((item) => (
              <Chip
                id={item.label + 'latex'}
                name="latex"
                label={item.label}
                key={item.label + 'latex'}
                customValue={item.value}
                onCheck={(val) => setFieldValue('latex', val)}
                checked={item.value === values.latex}
                dataTestId={item.label}
              />
            ))}
          </S.FlexContainer>
          <TextArea
            label="Outras alergias"
            style={{ width: '100%', marginTop: '24px' }}
            onChange={handleChange('otherAllergies')}
            rows={8}
            defaultValue={values.allergy}
            dataTestId="other-allergies-textarea"
          />
        </S.Step>
        <S.Step>
          <S.LabelContainer>
            <Label>
              Caráter<span>*</span>
            </Label>
          </S.LabelContainer>
          <S.FlexContainer>
            {characters.map((character) => (
              <Chip
                key={character.label}
                label={character.label}
                customValue={character.value}
                name="hospitalizationType"
                onCheck={handleChange('hospitalizationType')}
                checked={character.value === values.hospitalizationType}
                dataTestId={character.value}
              />
            ))}
          </S.FlexContainer>
          <S.FlexContainer>
            <SupportText
              color="lightGray"
              style={{ marginTop: '8px', fontSize: '1rem' }}
            >
              Urgência 72 horas a partir da data do pedido
            </SupportText>
          </S.FlexContainer>

          <S.LabelContainer>
            <Label>
              {values.hospitalizationType === 'URGENCY'
                ? 'Data da Urgência'
                : 'Data prevista'}
              {values.hospitalizationType === 'URGENCY' ? <span>*</span> : null}
            </Label>
          </S.LabelContainer>
          <S.FlexContainer>
            <TextField
              style={{ width: '12rem' }}
              type="date"
              showCalendarIcon
              name="expectedDate"
              id="expectedDate"
              onChange={handleChange}
              defaultValue={values.expectedDate}
              error={errors?.expectedDate || undefined}
              required={values.hospitalizationType === 'urgency'}
              data-testid="expected-date-input"
            />
          </S.FlexContainer>
          {values.hospitalizationType === 'URGENCY' &&
            values.expectedDate &&
            !errors?.expectedDate && (
              <S.DateSuportText>
                Dias até a urgência:&nbsp;
                {values.expectedDate
                  ? getDaysLeftToDate(new Date(values.expectedDate))
                  : 0}
                &nbsp;dias
              </S.DateSuportText>
            )}

          <S.LabelContainer>
            <Label>
              Paciente internado?<span>*</span>
            </Label>
          </S.LabelContainer>
          <S.FlexContainer>
            {defaultOptions.map((item) => (
              <Chip
                id={item.label + 'patientHospitalized'}
                name="patientHospitalized"
                label={item.label}
                key={item.label + 'patientHospitalized'}
                customValue={item.value}
                onCheck={(val) => setFieldValue('patientHospitalized', val)}
                checked={item.value === values.patientHospitalized}
                dataTestId={item.label + 'patientHospitalized'}
              />
            ))}
          </S.FlexContainer>
          <S.LabelContainer>
            <Label>
              Regime hospitalar<span>*</span>
            </Label>
          </S.LabelContainer>
          <S.FlexContainer>
            {hospitalRegimes.map((item) => (
              <Chip
                label={item.label}
                key={item.label}
                customValue={item.value}
                name="hospitalizationMode"
                onCheck={handleChange('hospitalizationMode')}
                checked={item.value === values.hospitalizationMode}
                dataTestId={item.value + '-hospitalization-mode'}
              />
            ))}
          </S.FlexContainer>
          <TextArea
            label="Observações do médico"
            style={{ width: '100%', marginTop: '24px' }}
            onChange={handleChange('observation')}
            rows={8}
            value={values.observation}
            dataTestId="doctor-observation-textarea"
          />
        </S.Step>
      </Carousel>
      <Modal
        title="Deseja incluir algum documento?"
        show={showDocumentsModal}
        close={() => setShowDocumentsModal(false)}
        preventAutomateClose
        style={{
          height: 'max(400px,60%)',
          width: 'min(100%, 400px)',
          position: 'relative'
        }}
      >
        <Heading>Deseja incluir algum documento?</Heading>
        <div style={{ margin: 'auto' }}>
          <DocumentIcon />
        </div>
        <div>
          <Button
            fullWidth
            type="button"
            onClick={() => finishAndRedirectToUploadOrResum(true)}
          >
            Adicionar documentos
          </Button>
          <Button
            variant="outlined"
            fullWidth
            type="button"
            style={{ marginTop: '8px' }}
            onClick={() => finishAndRedirectToUploadOrResum()}
          >
            Autorizar pedido
          </Button>
          <S.CloseButton onClick={() => setShowDocumentsModal(false)}>
            <SupportText color="primary">Fechar</SupportText>
          </S.CloseButton>
        </div>
      </Modal>
    </Container>
  )
}

const bloodTransfusion: ChipItem[] = [
  { label: 'Não tem restrição a transfusão sanguínea', value: false },
  {
    label: 'Restrição a transfusão sanguínea',
    value: true
  }
]

const defaultOptions: ChipItem[] = [
  { label: 'Sim', value: true },
  {
    label: 'Não',
    value: false
  }
]

const characters = [
  {
    label: 'Eletivo',
    value: 'ELECTIVE'
  },
  {
    label: 'Urgência',
    value: 'URGENCY'
  }
]

const hospitalRegimes = [
  {
    label: 'Ambulatorial',
    value: 'AMBULATORY'
  },
  {
    label: 'Hospital dia',
    value: 'DAY_HOSPITAL'
  },
  {
    label: 'Hospitalar',
    value: 'HOSPITAL'
  }
]

const latex = [
  {
    label: 'Sim',
    value: 'Látex'
  },
  {
    label: 'Não',
    value: 'false'
  },
  {
    label: 'Não informado',
    value: 'latex-not-informed'
  }
]

type ChipItem = {
  label: string
  value: any
}

export type AddMedicalRecordFormValues = {
  otherAllergies: string
  hospitalizationType: string
  hospitalizationMode: string
  freeze?: boolean
  bloodTransfusion: boolean
  latex: string
  observation?: string
  patientHospitalized: boolean
  allergy: string
  patientName?: string
  documents?: []
  expectedDate?: string
}

const validationSchema = yup.object().shape({
  hospitalizationType: yup.string().required(),
  hospitalizationMode: yup.string().required(),
  freeze: yup.boolean().optional(),
  bloodTransfusion: yup.boolean().required(),
  latex: yup.string().required(),
  expectedDate: yup
    .date()
    .typeError('Data Inválida')
    .format('YYYY-MM-DD', true)
    .optional()
    .test(
      'greater_then_today',
      'A data informada não pode ser menor que a atual.',
      (value) => {
        if (value) {
          const date = moment(value, 'YYYY-MM-DD').startOf('day')
          const now = moment().startOf('day')
          if (date.isValid()) {
            return date >= now
          }
        }
        return true
      }
    )
})

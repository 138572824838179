import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  overflow-x: auto;
`

export const InputsContainer = styled.div`
  margin-top: 12px;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const ChipsContainer = styled.div`
  display: flex;
  margin-top: 12px;

  & > div + div {
    margin-left: 20px;
  }
`
export const ButtonsContainer = styled.div`
  margin-top: 36px;
`

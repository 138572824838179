import { IApiRepository } from 'service/protocols/api/api-repository'
import { SurgicalOrderGuidesRepository as IReportsRepository } from 'repository/interfaces/surgical-order-guides-repository'
import { CreateSurgicalOrderGuides } from 'domain/usecases/surgical-order/create-surgical-order-guides'
import { createSurgicalOrderGuidesMutation } from 'repository/graphql/mutations'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { RepositoryErrors } from 'repository/errors/repository-errors'

export class SurgicalOrderGuidesRepository implements IReportsRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async createSurgicalOrderGuies(
    params: CreateSurgicalOrderGuides.Params
  ): Promise<boolean> {
    const apiRepository = this
      .apiRepository as IApiRepository<CreateSurgicalOrderGuides.Model>

    const query = createSurgicalOrderGuidesMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return true
    }
  }
}

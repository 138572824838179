import { AddDoctorSurgicalOrder } from 'domain/usecases/doctor/add-doctor-surgical-order'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteAddDoctorSurgicalOrder implements AddDoctorSurgicalOrder {
  constructor(private readonly doctorRepository: DoctorRepository) {}

  add(
    params: AddDoctorSurgicalOrder.Params
  ): Promise<AddDoctorSurgicalOrder.Model> {
    return this.doctorRepository.addSurgicalOrder(params)
  }
}

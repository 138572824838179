import React from 'react'

import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

import { Container } from 'presentation/shared/components/Container'
import Button from 'presentation/shared/components/Button'
import SupportText from 'presentation/shared/components/SupportText'
import Chip from 'presentation/shared/components/Chip'
import Heading from 'presentation/shared/components/Heading'
import { UpdateSurgicalOrder } from 'domain/usecases/surgical-order/update-surgical-order'
import { WithLoadingProps } from 'presentation/shared/components/HOCs/WithLoading'

import * as S from './styles'

type Props = {
  initialValues?: HasOpmeFormValues
  surgicalOrderId: number
  updateSurgicalOrder: UpdateSurgicalOrder
  useOpme?: boolean
  submit: () => void
  setSuccessModal: () => void
} & WithLoadingProps

const HasOpme = ({
  surgicalOrderId,
  updateSurgicalOrder,
  useOpme,
  initialValues = { use_opme: useOpme ?? undefined } as HasOpmeFormValues,
  submit,
  setSuccessModal,
  setIsLoading
}: Props) => {
  const history = useHistory()

  const { handleSubmit, setFieldValue, values, errors } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        if (values.use_opme) {
          submit()
        } else {
          setIsLoading(true)
          await updateSurgicalOrder?.update({
            opme: {
              solicitations: [],
              providers: []
            },
            surgical_order_id: surgicalOrderId
          })
          setSuccessModal()
        }
      } catch (e: any) {
        toast.error(e.message)
      } finally {
        setIsLoading(false)
      }
    }
  })

  const handleOpmeSelected = (value: boolean) => {
    setFieldValue('use_opme', value)
  }
  return (
    <S.Wrapper role="form">
      <Container
        title="OPME"
        primaryButton={
          <Button
            fullWidth
            disabled={!!errors.use_opme || values.use_opme === undefined}
            type="submit"
            onClick={() => handleSubmit()}
          >
            {values.use_opme ? 'Próximo' : 'Salvar'}
          </Button>
        }
        secondaryButton={
          <Button fullWidth variant="outlined" onClick={history.goBack}>
            Cancelar
          </Button>
        }
      >
        <SupportText>
          De acordo com a Agência Nacional de Saúde Suplementar (ANS), o prazo
          de autorização é de até 21 dias úteis.
        </SupportText>
        <S.InputsContainer>
          <S.InputContainer>
            <Heading size="medium" color="gray" style={{ marginTop: '24px' }}>
              Procedimento irá precisar de OPME?
            </Heading>
            <S.ChipsContainer>
              {hasOpme.map((opme) => (
                <Chip
                  key={opme.label}
                  label={opme.label}
                  customValue={opme.value}
                  name="use_opme"
                  checked={opme.value === values.use_opme}
                  onCheck={() => handleOpmeSelected(opme.value)}
                  dataTestId={opme.label}
                  variant="square"
                />
              ))}
            </S.ChipsContainer>
          </S.InputContainer>
        </S.InputsContainer>
      </Container>
    </S.Wrapper>
  )
}

const hasOpme = [
  {
    label: 'Sim',
    value: true
  },
  {
    label: 'Não',
    value: false
  }
]

const validationSchema = yup.object().shape({
  use_opme: yup.boolean().required()
})

type HasOpmeFormValues = {
  use_opme?: boolean
}

export default HasOpme

import styled from 'styled-components'

export const Content = styled.div`
  margin-top: 40px;
`

export const CarouselItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 4px;
  overflow-y: auto;

  section {
    display: flex;
    align-items: center;
  }
`

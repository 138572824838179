import { CreateSurgicalOrderGuides } from 'domain/usecases/surgical-order/create-surgical-order-guides'
import { SurgicalOrderGuidesRepository } from 'repository/interfaces/surgical-order-guides-repository'

export class RemoteCreateSurgicalOrderGuides
  implements CreateSurgicalOrderGuides
{
  constructor(
    private readonly surgicalOrderGuidesRepository: SurgicalOrderGuidesRepository
  ) {}

  create(params: CreateSurgicalOrderGuides.Params): Promise<boolean> {
    return this.surgicalOrderGuidesRepository.createSurgicalOrderGuies(params)
  }
}

import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import TextField from 'presentation/shared/components/TextField'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'
import normalizeText from 'common/utils/getNormalizedText'
import { useServices } from 'presentation/hooks/use-services'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import * as S from './styles'
import FavoriteCard from '../FavoriteCard'
import { LoadFavoriteSurgicalOrders } from 'domain/usecases/surgical-order/load-favorite-surgical-orders'
import { FavoriteSurgicalOrder } from 'domain/entities/favorite-surgical-order-model'
import moment from 'moment-timezone'

const FavoritesTable = WithLoading(({ setIsLoading }: WithLoadingProps) => {
  const [searchFavorite, setSearchFavorite] = useState('')
  const [favorites, setFavorites] = useState<Favorite[]>([])
  const [favoritesInitial, setFavoritesInitial] =
    useState<LoadFavoriteSurgicalOrders.Model>([])
  const [favoriteList, setFavoriteList] =
    useState<LoadFavoriteSurgicalOrders.Model>([])

  const service = useServices().surgicalOrder

  const getMainProcedure = (favorite: FavoriteSurgicalOrder) => {
    return favorite.procedure.find((procedure) => procedure.isMain)
  }

  const getCid = (favorite: FavoriteSurgicalOrder) => {
    return favorite.cid.length > 0 ? favorite.cid[0] : undefined
  }

  const getFormattedFavorites = (
    favorites: LoadFavoriteSurgicalOrders.Model
  ): Favorite[] => {
    return favorites.map((favorite) => {
      const procedure = getMainProcedure(favorite)
      const cid = getCid(favorite)
      return {
        id: favorite.favorite_surgical_order_id,
        date: moment(favorite.createdAt).format('DD/MM/YYYY'),
        name: favorite.name,
        procedure: procedure ? procedure.description : '',
        procedure_code: procedure ? procedure.procedure_code : '',
        doctor_name: procedure ? procedure.doctor_name : '',
        quantity: procedure ? procedure.quantity : '',
        isMain: procedure ? procedure.isMain : '',
        cid_id: favorite.cid.length > 0 ? favorite.cid[0].code : '',
        pro_fat_id: procedure ? procedure.pro_fat_id : '',
        surgery_id: procedure ? procedure.surgery_id : '',
        cid: cid ? cid.description : '',
        cid_code: cid ? cid.code : '',
        opme: favorite.opme,
        favorite_surgical_order_id: favorite.favorite_surgical_order_id
      }
    })
  }

  useEffect(() => {
    loadFavorites()
  }, [])

  const loadFavorites = async () => {
    try {
      setIsLoading(true)
      const favorites = await service.loadFavoriteDoctorSurgicalOrders([
        'favorite_surgical_order_id',
        'name',
        'procedure {isMain,  description, procedure_code, doctor_name, quantity, isMain, pro_fat_id, surgery_id}',
        'opme {solicitations {description, quantity}, providers}',
        'cid {code, description}',
        'createdAt'
      ])
      setFavoritesInitial(favorites)
      setFavorites(getFormattedFavorites(favorites))
      setFavoriteList(favorites)
    } catch (err: any) {
      toast.error(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const standardizeText = (term: string) => {
    return term.toUpperCase()
  }

  const handleFilterFavorites = async () => {
    try {
      if (!searchFavorite) {
        await loadFavorites()
        return
      }
      setIsLoading(true)
      const filteredFavorites = favoritesInitial.filter(
        (favorite) =>
          normalizeText(favorite.name).includes(
            normalizeText(searchFavorite)
          ) ||
          favorite.procedure[0].description.includes(
            standardizeText(searchFavorite)
          ) ||
          favorite.procedure[0].procedure_code.includes(
            standardizeText(searchFavorite)
          ) ||
          favorite.cid[0].description.includes(standardizeText(searchFavorite))
      )
      setFavoriteList(filteredFavorites)
    } catch (err) {
      toast.error('Falha ao buscar favoritos')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <TextField
        name="searchFavorite"
        id="searchFavorite"
        placeholder="Tuss, nome do procedimento e CID"
        icon={
          <S.Icon onClick={() => handleFilterFavorites()}>
            <SearchIcon width="29px" height="29px" />
          </S.Icon>
        }
        iconPosition="right"
        iconLocale="outside"
        onChange={(e) => setSearchFavorite(e.target.value)}
        value={searchFavorite}
        style={{ fontSize: 11 }}
      />
      <S.Input></S.Input>
      {favorites ? (
        <FavoriteCard favorites={favorites} favoriteList={favoriteList} />
      ) : (
        <S.EmptyList>Nenhum favorito para exibir</S.EmptyList>
      )}
    </>
  )
})

type Favorite = {
  id: number
  date: string
  name: string
  procedure: string
  procedure_code: string
  cid: string
  pro_fat_id: string
  surgery_id: string | number
  cid_code: string
}

export default FavoritesTable

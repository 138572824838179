import { Ethnicity } from 'common/enum/ethnicity'

export const ethnicities = [
  {
    label: 'Branco',
    value: Ethnicity.WHITE
  },
  {
    label: 'Preto',
    value: Ethnicity.BLACK
  },
  {
    label: 'Pardo',
    value: Ethnicity.BROWN
  },
  {
    label: 'Amarelo',
    value: Ethnicity.YELLOW
  },
  {
    label: 'Indígena',
    value: Ethnicity.INDIGENOUS
  }
]

export function translateEthnicity(ethnicity: Ethnicity | string): string {
  const { label } = ethnicities.find(({ value }) => value === ethnicity) ?? {}
  return label ?? 'Desconhecido'
}

import styled, { css } from 'styled-components'

export const Wrapper = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 10px;

  .edit-button {
    color: ${({ theme }) => theme.colors.darkGreen};
    text-decoration: underline;
  }
`

export const NotificationType = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      font-weight: 400;
      padding: 20px 0px;
      color: ${theme.colors.primary};
      font-size: ${theme.font.sizes.small};
    }

    & > div {
      display: flex;
      align-items: center;

      div + div {
        margin-left: 12px;
      }
    }
  `}
`

export const Buttons = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 14px;

  button {
    margin-bottom: 14px;
  }
`

export const Content = styled.div`
  padding-bottom: 60px;
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-top: 24px;

    span {
      cursor: pointer;
      color: ${theme.colors.gray};
      font-size: ${theme.font.sizes.xsmall};
    }
  `}
`

export const ConfirmationModal = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    button {
      width: 49%;
    }
  }
`

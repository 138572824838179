import React from 'react'
import * as FormS from '../../styles'
import { Label } from 'presentation/shared/components/TextField/styles'
import SelectField from 'presentation/shared/components/SelectField'
import { useFormik, useFormikContext } from 'formik'
import * as yup from 'yup'
import { AddMedicalTeamFormValues } from '..'
import TextField from 'presentation/shared/components/TextField'
import { phoneMask } from 'presentation/utils/masks'
import Button from 'presentation/shared/components/Button'
import { MedicalTeamTypeEnum } from 'common/enum/medical-team-professional'
import { medicalTeamType } from 'presentation/utils/professional'

export function UpsertMedicalTeam() {
  const formikContext = useFormikContext<AddMedicalTeamFormValues>()

  const indexEditing = formikContext.values.editingMedicalTeamIndex
  const initialValue =
    indexEditing !== undefined
      ? formikContext.values.surgicalValues?.medicalTeam[indexEditing]
      : ({} as UpsertMedicalTeamFormValues)

  const {
    setFieldValue,
    handleChange,
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    isValid
  } = useFormik<UpsertMedicalTeamFormValues>({
    initialValues: initialValue,
    validationSchema,
    onSubmit(values) {
      const newMedicalTeam =
        formikContext.values.surgicalValues?.medicalTeam || []
      if (indexEditing !== undefined) {
        newMedicalTeam[indexEditing] = values
      } else {
        newMedicalTeam.push(values)
      }
      formikContext.setValues({
        ...formikContext.values,
        surgicalValues: {
          ...formikContext.values.surgicalValues,
          medicalTeam: newMedicalTeam
        },
        step:
          indexEditing !== undefined ? 'review-employee' : 'list-medical-team'
      })
    },
    validateOnMount: true
  })

  const gerErrorLabel = (field: string) => {
    if (touched[field] && errors[field]) {
      return errors[field]
    }
  }

  const getActionButtonLabel = () => {
    if (indexEditing !== undefined) {
      return 'Editar'
    }
    return 'Adicionar'
  }

  return (
    <>
      <FormS.LabelContainer>
        <Label>
          Profissional <span>*</span>
        </Label>
      </FormS.LabelContainer>
      <FormS.FlexContainer>
        <SelectField
          style={{ width: '100%' }}
          items={medicalTeamType}
          onInputChange={(value) => setFieldValue('type', value)}
          defaultValue={values.type || ''}
          name="type"
          onBlur={handleBlur}
          error={gerErrorLabel('type')}
        />
      </FormS.FlexContainer>
      <FormS.LabelContainer>
        <Label>
          Nome <span>*</span>
        </Label>
      </FormS.LabelContainer>
      <FormS.FlexContainer>
        <TextField
          style={{ width: '100%' }}
          autoComplete="off"
          name="name"
          id="name"
          onChange={handleChange}
          defaultValue={values.name}
          error={gerErrorLabel('name')}
          required
          data-testid="name-input"
          onBlur={handleBlur}
        />
      </FormS.FlexContainer>
      <FormS.LabelContainer>
        <Label>
          CRM <span>*</span>
        </Label>
      </FormS.LabelContainer>
      <FormS.FlexContainer>
        <TextField
          style={{ width: '100%' }}
          autoComplete="off"
          name="crm"
          id="crm"
          onChange={handleChange}
          defaultValue={values.crm}
          error={gerErrorLabel('crm')}
          required
          onBlur={handleBlur}
          data-testid="crm-input"
        />
      </FormS.FlexContainer>
      <FormS.LabelContainer>
        <Label>
          Telefone <span>*</span>
        </Label>
      </FormS.LabelContainer>
      <FormS.FlexContainer>
        <TextField
          style={{ width: '100%', marginBottom: '14px' }}
          mask={phoneMask}
          autoComplete="off"
          name="phone"
          id="phone"
          onChange={handleChange}
          defaultValue={values.phone}
          error={gerErrorLabel('phone')}
          required
          onBlur={handleBlur}
          data-testid="phone-input"
        />
      </FormS.FlexContainer>
      <Button
        type="button"
        fullWidth
        onClick={() => handleSubmit()}
        style={{ marginTop: 'auto' }}
        disabled={!isValid}
      >
        {getActionButtonLabel()}
      </Button>
    </>
  )
}

type UpsertMedicalTeamFormValues = {
  type: MedicalTeamTypeEnum
  name: string
  crm: string
  phone: string
}

const validationSchema = yup.object().shape({
  type: yup.string().required('Campo obrigatório'),
  name: yup.string().required('Campo obrigatório'),
  crm: yup.string().required('Campo obrigatório'),
  phone: yup
    .string()
    .matches(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/, 'Telefone inválido')
    .required('Campo obrigatório')
})

import React, { useEffect, useRef, useState } from 'react'

import SelectProcedureForm from 'presentation/doctor/components/Forms/AddSurgicalOrder/SelectProcedure'
import { Tuss } from 'domain/entities/tuss'
import { UpdateSurgicalOrder } from 'domain/usecases/surgical-order/update-surgical-order'
import { useHistory, useLocation } from 'react-router-dom'
import Carousel, {
  CarouselState
} from 'presentation/shared/components/Carousel'
import SelectCid from 'presentation/doctor/components/Forms/AddSurgicalOrder/SelectCid'
import AddClinicalHistory from 'presentation/doctor/components/Forms/AddSurgicalOrder/AddClinicalHistory'
import { Cid } from 'domain/entities/cid'
import { LoadCid } from 'domain/usecases/surgical-order/load-cid'
import { LoadSurgeries } from 'domain/usecases/surgery/load-surgeries'
import Modal from 'presentation/shared/components/Modal'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import Header from 'presentation/shared/components/Header'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import * as yup from 'yup'

type Props = {
  loadTuss: LoadSurgeries
  loadCid: LoadCid
  updateSurgicalOrder: UpdateSurgicalOrder
} & WithLoadingProps

type Location = Pick<
  UpdateSurgicalOrder.Params,
  'cid' | 'procedure' | 'surgical_order_id' | 'clinicalHistory'
>

function SelectProcedure({
  loadTuss,
  loadCid,
  updateSurgicalOrder,
  setIsLoading
}: Props) {
  const [tuss, setTuss] = useState<Tuss[]>([])
  const [cids, setCids] = useState<Cid[]>([])
  const [carousel, setCarousel] = useState({} as CarouselState)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const history = useHistory()

  const { state } = useLocation<Location>()
  const favoriteProcedure =
    makeLocalStorageAdapter().get('favorite_doctor') ?? []

  useEffect(() => {
    loadSurgeries()
    loadCids()
  }, [])

  async function loadSurgeries(name?: string) {
    try {
      const tuss = await loadTuss.load({
        fields: [
          'data{',
          'description',
          'surgery_id',
          'pro_fat_id',
          'tuss_id}'
        ],
        pagination: { pageSize: 15, pageNumber: 1 },
        name
      })
      setTuss(tuss.data)
    } catch (error: any) {
      return error.message
    }
  }

  async function loadCids(query?: string) {
    try {
      const cid = await loadCid.load({
        fields: ['data{', 'description', 'cid_id}'],
        pagination: { pageSize: 15, pageNumber: 1 },
        query
      })
      setCids(cid.data)
    } catch (error: any) {
      return error.message
    }
  }

  const handleFavoriteProcedure = (procedure: Procedure[]) => {
    return (
      procedure?.map((procedure: Procedure) => ({
        principalProcedure: procedure.isMain,
        tuss_id: procedure.procedure_code,
        description: procedure.description,
        doctor_name: procedure.doctor_name,
        quantity: procedure.quantity,
        surgery_id: procedure.surgery_id,
        pro_fat_id: procedure.pro_fat_id
      })) ?? []
    )
  }

  const containerTopRef = useRef<HTMLDivElement>(null)

  const scrollToTop = () => {
    containerTopRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToTop()
  }, [carousel.activeIndex])

  return (
    <>
      <Header />
      <div style={{ overflow: 'auto' }}>
        <div ref={containerTopRef}></div>

        <Carousel state={carousel} setState={setCarousel} touch={false}>
          <SelectProcedureForm
            tussList={tuss}
            updateSurgicalOrder={updateSurgicalOrder}
            initialValues={{
              tuss: state.procedure
                ? handleFavoriteProcedure(state.procedure)
                : handleFavoriteProcedure(favoriteProcedure.procedure)
            }}
            surgicalOrderId={state.surgical_order_id}
            submit={carousel.slideNext}
            loadSurgeries={loadSurgeries}
            setIsLoading={setIsLoading}
          />
          <SelectCid
            cidList={cids}
            initialValues={{
              cids: state.cid
                ? state.cid?.map((cid) => ({ ...cid, cid_id: cid.code }))
                : favoriteProcedure && Object.keys(favoriteProcedure).length > 0
                ? favoriteProcedure.cid!.map(
                    (cid: { code: string; description: string }) => ({
                      ...cid,
                      cid_id: cid.code
                    })
                  )
                : []
            }}
            updateSurgicalOrder={updateSurgicalOrder}
            surgicalOrderId={state.surgical_order_id}
            loadCids={loadCids}
            submit={carousel.slideNext}
            goBack={carousel.slidePrev}
            setIsLoading={setIsLoading}
          />
          <AddClinicalHistory
            initialValues={{
              clinicalHistory: state.clinicalHistory ?? ''
            }}
            surgicalOrderId={state.surgical_order_id}
            updateSurgicalOrder={updateSurgicalOrder}
            goBack={carousel.slidePrev}
            setSuccessModal={() => setShowSuccessModal(true)}
            setIsLoading={setIsLoading}
          />
        </Carousel>
        <Modal
          show={showSuccessModal}
          title="Procedimento salvo com sucesso!"
          close={() => history.goBack()}
        />
      </div>
    </>
  )
}

export default WithLoading(SelectProcedure)

type Procedure = {
  isMain: boolean
  procedure_code: string
  code: string
  description: string
  quantity: number
  doctor_name: string
  pro_fat_id?: string
  surgery_id?: string
}

export const procedureValidationSchema = yup.object().shape({
  procedure: yup.array().min(1),
  cid: yup.array().min(1),
  clinicalHistory: yup.string().required('Campo obrigatório')
})

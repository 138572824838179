const HospitalBloodBank = [
  {
    hospital_id: 1,
    phoneNumber: '(31) 3339-9203'
  },
  {
    hospital_id: 6,
    phoneNumber: '(31) 3401-7991'
  },
  {
    hospital_id: 7,
    phoneNumber: '(31) 3512-2420'
  }
]

export const getBloodBankPhoneNumberByHospitalId = (
  hospital_id?: number
): string => {
  if (hospital_id) {
    return (
      HospitalBloodBank.find(
        (hospitalBloodBlank) => hospitalBloodBlank.hospital_id === hospital_id
      )?.phoneNumber || ''
    )
  }
  return ''
}

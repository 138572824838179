import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  overflow-x: auto;
`

export const InputsContainer = styled.div`
  margin-top: 12px;
`

export const InputContainer = styled.div`
  position: relative;
  display: flex;

  & + & {
    margin-top: 25px;
  }

  & > div {
    margin: 0 !important;
    width: 100%;

    &:first-child {
      input {
        padding-right: 54px;
      }
    }

    &:last-child {
      max-width: 100px;
      margin-left: 18px !important;
      input {
        padding: 0 0 0 8px !important;
        text-align: center;
      }
    }
  }

  button {
    width: 54px;
    height: 54px;
    position: absolute;
    left: calc(100% - 143px);
    bottom: 0;
    border-radius: 0px 10px 10px 0px;

    path {
      fill: white;
      opacity: 1;
    }
  }

  input[type='number']::-webkit-inner-spin-button {
    width: 30px;
    height: 50px;
    padding-right: 10px;
    cursor: pointer;
  }

  @media (max-width: 1000px) {
    input[type='number']::-webkit-inner-spin-button {
      display: none;
    }
  }

  @media (max-width: 320px) {
    flex-direction: column;
    gap: 10px;
    & > div {
      &:first-child {
        input {
          padding-right: 20px;
        }
      }

      &:last-child {
        max-width: 100%;
        margin-left: 0px !important;
      }
    }
  }
`

export const ProcedureTable = styled.div`
  min-height: 20rem;
`

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 0;
`

import React, { useEffect, useState } from 'react'

import { useFormik } from 'formik'
import moment from 'moment-timezone'
import * as yup from 'yup'
import 'main/config/yup'

import { InvitePatient as InviteUseCase } from 'domain/usecases/patient/invite-patient'
import Button from 'presentation/shared/components/Button'
import SupportText from 'presentation/shared/components/SupportText'
import TextField from 'presentation/shared/components/TextField'
import { Container } from 'presentation/shared/components/Container'
import Carousel, {
  CarouselState
} from 'presentation/shared/components/Carousel'
import { cpfMask, dateMask, phoneMask } from 'presentation/utils/masks'
import SelectField from 'presentation/shared/components/SelectField'
import { Gender } from 'common/enum/gender'
import { toast } from 'react-toastify'
import { UpdatePatientHealthInsurance } from 'domain/usecases/patient/update-patient-health-insurance'
import Modal from 'presentation/shared/components/Modal'
import { User } from 'domain/entities/user-model'
import { Profile } from 'common/enum/profile'
import { useHistory } from 'react-router'
import { useStores } from 'presentation/hooks/use-stores'
import { UserStatusEnum } from 'common/enum/user-status'
import { Hospital } from 'domain/entities/hospital-model'
import { useServices } from 'presentation/hooks/use-services'
import { HealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'
import LoadingModal from '../../../../shared/components/LoadingModal'
import getLifeDaysByBirthdayDate from 'common/utils/getLifeDaysByBirthdayDate'
import RequiredIndicator from 'presentation/shared/components/RequiredIndicator'
import { MaritalStatus } from 'presentation/utils/default-marital-status'

import * as S from './styles'
import { Patient } from 'domain/entities/patient-model'

export type InviteNewBornPatientProps = {
  invitePatient?: InviteUseCase
  updateInsuranceInfo?: UpdatePatientHealthInsurance
  hospital: Hospital
}

export default function InviteNewBornPatient({
  hospital
}: InviteNewBornPatientProps) {
  const insuranceServices = useServices().healthInsurance
  const [carousel, setCarousel] = useState<CarouselState>({} as CarouselState)
  const [handleSuccessModal, setHandleSuccessModal] = useState<boolean>(false)
  const [healthInsurances, setHealthInsurances] = useState(
    [] as HealthInsurancePlans[]
  )

  const history = useHistory()
  const currentSurgery = useStores().currentSurgery

  const patientService = useServices().patient

  const formik = useFormik({
    initialValues: {} as InvitePatientFormValues,
    validateOnMount: true,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const patient = await invitePatientByBirthday(values)

        if (patient) {
          const surgery = currentSurgery.getSurgery()

          surgery.patient = patient
          currentSurgery.setSurgery(surgery)

          setHandleSuccessModal(true)

          setTimeout(() => {
            const location = {
              pathname: '/pedido/novo',
              state: { patient, step: 2 }
            }
            history.push(location)
          }, 2000)
        }
      } catch (e: any) {
        toast.error(e.message)
      }
    }
  })

  const invitePatientByBirthday = async (
    values: InvitePatientFormValues
  ): Promise<Patient> => {
    if (isPatientOlderThan30Days(formik.values.birthday)) {
      const patient = await patientService.invitePatient({
        healthInsurance: values.healthInsurance && {
          healthInsuranceName: values.healthInsurance.description,
          healthInsuranceCode: values.healthInsurance.code,
          ansRegister: values.healthInsurance.ansRegister
        },
        birthday: values.birthday,
        gender: values.gender,
        maritalStatus: MaritalStatus.SINGLE,
        user: new User({
          user_id: 0,
          name: values.name,
          cpf: values.cpf ? values.cpf.replace(/[^\w\s]/gi, '') : '',
          email: values.email,
          role: Profile.PATIENT,
          status: UserStatusEnum.PENDING,
          phone: values.phone.replace(/\D/g, '')
        })
      })
      return patient
    }
    const newBornPatient = await patientService.inviteNewBornPatient({
      healthInsurance: values.healthInsurance && {
        healthInsuranceName: values.healthInsurance.description,
        healthInsuranceCode: values.healthInsurance.code,
        ansRegister: values.healthInsurance.ansRegister
      },
      birthday: moment(values.birthday, 'DD/MM/YYYY', true)
        .tz('America/Sao_Paulo')
        .toDate(),
      gender: values.gender,
      maritalStatus: MaritalStatus.SINGLE,
      user: new User({
        user_id: 0,
        name: values.name,
        cpf: values.cpf ? values.cpf.replace(/[^\w\s]/gi, '') : '',
        email: values.email,
        role: Profile.PATIENT,
        status: UserStatusEnum.PENDING,
        phone: values.phone.replace(/\D/g, '')
      })
    })
    return newBornPatient
  }

  const isPatientOlderThan30Days = (birthday: string): boolean => {
    return getLifeDaysByBirthdayDate(birthday) > 30
  }

  const loadData = async () => {
    try {
      const insurances = await insuranceServices.loadHealthInsurancePlans({
        hospital_id: hospital.hospital_id,
        params: ['code', 'description', 'ansRegister']
      })
      setHealthInsurances(insurances)
    } catch (err) {
      toast.error('Falha ao carregar os convênios.')
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  const isValid = (step: number) => {
    switch (step) {
      case 0:
        return (
          !formik.errors.cpf &&
          !formik.errors.name &&
          !formik.errors.birthday &&
          !formik.errors.gender
        )
      case 1:
        return !formik.errors.email && !formik.errors.phone
      case 2:
        return !formik.errors.gender && !formik.errors.maritalStatus
      case 3:
        return formik.isValid || !formik.isSubmitting
      default:
        return false
    }
  }

  const patienHasMoreThan30Days = (): boolean => {
    return getLifeDaysByBirthdayDate(formik.values.birthday) > 30
  }

  return (
    <Container
      noPadding
      primaryButton={
        <Button
          fullWidth
          type={carousel.isLast ? 'submit' : 'button'}
          disabled={!isValid(carousel.activeIndex) || formik.isSubmitting}
          onClick={carousel.isLast ? formik.submitForm : carousel.slideNext}
        >
          Próximo
        </Button>
      }
      title="Paciente recém-nascido"
      subtitle="Digite nos campos abaixo os dados"
      secondaryButton={
        <SupportText
          onClick={
            carousel.activeIndex === 0
              ? () => history.goBack()
              : carousel.slidePrev
          }
          color="primary"
        >
          Cancelar
        </SupportText>
      }
      justifyContent="center"
    >
      <S.Content>
        <Carousel state={carousel} setState={setCarousel} touch={false}>
          <S.CarouselItem>
            <TextField
              label="Data de nascimento"
              name="birthday"
              id="birthday"
              onChange={formik.handleChange}
              mask={dateMask}
              required
              style={{ marginTop: '12px' }}
            />
            {patienHasMoreThan30Days() && (
              <SupportText color="lightGray" style={{ marginBottom: '12px' }}>
                Caso o recém-nascido tenha mais de 30 dias o campo CPF é
                obrigatório
              </SupportText>
            )}
            {patienHasMoreThan30Days() ? (
              <>
                <TextField
                  label="Nome do paciente"
                  name="name"
                  id="name"
                  onChange={formik.handleChange}
                  required
                  style={{ marginTop: '12px' }}
                />
                <TextField
                  label="CPF"
                  name="cpf"
                  id="cpf"
                  onChange={formik.handleChange}
                  mask={cpfMask}
                  required
                />
              </>
            ) : (
              <>
                <SupportText color="primary" style={{ marginTop: '12px' }}>
                  Nome da mãe <RequiredIndicator />
                </SupportText>
                <section>
                  <TextField
                    bgColor="mainBg"
                    placeholder="RN"
                    disabled
                    required
                    style={{
                      minWidth: '40px',
                      maxWidth: '60px',
                      marginRight: '8px'
                    }}
                    data-testid="input-rn"
                  />
                  <TextField
                    name="name"
                    id="name"
                    onChange={formik.handleChange}
                    required
                  />
                </section>
              </>
            )}
            <SelectField
              label="Sexo"
              name="gender"
              id="gender"
              onChange={formik.handleChange}
              required
              value={formik.values.gender || ''}
              items={[
                {
                  label: 'Masculino',
                  value: Gender.MALE
                },
                {
                  label: 'Feminino',
                  value: Gender.FEMALE
                }
              ]}
            />
          </S.CarouselItem>
          <S.CarouselItem>
            <TextField
              label="E-mail"
              name="email"
              id="email"
              onChange={formik.handleChange}
              required
            />
            <TextField
              label="Telefone"
              name="phone"
              id="phone"
              onChange={formik.handleChange}
              required
              style={{ marginTop: '12px' }}
              defaultValue="+55"
              mask={phoneMask}
            />
          </S.CarouselItem>
          <S.CarouselItem>
            <SelectField
              label="Convênio"
              items={healthInsurances.map((insurance) => ({
                label: insurance.description,
                value: insurance.code
              }))}
              name="healthInsurance"
              value={formik.values.healthInsurance?.code || ''}
              id="healthInsurance"
              onInputChange={(id) => {
                formik.setFieldValue(
                  'healthInsurance',
                  healthInsurances.find(
                    (insurance) => insurance.code === Number(id)
                  )
                )
              }}
            />
          </S.CarouselItem>
        </Carousel>
      </S.Content>
      <LoadingModal show={formik.isSubmitting} />
      <Modal
        close={() => setHandleSuccessModal(false)}
        title="Paciente cadastrado com sucesso!"
        show={handleSuccessModal}
      />
    </Container>
  )
}

const validationSchema = yup.object().shape({
  cpf: yup.string().when('birthday', {
    is: (birthday: Date) =>
      getLifeDaysByBirthdayDate(moment(birthday).format('DD/MM/YYYY')) > 30,
    then: yup.string().required()
  }),
  name: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup
    .string()
    .matches(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/, 'Telefone inválido')
    .required(),
  gender: yup.string().required(),
  birthday: yup.date().format('DD/MM/YYYY', true).required()
})

type InvitePatientFormValues = {
  cpf: string
  name: string
  email: string
  phone: string
  gender: string
  maritalStatus: string
  healthInsurance: HealthInsurancePlans
  birthday: string
}

import React from 'react'
import * as FormS from '../../styles'
import { Label } from 'presentation/shared/components/TextField/styles'
import { useFormik, useFormikContext } from 'formik'
import * as yup from 'yup'
import { AddMedicalTeamFormValues } from '..'
import TextField from 'presentation/shared/components/TextField'
import { phoneMask } from 'presentation/utils/masks'
import Button from 'presentation/shared/components/Button'

export function UpsertAnesthetist() {
  const formikContext = useFormikContext<AddMedicalTeamFormValues>()

  const indexEditing = formikContext.values.editingAnesthetistTeamIndex
  const initialValue =
    indexEditing !== undefined
      ? formikContext.values.surgicalValues?.anesthetistTeam[indexEditing]
      : ({} as UpsertAnesthetistTeamFormValues)

  const {
    handleChange,
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    isValid
  } = useFormik<UpsertAnesthetistTeamFormValues>({
    initialValues: initialValue,
    validationSchema,
    onSubmit(values) {
      const newAnesthetistTeam =
        formikContext.values.surgicalValues?.anesthetistTeam || []
      if (indexEditing !== undefined) {
        newAnesthetistTeam[indexEditing] = values
      } else {
        newAnesthetistTeam.push(values)
      }
      formikContext.setValues({
        ...formikContext.values,
        surgicalValues: {
          ...formikContext.values.surgicalValues,
          anesthetistTeam: newAnesthetistTeam
        },
        step:
          indexEditing !== undefined
            ? 'review-employee'
            : 'list-anesthetist-team'
      })
    },
    validateOnMount: true
  })

  const gerErrorLabel = (field: string) => {
    if (touched[field] && errors[field]) {
      return errors[field]
    }
  }

  const getActionButtonLabel = () => {
    if (indexEditing !== undefined) {
      return 'Editar'
    }
    return 'Adicionar'
  }

  return (
    <>
      <FormS.LabelContainer>
        <Label>
          Médico anestesista <span>*</span>
        </Label>
      </FormS.LabelContainer>
      <FormS.FlexContainer>
        <TextField
          style={{ width: '100%' }}
          autoComplete="off"
          name="name"
          id="name"
          onChange={handleChange}
          defaultValue={values.name}
          error={gerErrorLabel('name')}
          required
          data-testid="name-input"
          onBlur={handleBlur}
        />
      </FormS.FlexContainer>
      <FormS.LabelContainer>
        <Label>
          Tipo de anestesia <span>*</span>
        </Label>
      </FormS.LabelContainer>
      <FormS.FlexContainer>
        <TextField
          style={{ width: '100%' }}
          autoComplete="off"
          name="anesthesiaType"
          id="anesthesiaType"
          onChange={handleChange}
          defaultValue={values.anesthesiaType}
          error={gerErrorLabel('anesthesiaType')}
          required
          data-testid="anesthesiaType-input"
          onBlur={handleBlur}
        />
      </FormS.FlexContainer>
      <FormS.LabelContainer>
        <Label>
          CRM <span>*</span>
        </Label>
      </FormS.LabelContainer>
      <FormS.FlexContainer>
        <TextField
          style={{ width: '100%' }}
          autoComplete="off"
          name="crm"
          id="crm"
          onChange={handleChange}
          defaultValue={values.crm}
          error={gerErrorLabel('crm')}
          required
          onBlur={handleBlur}
          data-testid="crm-input"
        />
      </FormS.FlexContainer>
      <FormS.LabelContainer>
        <Label>
          Telefone <span>*</span>
        </Label>
      </FormS.LabelContainer>
      <FormS.FlexContainer>
        <TextField
          style={{ width: '100%', marginBottom: '14px' }}
          mask={phoneMask}
          autoComplete="off"
          name="phone"
          id="phone"
          onChange={handleChange}
          defaultValue={values.phone}
          error={gerErrorLabel('phone')}
          required
          onBlur={handleBlur}
          data-testid="phone-input"
        />
      </FormS.FlexContainer>
      <Button
        type="button"
        fullWidth
        onClick={() => handleSubmit()}
        style={{ marginTop: 'auto' }}
        disabled={!isValid}
      >
        {getActionButtonLabel()}
      </Button>
    </>
  )
}

type UpsertAnesthetistTeamFormValues = {
  anesthesiaType: string
  name: string
  crm: string
  phone: string
}

const validationSchema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  anesthesiaType: yup.string().required('Campo obrigatório'),
  crm: yup.string().required('Campo obrigatório'),
  phone: yup
    .string()
    .matches(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/, 'Telefone inválido')
    .required('Campo obrigatório')
})

import React from 'react'

import { InvitePatient as InviteUseCase } from 'domain/usecases/patient/invite-patient'
import { LoadHealthInsurance } from 'domain/usecases/health-insurance/load-health-insurance'
import { Container } from 'presentation/shared/components/Container'
import Header from 'presentation/shared/components/Header'
import InvitePatient from 'presentation/doctor/components/Forms/InvitePatient'
import { Hospital } from 'domain/entities/hospital-model'

type Props = {
  invitePatient: InviteUseCase
  loadHealthInsurance?: LoadHealthInsurance
  hospital: Hospital
}

export default function AddPatientLayout({
  invitePatient,
  loadHealthInsurance,
  hospital
}: Props) {
  return (
    <>
      <Header />
      <Container>
        <InvitePatient
          invitePatient={invitePatient}
          loadHealthInsurance={loadHealthInsurance}
          hospital={hospital}
        />
      </Container>
    </>
  )
}

import React, { useState } from 'react'

import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'

import { Cid } from 'domain/entities/cid'
import { Container } from 'presentation/shared/components/Container'
import Button from 'presentation/shared/components/Button'
import SupportText from 'presentation/shared/components/SupportText'
import { UpdateSurgicalOrder } from 'domain/usecases/surgical-order/update-surgical-order'
import AutoComplete from 'presentation/shared/components/AutoComplete'
import { ReactComponent as DeleteIcon } from 'presentation/assets/icons/trash-outline-purple.svg'
import Table from 'presentation/shared/components/Table'
import { WithLoadingProps } from 'presentation/shared/components/HOCs/WithLoading'

import * as S from './styles'

type Props = {
  cidList?: Cid[]
  initialValues?: SelectCidsFormValues
  updateSurgicalOrder?: UpdateSurgicalOrder
  surgicalOrderId: number
  loadCids: (query?: string) => Promise<void>
  submit: () => void
  goBack: () => void
} & WithLoadingProps

const SelectCid = ({
  cidList,
  initialValues = { cids: [], clinicalHistory: '' } as SelectCidsFormValues,
  updateSurgicalOrder,
  surgicalOrderId,
  loadCids,
  submit,
  goBack,
  setIsLoading
}: Props) => {
  const [actualCid, setActualCid] = useState<Cid>({} as Cid)
  const [cidQuery, setCidQuery] = useState('')

  const { values, setFieldValue, errors } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async () => submit()
  })

  const addCid = () => {
    setFieldValue('cids', [...values.cids, actualCid])
    setActualCid({} as Cid)
    setCidQuery('')
  }

  const removeCid = (procedureId: number) => {
    const newCids = values.cids.filter(
      (procedure) => procedure.cid_id !== procedureId
    )
    setFieldValue('cids', newCids)
  }

  function selectCid(actualCid: Cid) {
    const cidAlreadyLinked = values.cids.find(
      (cid) => cid.cid_id === actualCid.cid_id
    )
    if (cidAlreadyLinked) {
      toast.error('CID já adicionado')
      throw new Error('CID já adicionado')
    }
    setActualCid(actualCid)
    setCidQuery(actualCid.cid_id + ' - ' + actualCid.description)
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const formatedCid = values.cids.map((item: any) => ({
        code: item.cid_id,
        description: item.description
      }))
      await updateSurgicalOrder?.update({
        cid: formatedCid,
        surgical_order_id: surgicalOrderId
      })
      submit()
    } catch (e: any) {
      toast.error(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <S.Wrapper role="form">
        <Container
          title="CID"
          primaryButton={
            <Button
              fullWidth
              disabled={!!errors.cids || values.cids.length === 0}
              type="submit"
              onClick={() => handleSubmit()}
              data-testid="next-cid-button"
            >
              Próximo
            </Button>
          }
          secondaryButton={
            <Button
              fullWidth
              variant="outlined"
              onClick={goBack}
              data-testid="go-back-cid-button"
            >
              Voltar
            </Button>
          }
        >
          <SupportText>Adicione os CIDS referente as doenças.</SupportText>
          <S.InputsContainer>
            <S.InputContainer>
              <AutoComplete
                label={'CID'}
                placeholder="CID ou Nome da Doença"
                onSuggestionClick={(procedure) => selectCid(procedure)}
                onType={loadCids}
                onInputChange={(newValue: string) => {
                  if (actualCid.cid_id) {
                    setActualCid({} as Cid)
                    setCidQuery('')
                  } else {
                    setCidQuery(newValue)
                  }
                }}
                suggestions={cidList?.map((cid) => ({
                  label: cid.cid_id + ' - ' + cid.description,
                  value: cid
                }))}
                value={cidQuery}
                required
                style={{ width: '100%' }}
                inputBgColor="white"
                bgColor="white"
              />
            </S.InputContainer>
          </S.InputsContainer>
          <Button
            fullWidth
            style={{
              marginTop: '24px'
            }}
            onClick={addCid}
            disabled={Object.keys(actualCid).length === 0}
            data-testid="add-cid-button"
          >
            Adicionar CID
          </Button>
          <S.ProcedureTable>
            <Table
              columns={tableColumns}
              data={values.cids.map((procedure) => ({
                cid_id: procedure.cid_id,
                description: procedure.description,
                quantity: procedure.quantity,
                delete: (
                  <S.DeleteButton
                    onClick={() => removeCid(procedure.cid_id)}
                    data-testid="remove-cid-button"
                  >
                    <DeleteIcon height={20} />
                  </S.DeleteButton>
                )
              }))}
              pagination={false}
              noItemsDescription={'Busque acima e e adicione o CID'}
            />
          </S.ProcedureTable>
        </Container>
      </S.Wrapper>
    </>
  )
}

type SelectCidsFormValues = {
  cids: any[]
}

const validationSchema = yup.object().shape({
  cids: yup.array().of(
    yup.object().shape({
      cid_id: yup.string().required(),
      description: yup.string().optional()
    })
  ),
  clinicalHistory: yup.string().required()
})

const tableColumns = [
  {
    name: 'cid_id',
    label: 'CID',
    options: { filter: false, sort: false }
  },
  {
    name: 'description',
    label: 'Descrição',
    options: { filter: false, sort: false }
  },
  {
    name: 'delete',
    label: ' ',
    options: { filter: false, sort: false }
  }
]

export default SelectCid

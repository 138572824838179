import React from 'react'

import { useLocation } from 'react-router-dom'

import RewiewSurgicalOrderList from 'presentation/doctor/components/ReviewSurgicalOrderList'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'
import { RemoteSearchSurgicalOrders } from 'service/usecases/search-surgical-orders/remote-search-surgical-orders'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'

type Location = {
  patientInfo?: {
    name: string
    birthdayDate: string
    age: number
    doctor: string
    healthInsurance: string
    cardNumber: string
  }
  surgicalOrder: SurgicalOrderModel
}

export default function makeReviewSurgicalOrderList() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const surgicalOrderRepository = new SurgicalOrderRepository(apiRepository)
  const searchSurgicalOrders = new RemoteSearchSurgicalOrders(
    surgicalOrderRepository
  )
  const { state } = useLocation<Location>()
  const { patientInfo, surgicalOrder } = state || {}

  return (
    <RewiewSurgicalOrderList
      patientInfo={patientInfo}
      searchSurgicalOrders={searchSurgicalOrders}
      surgicalOrder={surgicalOrder}
    />
  )
}

import { Hospital } from 'domain/entities/hospital-model'
import { Schedule } from 'domain/entities/schedule-model'
import moment from 'moment-timezone'
import React from 'react'
import * as S from '../styles'

type AmbulatoryScheduleDetailsProps = {
  data: Schedule
}

export const AmbulatoryScheduleDetails = ({
  data
}: AmbulatoryScheduleDetailsProps) => {
  return (
    <S.ContainerData>
      <strong>PACIENTE</strong>
      <label>{data?.patientName}</label>

      <strong>CONVÊNIO</strong>
      <label>{data?.healthInsuranceName}</label>

      <strong>UNIDADE</strong>
      <label>
        {new Hospital(0, data.hospitalName ?? '', data.hospitalName ?? '')
          .name || ''}
      </label>

      <strong>SETOR</strong>
      <label>{data?.sector}</label>

      <strong>DATA DE AGENDAMENTO</strong>
      <label>
        {data?.scheduleTime &&
          moment(data.scheduleTime).utc(true).format('DD/MM/YYYY')}
      </label>

      <strong>HORÁRIO</strong>
      <label>
        {data?.scheduleTime &&
          moment(data.scheduleTime).utc(true).format('HH:mm')}
      </label>

      <strong>MÉDICO RESPONSÁVEL</strong>
      <label>{data?.doctorName}</label>

      <strong>ESPECIALIDADE</strong>
      <label>{data?.speciality}</label>

      <strong>ITEM DE AGENDAMENTO</strong>
      <label>{data?.scheduleItemDescription}</label>
    </S.ContainerData>
  )
}

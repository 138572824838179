/* eslint-disable no-empty-pattern */
import React, { useContext } from 'react'

import { ReactComponent as DeleteIcon } from 'presentation/assets/icons/trash-outline-purple.svg'
import Table from 'presentation/shared/components/Table'

import * as S from './styles'
import { AddMedicalTeamFormValues, MedicalTeamContext } from '..'
import { useFormikContext } from 'formik'
import { medicalTeamTypeDictionary } from 'presentation/utils/professional'

type TeamTableProps = {
  type: 'medicalTeam' | 'anesthetistTeam'
}

export function TeamTable({ type }: TeamTableProps) {
  const formik = useFormikContext<AddMedicalTeamFormValues>()

  const { removeItem } = useContext(MedicalTeamContext)

  const getData = () => {
    return formik.values.surgicalValues?.[type] || []
  }

  const getValueFromType = (data: any) => {
    if (type === 'medicalTeam')
      return {
        name: data.name,
        subtitle: medicalTeamTypeDictionary[data.type]
      }
    if (type === 'anesthetistTeam')
      return {
        name: data.name,
        subtitle: data.anesthesiaType
      }
  }

  const formattedData = getData().map((item: any, index: number) => {
    const value = {
      ...getValueFromType(item),
      index
    }
    return {
      display: value,
      value,
      delete: (
        <S.DeleteButton
          key={`delete-${type}-${index}`}
          onClick={(e) => {
            e.stopPropagation()
            removeItem(type, index)
          }}
          data-testid="delete-team-button"
        >
          <DeleteIcon height={20} />
        </S.DeleteButton>
      )
    }
  })

  return (
    <S.TableContainer>
      <Table
        title="Equipe médica"
        pagination={false}
        columns={columns}
        data={formattedData}
        onClick={(data) => {
          formik.setValues({
            ...formik.values,
            step: 'review-employee',
            editingMedicalTeamIndex:
              type === 'medicalTeam' ? data[0].index : undefined,
            editingAnesthetistTeamIndex:
              type === 'anesthetistTeam' ? data[0].index : undefined
          })
        }}
      />
    </S.TableContainer>
  )
}

const columns = [
  {
    name: 'value',
    label: ' ',
    options: {
      display: false
    }
  },
  {
    name: 'display',
    label: 'Profissional',
    options: {
      filter: false,
      sort: false,
      //eslint-disable-next-line
      customBodyRender: (value: any) => (
        <S.TeamCellInfo>
          <div id="team-table-name">{value.name}</div>
          <div>{value.subtitle}</div>
        </S.TeamCellInfo>
      ),
      fullWidth: true
    }
  },
  {
    name: 'delete',
    label: ' ',
    options: { filter: false, sort: false }
  }
]

import { SearchSurgicalOrders } from 'domain/usecases/surgical-order/search-surgical-orders'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteSearchSurgicalOrders implements SearchSurgicalOrders {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  load(
    params: SearchSurgicalOrders.Params
  ): Promise<SearchSurgicalOrders.Model> {
    return this.surgicalOrderRepository.searchSurgicalOrders(params)
  }
}

export type SearchSurgicalOrdersModel = SearchSurgicalOrders.Model

import React, { useContext } from 'react'
import { useFormikContext } from 'formik'
import { AddMedicalTeamFormValues, MedicalTeamContext } from '..'
import { ReactComponent as DeleteIcon } from 'presentation/assets/icons/trash-outline-purple.svg'
import Button from 'presentation/shared/components/Button'

import * as S from './styles'
import styled from 'styled-components'
import { SurgicalMedicalTeamModel } from 'domain/entities/surgical-medical-team-model'
import { SurgicalAnesthetistTeamModel } from 'domain/entities/surgical-anesthetist-team-model'
import { medicalTeamTypeDictionary } from 'presentation/utils/professional'

const DeleteIconWhite = styled(DeleteIcon)`
  margin-right: 7px;
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
`

export function ReviewEmployee() {
  const { values, setValues } = useFormikContext<AddMedicalTeamFormValues>()

  const { removeItem } = useContext(MedicalTeamContext)

  const { property, index } = (() => {
    const info: {
      property: 'anesthetistTeam' | 'medicalTeam'
      index: number
    } = {
      property: 'anesthetistTeam',
      index: 0
    }
    if (values.editingAnesthetistTeamIndex !== undefined) {
      info.index = values.editingAnesthetistTeamIndex
    }
    if (values.editingMedicalTeamIndex !== undefined) {
      info.property = 'medicalTeam'
      info.index = values.editingMedicalTeamIndex
    }
    return info
  })()

  const employee = (() => {
    const person = values.surgicalValues?.[property]?.[index]
    return {
      ...person,
      subtitle:
        medicalTeamTypeDictionary[(person as SurgicalMedicalTeamModel).type] ||
        (person as SurgicalAnesthetistTeamModel).anesthesiaType
    }
  })()

  const onRemoveItem = () => {
    removeItem(property, index)

    setValues({
      ...values,
      editingAnesthetistTeamIndex: undefined,
      editingMedicalTeamIndex: undefined,
      step:
        values.editingAnesthetistTeamIndex !== undefined
          ? 'list-anesthetist-team'
          : 'list-medical-team'
    })
  }

  const onEditItem = () => {
    const newStep =
      property === 'anesthetistTeam'
        ? 'upsert-anesthetist-team'
        : 'upsert-medical-team'

    setValues({
      ...values,
      step: newStep
    })
  }

  return (
    <>
      <S.ReviewEmployeeContainer>
        <div>
          <p>{employee.name}</p>
          <strong>{employee.subtitle}</strong>
        </div>
        <div>
          <p>CRM</p>
          <label>{employee.crm}</label>
        </div>
        <div>
          <p>Telefone</p>
          <label>{employee.phone}</label>
        </div>
      </S.ReviewEmployeeContainer>
      <Button
        type="button"
        variant="outlined"
        fullWidth
        style={{ marginTop: 'auto' }}
        onClick={onEditItem}
      >
        Editar
      </Button>
      <Button
        type="button"
        fullWidth
        onClick={onRemoveItem}
        style={{ marginTop: '0.5rem' }}
      >
        <DeleteIconWhite height={20} /> Excluir da lista
      </Button>
    </>
  )
}

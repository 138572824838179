import React from 'react'

import { useFormik } from 'formik'
import { toast } from 'react-toastify'

import { UpdateSurgicalOrder } from 'domain/usecases/surgical-order/update-surgical-order'
import Button from 'presentation/shared/components/Button'
import Chip from 'presentation/shared/components/Chip'
import { Container } from 'presentation/shared/components/Container'
import { Label } from 'presentation/shared/components/TextField/styles'
import { useHistory } from 'react-router-dom'
import * as FormS from '../styles'

import Header from 'presentation/shared/components/Header'
import TextField from 'presentation/shared/components/TextField'

import * as yup from 'yup'
import { defaultOptions } from 'presentation/utils/default-boolean-options'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'

type Props = {
  updateSurgicalOrder: UpdateSurgicalOrder
  surgicalOrderId: number
  initialValues?: SurgicalOrderModel
}

export const adaptSurgicalOrderToFormValues = (
  surgicalOrder: SurgicalOrderModel
): AddBloodBankFormValues => {
  return {
    ...surgicalOrder,
    hasCellSaver: !!surgicalOrder.cellSaverSpec,
    bloodBagQuantity: surgicalOrder.bloodBagQuantity || 0
  } as AddBloodBankFormValues
}

export default function AddBloodBank({
  updateSurgicalOrder,
  surgicalOrderId,
  initialValues = {} as SurgicalOrderModel
}: Props) {
  const history = useHistory()

  const {
    values,
    handleChange,
    submitForm,
    setFieldValue,
    isValid,
    errors,
    handleBlur,
    touched
  } = useFormik<AddBloodBankFormValues>({
    initialValues: adaptSurgicalOrderToFormValues(initialValues),
    validationSchema: bloodBankValidationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      try {
        const adaptBody: UpdateSurgicalOrder.Params = {
          surgical_order_id: surgicalOrderId,
          hasBloodType: formValues?.hasBloodType,
          hasHemoconcentrates: formValues?.hasHemoconcentrates,
          cellSaverSpec: formValues?.hasCellSaver
            ? formValues?.cellSaverSpec
            : null,
          bloodBagQuantity: formValues?.bloodBagQuantity
        }
        await updateSurgicalOrder.update(adaptBody)
        return history.goBack()
      } catch (e: any) {
        toast.error(e.message)
      }
    }
  })

  const setChipField = (fieldName: string, value: boolean | string) => {
    setFieldValue(fieldName, values[fieldName] === value ? null : value)
  }

  return (
    <>
      <Header />
      <Container
        title="Banco de sangue"
        primaryButton={
          <Button
            type="button"
            fullWidth
            disabled={!isValid}
            onClick={() => submitForm()}
          >
            Próximo
          </Button>
        }
        secondaryButton={
          <Button variant="outlined" fullWidth onClick={history.goBack}>
            Cancelar
          </Button>
        }
      >
        <FormS.LabelContainer>
          <Label>
            Tipagem sanguínea <span>*</span>
          </Label>
        </FormS.LabelContainer>
        <FormS.FlexContainer>
          {defaultOptions.map((option) => (
            <Chip
              variant="square"
              key={option.label}
              label={option.label}
              customValue={option.value}
              name="hasBloodType"
              onCheck={(val) => setChipField('hasBloodType', val)}
              checked={option.value === values.hasBloodType}
              dataTestId={option.value + 'hasBloodType'}
            />
          ))}
        </FormS.FlexContainer>

        <FormS.LabelContainer>
          <Label>
            Hemoconcentrados <span>*</span>
          </Label>
        </FormS.LabelContainer>
        <FormS.FlexContainer>
          {defaultOptions.map((option) => (
            <Chip
              variant="square"
              key={option.label}
              label={option.label}
              customValue={option.value}
              name="hasHemoconcentrates"
              onCheck={(val) => setChipField('hasHemoconcentrates', val)}
              checked={option.value === values.hasHemoconcentrates}
              dataTestId={option.value + 'hasHemoconcentrates'}
            />
          ))}
        </FormS.FlexContainer>

        <FormS.LabelContainer>
          <Label>
            Cell Saver <span>*</span>
          </Label>
        </FormS.LabelContainer>
        <FormS.FlexContainer>
          {defaultOptions.map((option) => (
            <Chip
              variant="square"
              key={option.label}
              label={option.label}
              customValue={option.value}
              name="hasCellSaver"
              onCheck={(val) => setChipField('hasCellSaver', val)}
              checked={option.value === values.hasCellSaver}
              dataTestId={option.value + 'hasCellSaver'}
            />
          ))}
        </FormS.FlexContainer>

        {values.hasCellSaver && (
          <FormS.FlexContainer>
            <TextField
              style={{ width: '100%' }}
              autoComplete="off"
              name="cellSaverSpec"
              id="cellSaverSpec"
              onChange={handleChange}
              defaultValue={values.cellSaverSpec}
              error={touched.cellSaverSpec ? errors.cellSaverSpec : undefined}
              required
              data-testid="cellSaverSpec-input"
              onBlur={handleBlur}
              placeholder="Especificação"
            />
          </FormS.FlexContainer>
        )}

        <FormS.LabelInputContainer>
          <FormS.LabelContainer>
            <Label>
              Quantidade <span>*</span>
            </Label>
          </FormS.LabelContainer>
          <TextField
            style={{ maxWidth: '25%' }}
            onChange={handleChange('bloodBagQuantity')}
            value={values.bloodBagQuantity}
            name="bloodBagQuantity"
            id="bloodBagQuantity"
            type="number"
            min={0}
          />
        </FormS.LabelInputContainer>
      </Container>
    </>
  )
}

export type AddBloodBankFormValues = {
  hasBloodType?: boolean
  hasHemoconcentrates?: boolean
  hasCellSaver?: boolean
  cellSaverSpec?: string
  bloodBagQuantity?: number
}

export const bloodBankValidationSchema = yup.object().shape({
  hasBloodType: yup.boolean().required(),
  hasHemoconcentrates: yup.boolean().required(),
  hasCellSaver: yup.boolean().required(),
  cellSaverSpec: yup
    .string()
    .nullable()
    .optional()
    .notRequired()
    .when('hasCellSaver', {
      is: true,
      then: yup.string().required()
    }),
  bloodBagQuantity: yup.number().required()
})

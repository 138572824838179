import { LoadDoctorSurgeryRequestCount } from 'domain/usecases/doctor/load-doctor-surgery-request-count'
import { LoadDoctorSurgicalOrders } from 'domain/usecases/doctor/load-doctor-surgical-orders'
import { observer } from 'mobx-react'
import DoctorHomeLayout from 'presentation/doctor/layouts/Home'
import { useServices } from 'presentation/hooks/use-services'
import { useStores } from 'presentation/hooks/use-stores'
import React, { useEffect, useState } from 'react'

type DoctorHomeProps = {
  loadSurgeryDoctorRequestCount: LoadDoctorSurgeryRequestCount
}

export const DoctorHome = observer(
  ({ loadSurgeryDoctorRequestCount }: DoctorHomeProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const account = useStores().currentAccount
    const [surgeryRequests, setSurgeryRequests] =
      useState<LoadDoctorSurgeryRequestCount.Model>()
    const [pendenciesUnresolved, setPendenciesUnresolved] = useState<number>(0)
    const [surgicalOrders, setSurgicalOrders] =
      useState<LoadDoctorSurgicalOrders.Model>()
    const service = useServices().doctor

    useEffect(() => {
      async function loadData() {
        try {
          setIsLoading(true)
          const doctorPendencies = await service.loadDoctorPendencies({
            query: {
              unresolved: true
            },
            pagination: {
              pageSize: 1,
              pageNumber: 1
            },
            params: [
              `pageInfo {
                currentPage
                itemsPerPage
                totalItems
                totalPages
              }`
            ]
          })
          setPendenciesUnresolved(
            doctorPendencies?.pendencies?.pageInfo?.totalItems || 0
          )
          const response = await loadSurgeryDoctorRequestCount.count()
          setSurgeryRequests(response)

          const surgicalOrder = await service.loadDoctorSurgicalOrders({
            pagination: {
              pageSize: 5,
              pageNumber: 1
            }
          })
          setSurgicalOrders(surgicalOrder)
        } catch (err) {
          return
        } finally {
          setIsLoading(false)
        }
      }
      loadData()
    }, [])

    return (
      <>
        <DoctorHomeLayout
          doctorSchedule={surgeryRequests}
          name={account.getCurrentAccount().user.name}
          pendenciesUnresolved={pendenciesUnresolved}
          unfinishedOrders={surgeryRequests?.unfinished || 0}
          surgicalOrders={surgicalOrders}
          isLoading={isLoading}
        />
      </>
    )
  }
)

export default DoctorHome

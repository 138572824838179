import styled from 'styled-components'

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 0;

  svg {
    path {
      fill: ${({ theme }) => theme.colors.lightGray};
    }
  }
`

export const TableContainer = styled.div`
  .MuiTableHead-root,
  .MuiTableCell-head {
    z-index: 1;
    height: 32px !important;
    padding: 0 0 0 14px !important;
    text-align: left !important;
  }
`

export const TeamCellInfo = styled.div`
  #team-table-name {
    font-weight: ${({ theme }) => theme.font.semiBold} !important;
  }
  div {
    color: ${({ theme }) => theme.colors.gray} !important;
    font-size: ${({ theme }) => theme.font.sizes.medium} !important;
    font-weight: ${({ theme }) => theme.font.normal} !important;
  }
  display: flex;
  flex-direction: column;
  width: 165px;
  padding: 0px;
  text-align: left;

  text-overflow: ellipsis;
  overflow: hidden;

  div:first-child {
    margin-bottom: 10px;
    font-weight: ${({ theme }) => theme.font.normal} !important;
  }
`

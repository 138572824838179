import React, { useEffect, useState } from 'react'

import UpdateDoctorPersonalInfoLayout from 'presentation/doctor/layouts/UpdatePersonalInfo'
import { LoadDoctor } from 'domain/usecases/doctor/load-doctor'
import { Doctor } from 'domain/entities/doctor-model'

type Props = {
  loadDoctor: LoadDoctor
}
export default function UpdateDoctorPersonalInfo({ loadDoctor }: Props) {
  const [data, setData] = useState<Doctor>({} as Doctor)
  const getUserInfo = async () => {
    const doctor = await loadDoctor.load(['name', 'lastName', 'cpf', 'gender'])
    setData(doctor)
  }
  useEffect(() => {
    getUserInfo()
  }, [])

  if (!data.name) return <></>
  return <UpdateDoctorPersonalInfoLayout data={data} />
}

import React from 'react'

import { useHistory } from 'react-router-dom'

import Button from 'presentation/shared/components/Button'
import { Hospital } from 'domain/entities/hospital-model'

import * as S from './styles'

type NewPatientModalProps = {
  hospital: Hospital
}

const NewPatientModal = ({ hospital }: NewPatientModalProps) => {
  const history = useHistory()

  return (
    <S.Wrapper>
      <S.Title>Novo paciente</S.Title>
      <S.Complement>
        <strong>Qual paciente</strong> será atendido?
      </S.Complement>
      <S.Buttons>
        <Button
          onClick={() =>
            history.push('/paciente/save', {
              hospital: hospital
            })
          }
        >
          Paciente
        </Button>
        <Button
          onClick={() =>
            history.push('/paciente/recem-nascido/save', {
              hospital: hospital
            })
          }
          variant="outlined"
        >
          Paciente recém-nascido
        </Button>
      </S.Buttons>
    </S.Wrapper>
  )
}

export default NewPatientModal

import React, { useEffect, useState, useRef } from 'react'

import moment from 'moment-timezone'
import { toast } from 'react-toastify'
import 'moment/locale/pt-br'

import Calendar from 'presentation/shared/components/Calendar'
import { useServices } from 'presentation/hooks/use-services'
import LoadingGif from 'presentation/assets/icons/loading.gif'
import * as S from './styles'
import { DoctorScheduleCard } from './DoctorScheduleCard'
import { DoctorSchedule } from 'domain/usecases/doctor/load-doctor-schedule'

export const DoctorScheduleCalender = () => {
  const firstRender = useRef<boolean>(true)
  const [selectedDay, setSelectedDay] = useState<string>(
    moment().format('DD-MM-YYYY')
  )
  const [schedule, setSchedule] = useState<DoctorSchedule>()
  const [loading, setLoading] = useState<boolean>(false)

  const service = useServices().doctor

  // useEffect(() => {
  //   loadData()
  // }, [])

  // async function loadData(date?: string) {
  //   try {
  //     setLoading(true)
  //     const formattedObject = await service.loadDoctorSchedule({
  //       date: date ? date : moment().toISOString()
  //     })
  //     setSchedule(formattedObject)
  //   } catch (error) {
  //     setSchedule({} as DoctorSchedule)
  //     toast.error(error.message)
  //   } finally {
  //     setLoading(false)
  //     firstRender.current = false
  //   }
  // }

  // useEffect(() => {
  //   ;(async () => {
  //     if (selectedDay && schedule) {
  //       await loadData(moment(selectedDay, 'DD-MM-YYYY').toISOString())
  //     }
  //     if (!firstRender.current) {
  //       document?.body
  //         ?.querySelector('main>div')
  //         ?.scrollTo?.(0, document.body.scrollHeight)
  //     }
  //   })()
  // }, [selectedDay])

  return (
    <S.Wrapper>
      <Calendar
        formatLongDate={(date) => date}
        onChange={(val: any) => {
          setSelectedDay(moment(val).format('DD-MM-YYYY'))
        }}
      />
      <S.DateText>
        {moment(selectedDay, 'DD-MM-YYYY')
          .locale('pt-BR')
          .format('DD [de] MMMM [de] YYYY')}
      </S.DateText>
      {Object.entries(schedule ?? {})
        ?.sort((a, b) => (a?.[0] > b?.[0] ? 1 : -1))
        ?.map((val) => {
          return (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p
                  style={{
                    padding: '3px 0',
                    color: '#6A6A6A',
                    fontWeight: 500
                  }}
                >
                  {val?.[0]}
                </p>
                <hr
                  style={{ width: '100%', height: '1px', marginLeft: '10px' }}
                />
              </div>
              {Array.isArray(val?.[1]) &&
                val?.[1]?.map((schedule, index) => (
                  <DoctorScheduleCard
                    data={schedule.data}
                    type={schedule.type}
                    date={schedule.date}
                    key={index}
                  />
                ))}
            </>
          )
        })}
      {loading && (
        <S.Loading>
          <img src={LoadingGif} />
          <span>Carregando agenda</span>
        </S.Loading>
      )}
    </S.Wrapper>
  )
}

import React from 'react'

import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'
import { RemoteLoadPatientByCpf } from 'service/usecases/load-patient-by-cpf/remote-load-patient-by-cpf'
import { CreateSurgicalOrder } from 'presentation/doctor/pages/AddSurgicalOrder/CreateSurgicalOrder'
import { HospitalRepository } from 'repository/repositories/hospital/hospital-repository'
import { RemoteLoadHospitals } from 'service/usecases/load-hospitals/remote-load-hospitals'
import { SurgeryCenterRepository } from 'repository/repositories/surgery-center/surgery-center-repository'
import { RemoteLoadSurgeryCenter } from 'service/usecases/load-surgery-center/remote-load-surgery-center'
import { RemoteAddDoctorSurgicalOrder } from 'service/usecases/add-doctor-surgical-order/remote-add-doctor-surgical-order'
import { DoctorRepository } from 'repository/repositories/doctor/doctor-repository'
import { RemoteLoadHealthInsurancePlans } from 'service/usecases/load-health-insurance-plans/remote-load-health-insurance-plans'
import { HealthInsuranceRepository } from 'repository/repositories/health-insurance/health-insurance-repository'
import { RemoteUploadSurgicalOrderDocument } from 'service/usecases/upload-surgical-order-document/remote-upload-surgical-order-document'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'
import { RemoteCreateSurgicalOrderGuides } from 'service/usecases/create-surgical-order-guides/remote-create-surgical-order-guides'
import { SurgicalOrderGuidesRepository } from 'repository/repositories/surgical-order-guides/surgical-order-guides-repository'

export default function makeDoctorCreateSurgicalOrder() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )

  const patientRepository = new PatientRepository(apiRepository)
  const hospitalRepository = new HospitalRepository(apiRepository)
  const surgeryCenterRepository = new SurgeryCenterRepository(apiRepository)
  const doctorRepository = new DoctorRepository(apiRepository)
  const healthInsuranceRepository = new HealthInsuranceRepository(apiRepository)
  const surgicalOrderRepository = new SurgicalOrderRepository(apiRepository)

  const loadPatientByCpf = new RemoteLoadPatientByCpf(patientRepository)
  const loadHospitals = new RemoteLoadHospitals(hospitalRepository)
  const loadSurgeryCenters = new RemoteLoadSurgeryCenter(
    surgeryCenterRepository
  )
  const addDoctorSurgicalOrder = new RemoteAddDoctorSurgicalOrder(
    doctorRepository
  )
  const loadInsurances = new RemoteLoadHealthInsurancePlans(
    healthInsuranceRepository
  )
  const uploadSurgicalOrderDocument = new RemoteUploadSurgicalOrderDocument(
    surgicalOrderRepository
  )
  const surgicalOrderGuidesRepository = new SurgicalOrderGuidesRepository(
    apiRepository
  )
  const createSurgicalOrderGuides = new RemoteCreateSurgicalOrderGuides(
    surgicalOrderGuidesRepository
  )

  return (
    <CreateSurgicalOrder
      findPatient={loadPatientByCpf}
      loadHospitals={loadHospitals}
      loadSurgeryCenter={loadSurgeryCenters}
      addDoctorSurgicalOrder={addDoctorSurgicalOrder}
      loadInsurances={loadInsurances}
      uploadSurgicalOrderDocument={uploadSurgicalOrderDocument}
      createSurgicalOrderGuides={createSurgicalOrderGuides}
    />
  )
}

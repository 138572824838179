import React from 'react'
import ReactDOM from 'react-dom'
import 'reflect-metadata/Reflect.js'
import DoctorRouter from 'main/routes/doctor-routes'
import { ThemeProvider } from 'styled-components'
import { ToastContainer } from 'react-toastify'
import { QueryClientProvider } from 'react-query'

import theme from 'presentation/styles/theme'
import GlobalStyles from 'presentation/styles/global'

import 'react-toastify/dist/ReactToastify.min.css'
import ServiceWorkerWrapper from 'presentation/shared/components/PWAContainer'
import { queryClient } from 'main/config/react-query'

ReactDOM.render(
  <React.StrictMode>
    <ServiceWorkerWrapper>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <GlobalStyles mobile />
          <DoctorRouter />
          <ToastContainer />
        </ThemeProvider>
      </QueryClientProvider>
    </ServiceWorkerWrapper>
  </React.StrictMode>,
  document.getElementById('root')
)

export type ChipItem = {
  label: string
  value: any
}

export const defaultOptions: ChipItem[] = [
  { label: 'Sim', value: true },
  {
    label: 'Não',
    value: false
  }
]

import React, { useState } from 'react'

import { toast } from 'react-toastify'
import { useFormik, useFormikContext } from 'formik'

import { cpfMask, dateMask } from 'presentation/utils/masks'
import { Container } from 'presentation/shared/components/Container'
import { LoadPatientByCpf } from 'domain/usecases/patient/load-patient-by-cpf'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'
import TextButton from 'presentation/shared/components/TextButton'
import TextField from 'presentation/shared/components/TextField'
import Button from 'presentation/shared/components/Button'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { AddDoctorSurgicalOrderFormValues } from 'presentation/doctor/pages/AddSurgicalOrder'
import FindPatientSheet from 'presentation/doctor/components/FindPatientSheet'
import { useServices } from 'presentation/hooks/use-services'
import moment from 'moment-timezone'
import { Patient } from 'domain/entities/patient-model'
import { isIsoDate } from 'common/utils/verify-iso-date'
import SheetModal from 'presentation/shared/components/SheetModal'
import NewPatientModal from './NewPatientModal'

import * as S from './styles'

type Props = {
  goBack: () => void
  findPatient?: LoadPatientByCpf
  submit: () => void
}

export default function SelectPatient({ findPatient, submit, goBack }: Props) {
  const [searchType, setSearchType] = useState<FormTypes>('cpf')
  const [isOpenNewPatientModal, setIsOpenNewPatientModal] = useState(false)

  const { errors, values } =
    useFormikContext<AddDoctorSurgicalOrderFormValues>()

  const content: Record<FormTypes, ContentTypes> = {
    cpf: {
      subtitle: 'Digite o CPF do paciente',
      headingButton: 'cpf',
      search: 'Busca CPF',
      component: <CpfSearch findPatient={findPatient} required={true} />
    },
    advanced: {
      subtitle: 'Digite o nome e a data de nascimento do paciente',
      headingButton: 'advanced',
      search: 'Busca Nome',
      component: <AdvancedSearch />
    }
  }

  return (
    <>
      <Container
        actualPageText="Voltar"
        actualPageOnClick={goBack}
        primaryButton={
          <Button
            type="button"
            fullWidth
            onClick={submit}
            disabled={!!errors.patient}
          >
            Confirmar
          </Button>
        }
        title="Buscar paciente"
        subtitle={content[searchType].subtitle}
        headingButton={
          <TextButton
            style={{ fontWeight: 500, fontSize: '16px' }}
            onClick={() =>
              searchType !== 'cpf'
                ? setSearchType('cpf')
                : setSearchType('advanced')
            }
          >
            {content[searchType].headingButton !== 'cpf'
              ? 'Buscar por CPF'
              : 'Buscar por Nome'}
          </TextButton>
        }
        // secondaryButton={
        //   <Button
        //     fullWidth
        //     onClick={() => setIsOpenNewPatientModal(true)}
        //     variant={'outlined'}
        //     style={{
        //       background: 'none',
        //       marginTop: '10px'
        //     }}
        //   >
        //     Novo paciente
        //   </Button>
        // }
      >
        <S.Content>{content[searchType].component}</S.Content>
        <SheetModal
          isOpen={isOpenNewPatientModal}
          onClose={() => setIsOpenNewPatientModal(false)}
          size={400}
        >
          <NewPatientModal hospital={values.hospital} />
        </SheetModal>
      </Container>
    </>
  )
}

type ChildProps = {
  findPatient?: LoadPatientByCpf
  required?: boolean
} & WithLoadingProps

const CpfSearch = WithLoading(
  ({ findPatient, setIsLoading, required }: ChildProps) => {
    const { values, setFieldValue } =
      useFormikContext<AddDoctorSurgicalOrderFormValues>()

    const formik = useFormik({
      initialValues: {
        cpf: values.patient?.cpf
      },
      onSubmit: async () => null
    })

    const isNewBornPatient = (): boolean => {
      return values.patient?.name.startsWith('RN')
    }

    const searchPatient = async () => {
      setIsLoading(true)
      try {
        const cpf = formik.values.cpf.replace(/\D+/g, '')
        const patient = await findPatient?.load(cpf, [
          'patient_id',
          'birthday',
          'name',
          'email',
          'gender',
          'phone',
          'weight',
          'healthInsurance {',
          'healthInsuranceName',
          'healthInsuranceCode',
          '}'
        ])

        if (!patient) {
          await setFieldValue('patient', undefined, true)
          toast.error('Paciente não encontrado para o cpf fornecido')
          return
        }
        await setFieldValue('patient', { ...patient, cpf }, true)
        await setFieldValue('healthInsurance', patient?.healthInsurance)
      } catch (e) {
        await setFieldValue('patient', undefined, true)
        toast.error('Ocorreu um erro ao buscar um paciente')
      } finally {
        setIsLoading(false)
      }
    }

    return (
      <>
        <S.InputButtonContainer>
          <TextField
            label="CPF"
            style={{ width: '100%' }}
            mask={cpfMask}
            autoComplete="off"
            placeholder="000.000.000-00"
            name="cpf"
            id="cpf"
            defaultValue={formik.values.cpf || values.patient?.cpf || ''}
            onChange={formik.handleChange}
            required={required}
            disabled={isNewBornPatient()}
          />
          <Button
            onClick={searchPatient}
            disabled={!formik.values.cpf || isNewBornPatient()}
          >
            <SearchIcon width="20px" height="20px" viewBox="0 0 18 18" />
          </Button>
        </S.InputButtonContainer>
        <TextField
          label="Nome do paciente"
          value={values.patient?.name || ''}
          disabled
          data-testid="disabled-patient-name-input"
        />
      </>
    )
  }
)

const AdvancedSearch = WithLoading(({ setIsLoading }: WithLoadingProps) => {
  const [showModal, setShowModal] = useState(false)
  const [patients, setPatients] = useState<Patient[]>([])

  const patientService = useServices().patient

  const { values, setFieldValue } =
    useFormikContext<AddDoctorSurgicalOrderFormValues>()

  const formik = useFormik({
    initialValues: {
      name: values.patient?.name,
      birthDate: values.patient?.birthday
    },
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        const patients = await patientService.searchPatient({
          name: values.name,
          bday: isIsoDate(values.birthDate)
            ? values.birthDate
            : moment(values.birthDate, 'DD/MM/YYYY').toISOString(),
          pagination: {
            pageNumber: 1,
            pageSize: 50
          },
          params: [
            'data {',
            'patient_id',
            'birthday',
            'cpf',
            'name',
            'email',
            'gender',
            'phone',
            'weight',
            'documentHealthInsurance {',
            'name',
            'type',
            'document_id}',
            'healthInsurance {',
            'healthInsuranceName',
            'healthInsuranceCode',
            '}}'
          ]
        })
        if (!patients.data.length) {
          await setFieldValue('patient', undefined, true)
          toast.error('Nenhum paciente encontrado')
          return
        } else {
          setPatients(patients.data)
          setShowModal(true)
        }
      } catch (e: any) {
        toast.error(e.message)
      } finally {
        setIsLoading(false)
      }
    }
  })

  const selectPatient = async (patient: Patient) => {
    await setFieldValue('patient', { ...patient, cpf: patient.cpf })
    await formik.setFieldValue('name', patient.name)
    await formik.setFieldValue('birthday', patient.birthday)
    await setFieldValue('healthInsurance', patient?.healthInsurance)
    setShowModal(false)
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <S.InputButtonContainer>
        <TextField
          label="Nome"
          style={{ width: '100%' }}
          placeholder="Nome do paciente"
          onChange={formik.handleChange}
          name="name"
          value={formik.values.name || values.patient?.name || ''}
          required
        />
        <Button
          type="submit"
          disabled={!formik.values.birthDate || !formik.values.name}
        >
          <SearchIcon width="20px" height="20px" viewBox="0 0 18 18" />
        </Button>
      </S.InputButtonContainer>
      <TextField
        label="Data de nascimento"
        placeholder={'00/00/0000'}
        mask={dateMask}
        onChange={formik.handleChange}
        name="birthDate"
        defaultValue={
          (values.patient?.birthday &&
            moment(values.patient.birthday).utc(true).format('DD/MM/YYYY')) ||
          ''
        }
        required
      />
      <FindPatientSheet
        close={() => setShowModal(false)}
        show={showModal}
        patients={patients}
        confirmPatient={selectPatient}
      />
    </form>
  )
})

type FormTypes = 'cpf' | 'advanced'

type ContentTypes = {
  subtitle: string
  headingButton: FormTypes
  search: string
  component: React.ReactElement
}

import React, { useEffect, useRef, useState } from 'react'

import { useLocation, useHistory } from 'react-router-dom'
import Carousel, {
  CarouselState
} from 'presentation/shared/components/Carousel'

import SelectOpmeForm from 'presentation/doctor/components/Forms/AddSurgicalOrder/SelectOpme'
import { UpdateSurgicalOrder } from 'domain/usecases/surgical-order/update-surgical-order'
import HasOpme from 'presentation/doctor/components/Forms/AddSurgicalOrder/SelectOpme/HasOpme'
import SelectProviders from 'presentation/doctor/components/Forms/AddSurgicalOrder/SelectOpme/SelectProviders'
import Modal from 'presentation/shared/components/Modal'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import Header from 'presentation/shared/components/Header'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import * as yup from 'yup'

type Props = {
  updateSurgicalOrder: UpdateSurgicalOrder
} & WithLoadingProps

type Location = Pick<UpdateSurgicalOrder.Params, 'opme' | 'surgical_order_id'>

export type Opme = {
  description: string
  quantity: number
  opme_id: number
}

type OpmeList = {
  solicitations: {
    description: string
    quantity: number
    opme_id: number
  }[]
}

function SelectOpme({ updateSurgicalOrder, setIsLoading }: Props) {
  const [carousel, setCarousel] = useState({} as CarouselState)
  const [selectedOpmes, setSelectedOpmes] = useState<Opme[]>([])
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const { state } = useLocation<Location>()

  const history = useHistory()
  const hasFavoriteOpme = makeLocalStorageAdapter().get('favorite_doctor')

  const handleUseOpme = (opme?: OpmeList) => {
    if (hasFavoriteOpme && Object.keys(hasFavoriteOpme).length > 0) {
      return !!hasFavoriteOpme.opme.solicitations.length
    }
    if (opme && opme.solicitations) {
      return !!opme && opme.solicitations.length > 0
    }
  }

  const handleOpme = (opme: OpmeList) => {
    if (hasFavoriteOpme && hasFavoriteOpme?.opme?.solicitations?.length > 0) {
      return hasFavoriteOpme.opme.solicitations
    }
    return opme?.solicitations ?? []
  }
  const provider1 = hasFavoriteOpme?.opme?.providers?.[0]
    ? hasFavoriteOpme.opme.providers[0]
    : state?.opme?.providers?.[0] || ''
  const provider2 = hasFavoriteOpme?.opme?.providers?.[1]
    ? hasFavoriteOpme.opme.providers[1]
    : state?.opme?.providers?.[1] || ''
  const provider3 = hasFavoriteOpme?.opme?.providers?.[2]
    ? hasFavoriteOpme.opme.providers[2]
    : state?.opme?.providers?.[2] || ''

  const handleSelectOpme = (opmes: Opme[]) => {
    setSelectedOpmes(opmes)
    carousel.slideNext()
  }

  const containerTopRef = useRef<HTMLDivElement>(null)

  const scrollToTop = () => {
    containerTopRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToTop()
  }, [carousel.activeIndex])

  return (
    <>
      <Header />
      <div style={{ overflow: 'auto' }}>
        <div ref={containerTopRef}></div>
        <Carousel state={carousel} setState={setCarousel} touch={false}>
          <HasOpme
            surgicalOrderId={state?.surgical_order_id}
            useOpme={handleUseOpme(state.opme)}
            submit={carousel.slideNext}
            updateSurgicalOrder={updateSurgicalOrder}
            setSuccessModal={() => setShowSuccessModal(true)}
            setIsLoading={setIsLoading}
          />
          <SelectOpmeForm
            updateSurgicalOrder={updateSurgicalOrder}
            surgicalOrderId={state?.surgical_order_id}
            initialValues={{
              opmes: handleOpme(state.opme!)
            }}
            submit={carousel.slideNext}
            goBack={carousel.slidePrev}
            submitForm={(opmes) => handleSelectOpme(opmes)}
          />

          <SelectProviders
            initialValues={{
              opmes:
                state.opme?.solicitations &&
                  state.opme?.solicitations.length > 0
                  ? handleOpme(state.opme)
                  : selectedOpmes,
              provider1,
              provider2,
              provider3
            }}
            values={{
              opmes:
                state.opme?.solicitations &&
                  state.opme?.solicitations.length > 0
                  ? handleOpme(state.opme)
                  : selectedOpmes,
              provider1,
              provider2,
              provider3
            }}
            surgicalOrderId={state?.surgical_order_id}
            updateSurgicalOrder={updateSurgicalOrder}
            goBack={carousel.slidePrev}
            setSuccessModal={() => setShowSuccessModal(true)}
            setIsLoading={setIsLoading}
          />
        </Carousel>
        <Modal
          show={showSuccessModal}
          title="Dados salvo com sucesso!"
          close={() => history.goBack()}
        />
      </div>
    </>
  )
}

export default WithLoading(SelectOpme)

export const opmeValidationSchema = yup.object().shape({
  opme: yup.object().required()
})

import React from 'react'

import { useLocation } from 'react-router-dom'

import AddMedicalRecord, {
  AddMedicalRecordFormValues
} from 'presentation/doctor/components/Forms/AddSurgicalOrder/AddMedicalRecord'
import { UpdateSurgicalOrder } from 'domain/usecases/surgical-order/update-surgical-order'
import { UpdatePatientInfo } from 'domain/usecases/patient/update-patient-info'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'

type Props = {
  updateSurgicalOrder: UpdateSurgicalOrder
  updatePatientInfo: UpdatePatientInfo
}

type Location = Required<AddMedicalRecordFormValues> & {
  surgical_order_id: number
  patient_id: number
  surgicalOrder: SurgicalOrderModel
}

export default function CreateMedicalRecord({
  updateSurgicalOrder,
  updatePatientInfo
}: Props) {
  const { state } = useLocation<Location>()

  const { surgical_order_id, patient_id, surgicalOrder, ...rest } =
    state || ({} as unknown as Location)
  return (
    <AddMedicalRecord
      updateSurgicalOrder={updateSurgicalOrder}
      surgicalOrderId={surgical_order_id}
      initialValues={rest}
      updatePatientInfo={updatePatientInfo}
      patientId={patient_id}
      surgicalOrder={surgicalOrder}
    />
  )
}

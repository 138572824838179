import React from 'react'

import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'
import { RemoteInvitePatient } from 'service/usecases/invite-patient/remote-invite-patient'
import InvitePatient from 'presentation/doctor/pages/AddPatient/index'
import { HealthInsuranceRepository } from 'repository/repositories/health-insurance/health-insurance-repository'
import { RemoteLoadHealthInsurance } from 'service/usecases/load-health-insurance/remote-load-health-insurance'

export default function makeDoctorAddPatient() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const patientRepository = new PatientRepository(apiRepository)
  const healthInsuranceRepository = new HealthInsuranceRepository(apiRepository)

  const invitePatient = new RemoteInvitePatient(patientRepository)
  const loadInsurances = new RemoteLoadHealthInsurance(
    healthInsuranceRepository
  )

  return (
    <InvitePatient
      invitePatient={invitePatient}
      loadHealthInsurance={loadInsurances}
    />
  )
}

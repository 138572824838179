import { LoadDoctorSurgeryRequestCount } from 'domain/usecases/doctor/load-doctor-surgery-request-count'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteDoctorLoadSurgeryRequestCount
  implements LoadDoctorSurgeryRequestCount
{
  constructor(private readonly doctorRepository: DoctorRepository) {}

  async count(): Promise<LoadDoctorSurgeryRequestCount.Model> {
    return await this.doctorRepository.countSurgeryRequests()
  }
}

export type RemoteDoctorLoadSurgeryRequestCountModel =
  LoadDoctorSurgeryRequestCount.Model

import styled from 'styled-components'

export const InputButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 12px;

  button {
    width: 85px;
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 10px;
    margin-left: 10px;

    span {
      width: 20px;
      height: 20px;
    }

    svg {
      height: 20px;
      width: 20px;

      path {
        height: 20px;
        width: 20px;
        fill: white;
        opacity: 1;
      }
    }
  }
`

export const Content = styled.div`
  margin: auto 0;
`

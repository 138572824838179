import React, { useState } from 'react'

import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { Container } from 'presentation/shared/components/Container'
import TextField from 'presentation/shared/components/TextField'
import Button from 'presentation/shared/components/Button'
import { UpdateSurgicalOrder } from 'domain/usecases/surgical-order/update-surgical-order'
import Table from 'presentation/shared/components/Table'
import { ReactComponent as DeleteIcon } from 'presentation/assets/icons/trash-outline-purple.svg'
import Chip from 'presentation/shared/components/Chip'
import Heading from 'presentation/shared/components/Heading'

import * as S from './styles'
import * as FormS from '../styles'
import { Opme } from 'presentation/doctor/pages/AddSurgicalOrder/SelectOpme'
import { useServices } from 'presentation/hooks/use-services'
import AutoComplete from 'presentation/shared/components/AutoComplete'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'

type Props = {
  submit?: () => void
  initialValues?: SelectOpmeFormValues
  updateSurgicalOrder?: UpdateSurgicalOrder
  surgicalOrderId: number
  goBack: () => void
  submitForm?: (opmes: Opme[]) => void
}

export default function SelectOpme({
  initialValues = {
    opmes: []
  } as SelectOpmeFormValues,
  submit,
  goBack,
  submitForm
}: Props) {
  const surgicalOrderService = useServices().surgicalOrder
  const [actualOpme, setActualOpme] = useState('')
  const [opmeQuantity, setOpmeQuantity] = useState(1)
  const [opmeId, setOpmeId] = useState()
  const [opmeList, setOpmeList] = useState([])

  const { values, setFieldValue, errors } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async () => {
      if (submit) {
        submit()
      }
    }
  })

  const addOpme = () => {
    if (values.opmes.find((opme) => opme.opme_id === opmeId)) {
      toast.error('OPME já adicionada')
      setOpmeId(undefined)
      return
    }

    setFieldValue('opmes', [
      ...values.opmes,
      { description: actualOpme, quantity: opmeQuantity, opme_id: opmeId }
    ])
    setActualOpme('')
    setOpmeQuantity(1)
    setOpmeId(undefined)
  }

  const removeOpme = (opmeIndex: number) => {
    const newOpmes = values.opmes.filter((opme, index) => index !== opmeIndex)
    setFieldValue('opmes', newOpmes)
  }


  const handleSearchOpme = async (opme: string) => {
    try {
      const response = await surgicalOrderService.findAllOpmes({
        query: opme,
        pagination: { pageNumber: 1, pageSize: 5 }
      })
      setOpmeList(response.data)
    } catch (e: any) {
      toast.error(e.message)
    }
  }

  const handleSuggestionClick = (opme) => {
    setActualOpme(opme.description)
    setOpmeId(opme.id)
  }

  const handleSubmit = () => {
    try {
      if (submitForm) {
        submitForm(values.opmes)
      }
    } catch (e: any) {
      toast.error(e.message)
    }
  }

  return (
    <S.Wrapper role="form">
      <Container
        title="OPME"
        subtitle="De acordo com a Agência Nacional de Saúde Suplementar (ANS), o prazo de autorização de procedimento é de até 21 dias úteis."
        primaryButton={
          <Button
            fullWidth
            disabled={!!errors.opmes || values.opmes.length === 0}
            type="submit"
            onClick={() => handleSubmit()}
            data-testid="next-opme-button"
          >
            Próximo
          </Button>
        }
        secondaryButton={
          <Button
            fullWidth
            variant="outlined"
            onClick={goBack}
            data-testid="go-back-opme-button"
          >
            Voltar
          </Button>
        }
      >
        <S.InputsContainer>
          <Heading size="medium" color="gray" style={{ marginTop: '24px' }}>
            Procedimento irá precisar de OPME?
          </Heading>

          <S.ChipsContainer>
            {hasOpme.map((opme) => (
              <Chip
                key={opme.label}
                label={opme.label}
                customValue={opme.value}
                name="opme"
                checked={opme.value}
                disabled
                dataTestId={`chip-${opme.value}`}
                variant="square"
              />
            ))}
          </S.ChipsContainer>
          <S.InputContainer>
            <AutoComplete
              label="OPME"
              labelColor="gray"
              placeholder="OPME"
              value={actualOpme}
              onInputChange={setActualOpme}
              onSuggestionClick={(opme) => handleSuggestionClick(opme)}
              style={{ width: '100%' }}
              onType={(val) => handleSearchOpme(val)}
              suggestions={opmeList?.map((opme) => ({
                label: opme.description,
                value: opme
              }))}
              disabled={!!opmeId}
              bgColor="white"
              inputBgColor="white"
              icon={<SearchIcon />}
              iconPosition="right"
              iconLocale="inside"
              required
              data-testid={`opme-description-autocomplete`}
            />
            <TextField
              type="number"
              label="Qt."
              defaultValue={opmeQuantity}
              onInputChange={(value) => setOpmeQuantity(Number(value))}
              data-testid="opme-quantity-input"
            />
          </S.InputContainer>
        </S.InputsContainer>
        <Button
          fullWidth
          type="button"
          style={{
            marginTop: '24px'
          }}
          onClick={addOpme}
          disabled={actualOpme === '' || opmeQuantity < 1 || !opmeId}
          data-testid="add-opme-button"
        >
          Adicionar OPME
        </Button>
        <FormS.TableContainer>
          <Table
            columns={tableColumns}
            data={values.opmes.map((opme, index) => ({
              description: opme.description,
              quantity: opme.quantity,
              delete: (
                <FormS.DeleteButton
                  onClick={() => removeOpme(index)}
                  data-testid="delete-opme-button"
                >
                  <DeleteIcon height={20} />
                </FormS.DeleteButton>
              )
            }))}
            pagination={false}
          />
          {/* )} */}
        </FormS.TableContainer>
      </Container>
    </S.Wrapper>
  )
}

type SelectOpmeFormValues = {
  opmes: any[]
}

const validationSchema = yup.object().shape({
  opmes: yup.array().of(
    yup.object().shape(
      {
        description: yup.string().when(['quantity'], {
          is: (quantity: number, observation: string) =>
            quantity || observation,
          then: yup.string().required()
        }),
        quantity: yup.number().when(['description'], {
          is: (quantity: number, description: string) =>
            quantity || description,
          then: yup.number().required()
        }),
        opme_id: yup.number().required()
      },
      [['description', 'quantity']]
    )
  ),
  provider1: yup.string().when('opmes', {
    is: (opmes: any[]) => opmes.length > 0,
    then: yup.string().required()
  })
})

const tableColumns = [
  {
    name: 'description',
    label: 'Descrição',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: string) => (
        <div style={{ overflow: 'hidden', maxWidth: '200px' }}>{value}</div>
      )
    }
  },
  {
    name: 'quantity',
    label: ' ',
    options: { filter: false, sort: false }
  },
  {
    name: 'delete',
    label: ' ',
    options: { filter: false, sort: false }
  }
]

const hasOpme = [
  {
    label: 'Sim',
    value: true
  },
  {
    label: 'Não',
    value: false
  }
]

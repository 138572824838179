import React, { useState } from 'react'

import * as S from './styles'
import Carousel, {
  CarouselState
} from 'presentation/shared/components/Carousel'
import Table from 'presentation/shared/components/Table'
import { Patient } from 'domain/entities/patient-model'
import { Radio } from '@material-ui/core'
import { dateInputFormat } from 'presentation/utils/date-format'
import { Container } from 'presentation/shared/components/Container'
import Button from 'presentation/shared/components/Button'
import SheetModal from 'presentation/shared/components/SheetModal'
import { cpfMask, phoneMask } from 'presentation/utils/masks'
import TextField from 'presentation/shared/components/TextField'
import { Label } from 'presentation/shared/components/TextField/styles'
import { DownloadCard } from 'presentation/shared/components/DownloadCard'
import { PatientDocument } from 'common/enum/patient-document'

type Props = {
  close: () => void
  show: boolean
  patients?: Patient[]
  confirmPatient: (patient: Patient) => void
}

export default function FindPatientSheet({
  close,
  show,
  patients = [],
  confirmPatient
}: Props) {
  const [carousel, setCarousel] = useState<CarouselState>({
    activeIndex: 0
  } as CarouselState)
  const [selectedPatient, setSelectedPatient] = useState<Patient>()

  const frontFile = selectedPatient?.documentHealthInsurance
    ?.reverse()
    ?.find((doc) => doc.type === PatientDocument.HEALTH_CARD_FRONT)

  const backFile = selectedPatient?.documentHealthInsurance
    ?.reverse()
    ?.find((doc) => doc.type === PatientDocument.HEALTH_CARD_BACK)

  const stepData: Record<number, any> = {
    0: {
      button: (
        <Button
          fullWidth
          onClick={carousel.slideNext}
          disabled={!selectedPatient}
          type="button"
        >
          Próximo
        </Button>
      )
    },
    1: {
      button: (
        <Button fullWidth onClick={carousel.slideNext} type="button">
          Próximo
        </Button>
      )
    },
    2: {
      button: (
        <Button
          fullWidth
          onClick={() => confirmPatient(selectedPatient as Patient)}
          type="button"
        >
          Confirmar
        </Button>
      )
    }
  }

  return (
    <SheetModal size={1000} isOpen={show} onClose={close}>
      <Container
        noPadding
        title="Busca de paciente"
        subtitle="Selecione o paciente abaixo"
        primaryButton={stepData[carousel.activeIndex].button}
        justifyContent="center"
        style={{ placeSelf: 'flex-start', textAlign: 'left' }}
      >
        <Carousel state={carousel} setState={setCarousel} touch={false}>
          <Table
            columns={tableColumns}
            data={patients.map((patient) => ({
              name: patient.name,
              birthday: dateInputFormat(patient.birthday),
              add: (
                <Radio
                  value={patient.patient_id}
                  name="patient"
                  checked={selectedPatient?.patient_id === patient.patient_id}
                  onChange={() => setSelectedPatient(patient)}
                />
              )
            }))}
            pagination={false}
          />
          <S.InputsWrapper>
            <TextField
              label="CPF"
              disabled
              mask={cpfMask}
              defaultValue={selectedPatient?.cpf}
            />
            <TextField
              label="Nome do paciente"
              disabled
              value={selectedPatient?.name}
            />
            <TextField label="E-mail" disabled value={selectedPatient?.email} />
            <TextField
              bgColor="mainBg"
              label="Celular"
              disabled
              mask={phoneMask}
              defaultValue={selectedPatient?.phone}
            />
          </S.InputsWrapper>
          <S.InputsWrapper>
            <TextField
              label="Convênio"
              disabled
              defaultValue={
                selectedPatient?.healthInsurance?.healthInsuranceName
              }
            />
            <Label labelColor="primary" style={{ marginTop: '12px' }}>
              Carteirinha
            </Label>
            <div
              style={{
                display: 'grid',
                marginTop: '12px',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '2%'
              }}
            >
              <DownloadCard
                fileName={frontFile?.name || ''}
                documentId={frontFile?.document_id as number}
                emptyMessage={
                  !frontFile ? 'Frente da carteirinha não enviada' : undefined
                }
              />
              <DownloadCard
                fileName={backFile?.name || ''}
                documentId={backFile?.document_id as number}
                emptyMessage={
                  !frontFile ? 'Verso da carteirinha não enviado' : undefined
                }
              />
            </div>
          </S.InputsWrapper>
        </Carousel>
      </Container>
    </SheetModal>
  )
}

const tableColumns = [
  {
    name: 'name',
    label: 'Nome',
    options: { filter: false, sort: false }
  },
  {
    name: 'birthday',
    label: 'Dt. de nascimento',
    options: { filter: false, sort: false }
  },
  {
    name: 'add',
    label: 'ADD',
    options: { filter: false, sort: false }
  }
]

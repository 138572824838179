import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
`
export const Card = styled.div`
  display: flex;
  align-items: center;
`
export const Icon = styled.div`
  width: 30px;
  display: flex;
`
export const Item = styled.div`
  flex: 1;
`
export const FavoriteName = styled.p`
  font-size: 16px;
  font-weight: 600;
`
export const FavoriteDate = styled.p`
  font-size: 14px;
`
export const Text = styled.mark`
  color: ${({ theme }) => theme.colors.gray};
  background: none;
  margin-right: 10px;
`

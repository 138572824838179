export const ScheduleState = {
  allScheduled: {
    scheduled: true,
    all: true
  },
  scheduledAuthorized: {
    scheduled: true,
    all: false,
    authorized: true
  },
  scheduledUnauthorized: {
    scheduled: true,
    all: false,
    authorized: false
  },
  allNotScheduled: {
    scheduled: false,
    all: true
  },
  notScheduledAuthorized: {
    scheduled: false,
    all: false,
    authorized: true
  },
  notScheduledUnauthorized: {
    scheduled: false,
    all: false,
    authorized: false
  },
  UNFINISHED: {
    scheduled: false,
    all: false,
    authorized: false
  },
  ALL: {
    all: true,
    scheduled: false,
    authorized: false
  }
}

import React from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment-timezone'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ReactComponent as FavoriteIcon } from 'presentation/assets/icons/star_verde.svg'
import { ReactComponent as ArrowIcon } from 'presentation/assets/icons/fav_seta.svg'
import { LoadFavoriteSurgicalOrders } from 'domain/usecases/surgical-order/load-favorite-surgical-orders'

import * as S from './styles'

type Favorite = {
  id: number
  date: string
  name: string
  isMain?: boolean
  procedure: string
  procedure_code: string
  cid: string
  quantity?: number
  doctor_name?: string
  pro_fat_id: string
  surgery_id: string | number
  cid_code: string
  opme?: {
    solicitations: {
      description: string
      quantity: string
      observation: string
    }[]
    providers: string[]
  }
}

type FavoriteList = LoadFavoriteSurgicalOrders.Model

type Props = {
  favorites?: Favorite[]
  favoriteList: FavoriteList
}

const FavoriteCard = ({ favoriteList }: Props) => {
  const history = useHistory()

  const handleLinkPatient = (favorite: Record<number, string>) => {
    makeLocalStorageAdapter().set('favorite_doctor', favorite)
    history.push('/pedido/novo')
  }

  return (
    <>
      {favoriteList.map((favorite, index) => (
        <S.Wrapper
          data-testid="favorite-card-wrapper"
          key={index}
          onClick={() => handleLinkPatient(favorite)}
        >
          <S.Card>
            <FavoriteIcon style={{ marginRight: 20 }} />
            <S.Item>
              <S.FavoriteName data-testid="favorite-name">
                {favorite.name}
              </S.FavoriteName>
              <S.FavoriteDate data-testid="favorite-createdAt">
                <S.Text>Adicionado em</S.Text>
                {moment(favorite.createdAt).format('DD/MM/YYYY')}
              </S.FavoriteDate>
            </S.Item>
            <S.Icon>
              <ArrowIcon width={50} />
            </S.Icon>
          </S.Card>
        </S.Wrapper>
      ))}
    </>
  )
}

export default FavoriteCard

import styled from 'styled-components'
import { ReactComponent as CloseIcon } from 'presentation/assets/icons/close.svg'

export const Step = styled.div`
  overflow-y: auto;
  padding: 0 2px;
  p {
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
    margin-top: 12px;
  }
`

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2%;

  & > div {
    margin: 0 !important;
  }
`

export const LabelInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
`

export const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: ${({ theme }) => theme.font.sizes.xsmall};

  & > div {
    margin-top: 12px;
  }

  & > div {
    margin-right: 8px;
  }
`

export const LabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: ${({ theme }) => theme.font.sizes.small};
  color: ${({ theme }) => theme.colors.darkGreen};
  margin-top: 12px;
  margin-right: 1rem;

  & > div {
    margin-top: 12px;
  }

  & > div {
    margin-right: 8px;
  }

  span {
    color: ${({ theme }) => theme.colors.red};
  }
`

export const BodyInfoLabel = styled.small`
  color: ${({ theme }) => theme.colors.lightGray};
  height: 8px;
  width: 1px;
  margin-right: 10px;
`

export const CloseButton = styled(CloseIcon)`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  position: absolute;
  top: 0;
  right: 10px;
  cursor: pointer;
`

export const DateSuportText = styled.div`
  color: ${({ theme }) => theme.colors.darkGreen};
  background: #fff;
  width: fit-content;
  font-style: italic;
  padding: 0 10px;
  margin-bottom: 2rem;
  margin-top: 1rem;
`
